import React, { useState, useEffect, useContext, useCallback } from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../assets/CSS/PlacedOffers.css';
import axios from 'axios';
import { jwtToken } from '../Context/DataContext';
import { Spinner } from './Spinner';
import { UserContext } from '../Context/UserContext';
import { TimestampToDate } from '../utils/DateAndTimeUtils';
import swal from 'sweetalert';
import '../assets/CSS/SoldBought.css';

export default function Offers() {
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [loaded, setLoaded] = useState(false);

  const fetchBoughtVehicles = useCallback(async () => {
    if (token && userID) {
      try {
        setLoaded(true);
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}boughtvehicles`, { userID },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setData(response.data);
      } catch (error) {
        swal({
          text: 'Failed to retrieve bought vehicles!',
          icon: 'error',
          dangerMode: true,
        });
      } finally {
        setLoaded(false);
      }
    } else {
      setLoaded(true);
    }
  }, [token, userID]);

  useEffect(() => {
    fetchBoughtVehicles();
  }, [fetchBoughtVehicles]);

  return (
    <>
      {loaded ? (<Spinner></Spinner>) : (
        <div className="App p-spacing" >
          <div className="container m-spacing">
            {data.length === 0 ? (<div className="text-center my-5 py-5">No Vehicles</div>) : (
              <div className="offer-details">
                <div className="form-group mb-2">
                  <div className="row mb-5">
                    <div className="col mb-2">
                      <input
                        type="text"
                        placeholder="Search from plate number/VIN"
                        className="form-control"
                        onChange={(e) => {
                          setSearchData(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-3">
                      <button className="btn btn-danger" id="search-button" type="button" style={{ height: '90%' }}>
                        {' '}
                        Search &nbsp;
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                  {/* Table Section */}
                  <table className="table align-middle mb-0 mt-2 bg-white" style={{ margin: 'auto' }}>
                    <thead className="bg-light">
                      <tr>
                        <th>Plate Number/VIN</th>
                        <th>Purchased Price</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter((val) => {
                          if (!searchData.trim()) {
                            return val;
                          } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                            return val;
                          }
                          return null;
                        })
                        .map((item, index) => (
                          <tr key={index}>
                            <td>
                              <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                            </td>
                            <td>
                              <p className="text-muted mb-0">${item.Amount}</p>
                            </td>
                            <td>
                              <span>{TimestampToDate(item.CreatedAt)}</span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
