const dayjs = window.dayjs;
const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//timestamp to date
function TimestampToDate(timestamp) {
  const formattedDate = dayjs(timestamp * 1000).tz(browserTimezone).format('MMM DD, YYYY');
  return formattedDate;
}

//current timestamp to date
function CurrentTimestampToDate() {
  const currentTimestamp = Date.now() / 1000;
  const formattedDate = dayjs(currentTimestamp * 1000).tz(browserTimezone).format('MMM DD, YYYY');
  return formattedDate;
}

//timestamp to date with seconds
function checkIsYesterday(timestamp) {
  const formattedDate = dayjs(timestamp * 1000).tz(browserTimezone).isYesterday();
  return formattedDate;
}

//current timestamp to date
function CurrentTimestampToDateWithSeconds() {
  const currentTimestamp = Date.now() / 1000;
  const formattedDate = dayjs(currentTimestamp * 1000).tz(browserTimezone).format('MMM DD, YYYY HH: mm: ss');
  return formattedDate;
}

//UTC date to full formatted date with offset abbreviation
function UTCdateToFullFormattedDate(utcDate) {
  const localDateTime = dayjs(utcDate).tz(browserTimezone).format('MMM DD, YYYY hh:mm A zzz');
  const match = localDateTime.match(/(AM|PM)(\s+.*)/);
  const words = match[2].trim().split(/\s+/); //get the words after PM/AM and join first letters of each word
  const firstLetters = words.map(word => word.charAt(0)).join('');
  const firstCapitalLetters = firstLetters.toUpperCase();
  const formattedFullDate = localDateTime.replace(match[2], ` ${firstCapitalLetters}`);
  return formattedFullDate;
}

//UTC date to normal formatted date
function UTCdateToFormattedDate(utcDate) {
  const formattedDate = dayjs(utcDate).tz(browserTimezone).format('MMM DD, YYYY');
  return formattedDate;
}

//UTC date to normal formatted date
function UTCdatetimeToFormattedDateTime(utcDateTime) {
  const formattedDate = dayjs(utcDateTime).tz(browserTimezone).format('MMM DD, YYYY HH: mm: ss');
  return formattedDate;
}

//Get current Date and Time in UTC format
function getCurrentUTCTime() {
  const currentDate = new Date();
  const utcTime = currentDate.toISOString();
  return utcTime;
}

//get current date and time in UTC timestamp
function getCurrentUTCTimeStamp() {
  const currentDate = new Date();
  const utcTime = currentDate.toISOString();
  return utcTime / 1000;
}

//Convert timestamp into UTC (Both Date and Time)
function TimestampToUTC(timestamp) {
  const formattedDate = dayjs.unix(timestamp).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  return formattedDate;
}

export { TimestampToDate, UTCdateToFullFormattedDate, UTCdateToFormattedDate, getCurrentUTCTime, TimestampToUTC, UTCdatetimeToFormattedDateTime, CurrentTimestampToDate, checkIsYesterday, CurrentTimestampToDateWithSeconds, getCurrentUTCTimeStamp };