import React from 'react';
import '../App.css';
import HeroSection from '../components/HeroSection';
import '../assets/CSS/Footer.css';

function Home() {
  return (
    <>
      <HeroSection />
    </>
  );
}

export default Home;
