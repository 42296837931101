import { createContext, useState, useEffect } from 'react';

export const VehicleDataContext = createContext();

export const VehicleDataProvider = (props) => {
  const [vehiData, setVehiData] = useState(
    localStorage.getItem('vehiData') ? JSON.parse(localStorage.getItem('vehiData')) : {}
  );
  const [claimObj, setClaimObj] = useState(
    localStorage.getItem('claimObj') ? JSON.parse(localStorage.getItem('claimObj')) : {}
  );
  const [offer, setOffer] = useState(
    localStorage.getItem('offer') ? JSON.parse(localStorage.getItem('offer')) : {}
  );
  const [plateNumber, setPlateNumber] = useState(localStorage.getItem('plateNumber') ? localStorage.getItem('plateNumber') : '');
  const [VIN, setVIN] = useState(localStorage.getItem('VIN') ? localStorage.getItem('VIN') : '');
  const [expiredClaimModalShown, setExpiredClaimModalShown] = useState(localStorage.getItem('expiredClaimModalShown') ? localStorage.getItem('expiredClaimModalShown') : 'false');

  // When the vehicle data changes, update it in local storage
  useEffect(() => {
    localStorage.setItem('vehiData', JSON.stringify(vehiData));
    if (vehiData.statusDetails?.ClaimStatus) {
      localStorage.setItem('claimObj', JSON.stringify(vehiData.statusDetails.ClaimRecord));
      setClaimObj(vehiData.statusDetails.ClaimRecord);
    } else {
      localStorage.setItem('claimObj', JSON.stringify({}));
      setClaimObj({});
    }
    if (vehiData.statusDetails?.offerRecord) {
      localStorage.setItem('offer', JSON.stringify(vehiData.statusDetails.offerRecord));
      setOffer(vehiData.statusDetails.offerRecord);
    } else {
      localStorage.setItem('offer', JSON.stringify({}));
      setOffer({});
    }
    if (vehiData.vehicleDetails) {
      if (vehiData.vehicleDetails.PlateNumber) {
        localStorage.setItem('plateNumber', vehiData.vehicleDetails.PlateNumber);
        setPlateNumber(vehiData.vehicleDetails.PlateNumber);
      } else {
        localStorage.setItem('plateNumber', '');
        setPlateNumber('');
      }
      if (vehiData.vehicleDetails.VIN) {
        localStorage.setItem('VIN', vehiData.vehicleDetails.VIN);
        setVIN(vehiData.vehicleDetails.VIN);
      }
    }
  }, [vehiData]);

  useEffect(() => {
    localStorage.setItem('claimObj', JSON.stringify(claimObj));
  }, [claimObj]);

  useEffect(() => {
    localStorage.setItem('offer', JSON.stringify(offer));
  }, [offer]);

  useEffect(() => {
    localStorage.setItem('plateNumber', plateNumber);
  }, [plateNumber]);

  useEffect(() => {
    localStorage.setItem('VIN', VIN);
  }, [VIN]);

  useEffect(() => {
    localStorage.setItem('expiredClaimModalShown', expiredClaimModalShown);
  }, [expiredClaimModalShown]);

  // Render the provider component with the vehicle data value and setter function in the context
  return (
    <VehicleDataContext.Provider
      value={{
        vehiData,
        setVehiData,
        claimObj,
        setClaimObj,
        offer,
        setOffer,
        plateNumber,
        setPlateNumber,
        VIN,
        setVIN,
        expiredClaimModalShown,
        setExpiredClaimModalShown
      }}
    >
      {props.children}
    </VehicleDataContext.Provider>
  );
};


