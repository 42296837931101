import React, { useState, useContext, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { UserContext } from '../Context/UserContext';
import { jwtToken } from '../Context/DataContext';
import { useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { addUserPreferences } from '../services/PreferenceService';
import { GlobalContext } from '../Context/GlobalContext';
import SearchContext from '../Context/SearchContext';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Tooltip } from 'react-tippy';
import { chatBot, wakaonlineChatbot } from '../services/ChatBotService';
import { ChatBotContext } from '../Context/ChatBotContext';
import chatbot from '../assets/img/chatbot.jpg';

const budget = 500;

export default function Chatbot() {
  const [isOpen, setIsOpen] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const { userID } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const history = useHistory();
  const location = useLocation();
  const { setPreviousLocation } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const { setSelectedMake, setSelectedModel } = useContext(SearchContext);
  const [typingMessage, setTypingMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);
  const { chatMessages, addMessage, clearChat } = useContext(ChatBotContext);
  const [selectedBodyTypes, setSelectedBodyTypes] = useState([]);
  const [selectedFuelType, setSelectedFuelType] = useState([]);
  const [selectedVehicleBrands, setSelectedVehicleBrands] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedDynamicAnswer, setSelectedDynamicAnswer] = useState([]);
  const [currentDynamicQuestion, setCurrentDynamicQuestion] = useState('');
  const [dynamicQuestionAnswers, setDynamicQuestionAnswers] = useState([]);

  const bodyStyleOptions = ["SUV", "Hatchback", "Wagon", "Sedan", "Sports", "Convertible", "Coupes"];
  const fuelTypeOptions = ["Petrol", "Diesel", "Hybrid", "Electric"];
  const vehicleBrandsOptions = ["Abarth", "Audi", "BMW", "BYD", "Citroen", "Cupra", "Dacia", "DS", "Fiat", "Ford", "Genesis", "Honda", "Hyundai", "INEOS", "Jaguar", "Kia", "Land Rover", "Lexus", "Lotus", "Maserati", "Mazda", "Mercedes", "MG", "MINI", "Nissan", "ORA", "Peugeot", "Polestar", "Porsche", "Renault", "SEAT", "Skoda", "Smart", "SsangYong", "Subaru", "Suzuki", "Tesla", "Toyota", "Vauxhall", "Volkswagen", "Volvo"];
  const budgetOptions = ["between 5K to 25K", "between 25K to 50K", "between 50K to 75K", "between 75K to 100K", "Morethan 100K"];
  const featureOptions = ["Android Auto", "Apple CarPlay", "Backup Camera", "Bluetooth", "Multi-Zone Climate Control", "Navigation", "Parking Sensors", "Remote Engine Start", "Touch Screen Infotainment"];

  useEffect(() => {
    console.log('Loading previous messages...');
    const storedMessages = JSON.parse(localStorage.getItem('chatMessages')) || [];
    console.log('Stored messages:', storedMessages);

    chatMessages.forEach((message) => {
      console.log('Adding message:', message);
      addMessage(message.role, message.content);
    });

    setMessages((prevMessages) => [...prevMessages, ...chatMessages]);
    console.log('chatmessages', chatMessages);
  }, []);

  useEffect(() => {
    console.log('Current messages state:', messages);
    localStorage.setItem('chatMessages', JSON.stringify(chatMessages));
  }, [chatMessages, messages]);

  useEffect(() => {
    return () => {
      clearChat();
    };
  }, []);

  const handleBodyStyleSelection = (selectedOption) => {
    const updatedBodyTypes = selectedBodyTypes.includes(selectedOption)
      ? selectedBodyTypes.filter((option) => option !== selectedOption)
      : [...selectedBodyTypes, selectedOption];

    setSelectedBodyTypes(updatedBodyTypes);
  };

  const handleFuelTypeSelection = (selectedOption) => {
    const updatedFuelTypes = selectedFuelType.includes(selectedOption)
      ? selectedFuelType.filter((option) => option !== selectedOption)
      : [...selectedFuelType, selectedOption];

    setSelectedFuelType(updatedFuelTypes);
  };

  const handleVehicleBrandOptions = (selectedOption) => {
    const updatedBrands = selectedVehicleBrands.includes(selectedOption)
      ? selectedVehicleBrands.filter((option) => option !== selectedOption)
      : [...selectedVehicleBrands, selectedOption];

    setSelectedVehicleBrands(updatedBrands);
  };

  const handleBudgetSelection = (selectedOption) => {
    const updateBudget = selectedBudget.includes(selectedOption)
      ? selectedBudget.filter((option) => option !== selectedOption)
      : [...selectedBudget, selectedOption];

    setSelectedBudget(updateBudget);
  };

  const handleFeatureSelection = (selectedOption) => {
    const updateFeature = selectedFeatures.includes(selectedOption)
      ? selectedFeatures.filter((option) => option !== selectedOption)
      : [...selectedFeatures, selectedOption];

    setSelectedFeatures(updateFeature);
  };

  const handleNextStep = async () => {
    if (currentDynamicQuestion) {
      handleDynamicNextStep();
      return;
    }
    setCurrentStep((prevStep) => prevStep + 1);
    setSuggestions([]);
  };

  const handleFinalNextStep = async () => {
    try {
      setCurrentStep((prevStep) => prevStep + 1);
      setSuggestions([]);
      setIsLoading(true);

      const bodyTypeInput = selectedBodyTypes.length > 0 ? selectedBodyTypes.join(", ") : "any";
      const fuelTypeInput = selectedFuelType.length > 0 ? selectedFuelType.join(", ") : "any";
      const vehilceBrand = selectedVehicleBrands.length > 0 ? selectedVehicleBrands.join(", ") : "any";
      const vehicleBudget = selectedBudget.length > 0 ? selectedBudget.join(", ") : "any";
      const featureInput = selectedFeatures.length > 0 ? selectedFeatures.join(", ") : "any";

      addMessage('user', `Body style: ${bodyTypeInput}, \nFuel type: ${fuelTypeInput}, \nBrand: ${vehilceBrand}, \nBudget: ${vehicleBudget}, \nFeatures: ${featureInput}`);
      const customUserInput = `What are some economical car options suitable for daily commuting, considering body style ${bodyTypeInput}, fuel type ${fuelTypeInput}, brand ${vehilceBrand}, budget ${vehicleBudget}, and with ${featureInput} features in New Zealand`;

      const response = await chatBot(budget, customUserInput);

      const responseData = response.data;
      console.log('response', responseData);

      if (responseData && responseData.vehicleInfo[0]?.originalResponse && responseData.vehicleInfo[0]?.originalResponse?.length > 0) {
        addMessage('assistant', responseData.vehicleInfo[0].originalResponse);
      }
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      const defaultResponse = "We regret to inform you that we couldn't address your question at this moment. Kindly give us another moment to rectify this, and we sincerely apologize for any inconvenience caused. Please feel free to try again shortly. Thank you for your understanding.";
      addMessage('assistant', defaultResponse);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
    setSuggestions([]);
    if (!hasClicked) {
      setHasClicked(true);
    }
  };

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
    setCurrentStep(0);
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion === 'Would you like to explore cars with more specific options?') {
      setCurrentDynamicQuestion('');
      setDynamicQuestionAnswers([]);
      setUserInput('');
    } else if (suggestion === 'How many people are traveling?') {
      handleDynamicQuestion(suggestion);
    }
    else {
      setCurrentDynamicQuestion('');
      setDynamicQuestionAnswers([]);
      setUserInput(suggestion);
    }
    switch (suggestion) {
      case "Would you like to explore cars with more specific options?":
        setCurrentStep(1);
        break;
      case "Which fuel type would you like?":
        setCurrentStep(2);
        break;
      case "Are you considering any specific brands?":
        setCurrentStep(3);
        break;
      case "What is your expected budget?":
        setCurrentStep(4);
        break;
      case "What are the popular features you want?":
        setCurrentStep(5);
        break;
      default:
        setCurrentStep(0);
        break;
    }
  };

  const handleDynamicQuestion = (suggestion) => {
    setUserInput('');

    switch (suggestion) {
      case "How many people are traveling?":
        setCurrentDynamicQuestion("Number of People");
        setDynamicQuestionAnswers(["2 people", "4 people", "8 people", "More than eight"]);

        break;
      default:
        setCurrentStep(0);
        setSuggestions([]);
        setDynamicQuestionAnswers([]);
        break;
    }
  };

  const handleDynamicAnswerSelection = (selectedOption) => {
    setSelectedDynamicAnswer((prevSelectedAnswers) => {
      if (prevSelectedAnswers.includes(selectedOption)) {
        return prevSelectedAnswers.filter((option) => option !== selectedOption);
      } else {
        return [...prevSelectedAnswers, selectedOption];
      }
    });
  };

  const handleDynamicNextStep = async () => {
    try {
      setSuggestions([]);
      setIsLoading(true);
      setCurrentDynamicQuestion('');
      setDynamicQuestionAnswers([]);
      setSelectedDynamicAnswer([]);

      switch (currentDynamicQuestion) {
        case "Number of People":
          addMessage('user', `Number of People: ${selectedDynamicAnswer.join(', ')}`);
          const response1 = await wakaonlineChatbot(selectedDynamicAnswer);

          const responseData1 = response1.data;
          console.log('response', responseData1);

          if (responseData1) {
            addMessage('assistant', responseData1);
          }

          setCurrentDynamicQuestion("Which fuel type would you like?");
          setDynamicQuestionAnswers(["Petrol", "Diesel", "Hybrid", "Electric", "Plugin Hybrid"]);
          break;
        case "Which fuel type would you like?":
          addMessage('user', `Fuel Type: ${selectedDynamicAnswer.join(', ')}`);
          const response2 = await wakaonlineChatbot('', selectedDynamicAnswer);

          const responseData2 = response2.data;
          console.log('response', responseData2);

          if (responseData2) {
            addMessage('assistant', responseData2);
          }
          setCurrentDynamicQuestion("What's your total budget?");
          setDynamicQuestionAnswers(["Less than $200", "between $200 to $400", "between $400 to $600", "between $600 to $800", "between $800to $1,000", "More than $1,000"]);
          break;
        case "What's your total budget?":
          addMessage('user', `Total Budget: ${selectedDynamicAnswer.join(', ')}`);
          const response3 = await wakaonlineChatbot('', '', selectedDynamicAnswer);

          const responseData3 = response3.data;
          console.log('response', responseData3);

          if (responseData3) {
            addMessage('assistant', responseData3);
          }
          setCurrentDynamicQuestion("How much would you be running every month?");
          setDynamicQuestionAnswers(["Less than $200 per month", "between $200 to $400 per month", "between $400 to $600 per month", "between $600 to $800 per month", "between $800to $1,000 per month", "More than $1,000 per month"]);
          break;
        case "How much would you be running every month?":
          addMessage('user', `Monthly Budget: ${selectedDynamicAnswer.join(', ')}`);
          const response4 = await wakaonlineChatbot('', '', '', selectedDynamicAnswer);

          const responseData4 = response4.data;
          console.log('response', responseData4);

          if (responseData4) {
            addMessage('assistant', responseData4);
          }
          setCurrentDynamicQuestion("What's your monthly travel budget?");
          setDynamicQuestionAnswers(["Less than $500", "$500 to $1,000", "$1,000 to $1,500", "$1,500 to $2,000", "$2,000 to $2,500", "More than $2,500"]);
          break;
        case "What's your monthly travel budget?":
          addMessage('user', `Monthly Travel Budget: ${selectedDynamicAnswer.join(', ')}`);
          const response5 = await wakaonlineChatbot('', '', '', '', selectedDynamicAnswer);

          const responseData5 = response5.data;
          console.log('response', responseData5);

          if (responseData5) {
            addMessage('assistant', responseData5);
          }
          setCurrentDynamicQuestion("Are you looking for any specific safety features?");
          setDynamicQuestionAnswers(["Anti-lock Braking System", "Adaptive Cruise Control", "Forward Collision Warning", "Obstacle Detection", "Pedestrian Detection", "High-Speed Alert", "Multi-Zone Climate Control", "Tire Pressure Monitoring System", "Backup Camera", "Traction Control", "Parking Sensors", "Any"]);
          break;
        case "Are you looking for any specific safety features?":
          addMessage('user', `Safety Features: ${selectedDynamicAnswer.join(', ')}`);
          const response6 = await wakaonlineChatbot('', '', '', '', '', selectedDynamicAnswer);

          const responseData6 = response6.data;
          console.log('response', responseData6);

          if (responseData6) {
            addMessage('assistant', responseData6);
          }
          setCurrentDynamicQuestion("Are you looking for any specific advanced features?");
          setDynamicQuestionAnswers(["Blind Spot Monitoring", "Rear Cross-Traffic Alert", "Automated Emergency Braking", "Advanced Smartphone Integration", "Parking Pilot", "Night Vision", "Stolen Vehicle Recovery", "Heated and Ventilated Seats", "Any"]);
          break;
        case "Are you looking for any specific advanced features?":
          addMessage('user', `Advanced Features: ${selectedDynamicAnswer.join(', ')}`);
          const response7 = await wakaonlineChatbot('', '', '', '', '', '', selectedDynamicAnswer);

          const responseData7 = response7.data;
          console.log('response', responseData7);

          if (responseData7) {
            addMessage('assistant', responseData7);
          }
          setCurrentDynamicQuestion("Want to know about available vehicles based on your overall choices?");
          break;
        case "Want to know about available vehicles based on your overall choices?":
          addMessage('user', `Overall summary based on your choices`);
          const response8 = await wakaonlineChatbot('', '', '', '', '', '', '', 'Overall summary based on your choices');

          const responseData8 = response8.data;
          console.log('response', responseData8);

          if (responseData8) {
            addMessage('assistant', responseData8);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      let errorMessage = "We're sorry, but there seems to be an issue with the connection. Please try again.";
      if (error.response && error.response.status === 429) {
        errorMessage = "Oops! Too many requests. Please wait a moment and try again.";
      }

      addMessage('assistant', errorMessage);
      handleDynamicCurrentQuestion();

    } finally {
      setIsLoading(false);
    }
  };

  const handleDynamicCurrentQuestion = async () => {
    try {
      setSuggestions([]);
      setIsLoading(true);

      switch (currentDynamicQuestion) {
        case "Number of People":
          setCurrentDynamicQuestion("Number of People");
          setDynamicQuestionAnswers(["2 people", "4 people", "8 people", "More than eight"]);
          break;
        case "Which fuel type would you like?":
          setCurrentDynamicQuestion("Which fuel type would you like?");
          setDynamicQuestionAnswers(["Petrol", "Diesel", "Hybrid", "Electric", "Plugin Hybrid"]);
          break;
        case "What's your total budget?":
          setCurrentDynamicQuestion("What's your total budget?");
          setDynamicQuestionAnswers(["Less than $200", "between $200 to $400", "between $400 to $600", "between $600 to $800", "between $800to $1,000", "More than $1,000"]);
          break;
        case "How much would you be running every month?":
          setCurrentDynamicQuestion("How much would you be running every month?");
          setDynamicQuestionAnswers(["Less than $200 per month", "between $200 to $400 per month", "between $400 to $600 per month", "between $600 to $800 per month", "between $800to $1,000 per month", "More than $1,000 per month"]);
          break;
        case "What's your monthly travel budget?":
          setCurrentDynamicQuestion("What's your monthly travel budget?");
          setDynamicQuestionAnswers(["Less than $500", "$500 to $1,000", "$1,000 to $1,500", "$1,500 to $2,000", "$2,000 to $2,500", "More than $2,500"]);
          break;
        case "Are you looking for any specific safety features?":
          setCurrentDynamicQuestion("Are you looking for any specific safety features?");
          setDynamicQuestionAnswers(["Anti-lock Braking System", "Adaptive Cruise Control", "Forward Collision Warning", "Obstacle Detection", "Pedestrian Detection", "High-Speed Alert", "Multi-Zone Climate Control", "Tire Pressure Monitoring System", "Backup Camera", "Traction Control", "Parking Sensors", "Any"]);
          break;
        case "Are you looking for any specific advanced features?":
          setCurrentDynamicQuestion("Are you looking for any specific advanced features?");
          setDynamicQuestionAnswers(["Blind Spot Monitoring", "Rear Cross-Traffic Alert", "Automated Emergency Braking", "Advanced Smartphone Integration", "Parking Pilot", "Night Vision", "Stolen Vehicle Recovery", "Heated and Ventilated Seats", "Any"]);
          break;
        case "Want to know about available vehicles based on your overall choices?":
          setCurrentDynamicQuestion("Want to know about available vehicles based on your overall choices?");
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      const defaultResponse = "We regret to inform you that we couldn't address your question at this moment. Kindly give us another moment to rectify this, and we sincerely apologize for any inconvenience caused. Please feel free to try again shortly. Thank you for your understanding.";
      addMessage('assistant', defaultResponse);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDynamicNextQuestion = async () => {
    try {
      setSuggestions([]);
      setIsLoading(true);

      switch (currentDynamicQuestion) {
        case "Number of People":
          setCurrentDynamicQuestion("Which fuel type would you like?");
          setDynamicQuestionAnswers(["Petrol", "Diesel", "Hybrid", "Electric", "Plugin Hybrid"]);
          break;
        case "Which fuel type would you like?":
          setCurrentDynamicQuestion("What's your total budget?");
          setDynamicQuestionAnswers(["Less than $200", "between $200 to $400", "between $400 to $600", "between $600 to $800", "between $800to $1,000", "More than $1,000"]);
          break;
        case "What's your total budget?":
          setCurrentDynamicQuestion("How much would you be running every month?");
          setDynamicQuestionAnswers(["Less than $200 per month", "between $200 to $400 per month", "between $400 to $600 per month", "between $600 to $800 per month", "between $800to $1,000 per month", "More than $1,000 per month"]);
          break;
        case "How much would you be running every month?":
          setCurrentDynamicQuestion("What's your monthly travel budget?");
          setDynamicQuestionAnswers(["Less than $500", "$500 to $1,000", "$1,000 to $1,500", "$1,500 to $2,000", "$2,000 to $2,500", "More than $2,500"]);
          break;
        case "What's your monthly travel budget?":
          setCurrentDynamicQuestion("Are you looking for any specific safety features?");
          setDynamicQuestionAnswers(["Anti-lock Braking System", "Adaptive Cruise Control", "Forward Collision Warning", "Obstacle Detection", "Pedestrian Detection", "High-Speed Alert", "Multi-Zone Climate Control", "Tire Pressure Monitoring System", "Backup Camera", "Traction Control", "Parking Sensors", "Any"]);
          break;
        case "Are you looking for any specific safety features?":
          setCurrentDynamicQuestion("Are you looking for any specific advanced features?");
          setDynamicQuestionAnswers(["Blind Spot Monitoring", "Rear Cross-Traffic Alert", "Automated Emergency Braking", "Advanced Smartphone Integration", "Parking Pilot", "Night Vision", "Stolen Vehicle Recovery", "Heated and Ventilated Seats", "Any"]);
          break;
        case "Are you looking for any specific advanced features?":
          setCurrentDynamicQuestion("Want to know about available vehicles based on your overall choices?");
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      const defaultResponse = "We regret to inform you that we couldn't address your question at this moment. Kindly give us another moment to rectify this, and we sincerely apologize for any inconvenience caused. Please feel free to try again shortly. Thank you for your understanding.";
      addMessage('assistant', defaultResponse);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDynamicSkip = () => {
    setCurrentDynamicQuestion('');
    setDynamicQuestionAnswers([]);
    setSelectedDynamicAnswer([]);

    handleDynamicNextQuestion();
  };

  const handleButtonClick = (info) => {
    console.log('Info: ', info.make, info.model);
    setSelectedMake(info.make);
    setSelectedModel(info.model);
    history.push('/advancedSearch', { fetchdata: true });
  };

  useEffect(() => {
    setMessages(chatMessages);
  }, [chatMessages]);

  const handleSendMessage = async () => {
    if (userInput.trim() === '') return;
    // setMessages((prevMessages) => [...prevMessages, { role: 'user', content: userInput }]);

    addMessage('user', userInput);

    try {
      setUserInput('');
      setSuggestions([]);
      setIsLoading(true);

      let backendUserInput = userInput;

      if (userInput.toLowerCase().includes("running cost under $")) {
        const userBudget = parseFloat(userInput.match(/\$\d+/)[0].substring(1));
        backendUserInput = `Which combination of car make, model, fuel type, and year range would ensure daily commuting in New Zealand with running costs under $${userBudget}?`;
      }

      const response = await chatBot(budget, backendUserInput);

      const responseData = response.data;
      console.log('response', responseData);

      if (responseData && responseData.vehicleInfo[0]?.originalResponse && responseData.vehicleInfo[0]?.originalResponse?.length > 0) {
        // setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: responseData.vehicleInfo[0].originalResponse }]);
        addMessage('assistant', responseData.vehicleInfo[0].originalResponse);
      } else {
        if (responseData && responseData.vehicleInfo && responseData.vehicleInfo.length > 0) {
          const vehicleInfo = responseData.vehicleInfo;
          const vehicleInfoMessages = vehicleInfo.map((info, index) => (
            {
              role: 'assistant',
              content: (
                <div key={index} style={{ color: '#fff', important: 'true', width: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', position: 'relative' }}>
                    <span
                      style={{ position: 'absolute', top: '0', right: '0', }}
                      onClick={() => handleSaveVehicleInfo(info)}
                      disabled={isLoading}
                    >
                      <Tooltip title="Save as Preference" arrow><IconButton><FavoriteIcon style={{ color: 'white', opacity: 0.8 }} /></IconButton></Tooltip>
                    </span>
                  </div>
                  {`Vehicle ${index + 1}:`} <br />
                  {`Make: `} <button
                    style={{ color: 'white', fontWeight: 600, border: 'none', background: 'none', textDecoration: 'underline', }}
                    onClick={() => handleButtonClick(info)}
                  >{`${info.make}`}
                  </button>
                  <br />
                  {`Model: `}<button
                    style={{ color: 'white', fontWeight: 600, border: 'none', background: 'none', textDecoration: 'underline', }}
                    onClick={() => handleButtonClick(info)}
                  > {`${info.model}`}
                  </button>
                  <br />
                  {`Fuel Type: ${info.fuelType}`} <br />
                  {`Year Range: ${info.startYear} - ${info.endYear}`}<br />
                </div >
              )
            }
          ));

          // setMessages((prevMessages) => [...prevMessages, ...vehicleInfoMessages]);
          vehicleInfoMessages.forEach(message => {
            addMessage(message.role, message.content);
          });
        }
      }
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      const defaultResponse = "We regret to inform you that we couldn't address your question at this moment. Kindly give us another moment to rectify this, and we sincerely apologize for any inconvenience caused. Please feel free to try again shortly. Thank you for your understanding.";
      // setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: defaultResponse }]);
      addMessage('assistant', defaultResponse);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      clearChat();
    };
  }, []);

  const handleSaveVehicleInfo = async (info) => {
    console.log('Saving vehicle info:', info);
    setIsSaving(true);
    if (!token) {
      history.push(`/signin`);
      setPreviousLocation(location.pathname);
    }
    else {
      try {
        setIsLoading(true);
        const prefResponse = await addUserPreferences(userID, token, {
          Make: info.make,
          Model: info.model,
          StartYear: info.startYear,
          EndYear: info.endYear,
          FuelType: info.fuelType,
        });
        if (prefResponse.status === 200) {
          swal({
            text: 'Preferences saved successfully!',
            icon: 'success',
            dangerMode: true,
          });
        }
      } catch (error) {
        console.log('Error saving preferences.', error.message);
        if (error.response && error.response.status === 401) {
          error.message = 'Unauthorized Access';
          console.log('Unauthorized access. Redirecting to login...');
        }
        swal({
          text: error.message,
          icon: 'error',
          dangerMode: true,
        });
      } finally {
        setIsLoading(false);
        setIsSaving(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  useEffect(() => {
    let animationInterval;
    let dotsCount = 0;

    if (isLoading) {
      setTypingMessage('Typing');
      animationInterval = setInterval(() => {
        dotsCount = (dotsCount + 1) % 4;
        setTypingMessage('Typing' + '.'.repeat(dotsCount));
      }, 500);
    } else {
      clearInterval(animationInterval);
      setTypingMessage('');
    }

    return () => {
      clearInterval(animationInterval);
    };
  }, [isLoading]);

  const handlePromptClick = (prompt) => {
    if (prompt === "knowWhatIWant") {
      history.push('/advancedSearch', { fetchdata: true });
    } else if (prompt === 'notSure') {
      setSuggestions([
        `Give me car options to have my running cost under $${budget}.`,
        "Tell me about electric cars available in the market in New Zealand?",
        'Would you like to explore cars with more specific options?',
        'How many people are traveling?',
      ]);
    }
    setShowPrompts(false);
  };

  console.log('Current messages status:', messages);

  const renderDynamicQuestionStep = () => (
    <div>
      <p>{currentDynamicQuestion}</p>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {dynamicQuestionAnswers.map((option) => (
          <div key={option} style={{ marginBottom: '8px' }}>
            <input
              type="checkbox"
              id={`dynamicOption_${option}`}
              value={option}
              checked={selectedDynamicAnswer.includes(option)}
              onChange={() => handleDynamicAnswerSelection(option)}
              style={{ marginRight: '4px' }}
            />
            <label htmlFor={`dynamicOption_${option}`} style={{ marginLeft: '4px', color: 'black' }}>{option}</label>
          </div>
        ))}
      </div>
      <button className="nextButton" onClick={handleDynamicSkip}>Skip</button>
      <button className="nextButton" onClick={handleDynamicNextStep}>Get Answer</button>
    </div>
  );

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <p>Which body types would you consider for your car?</p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {bodyStyleOptions.map((option) => (
                <div key={option} style={{ marginBottom: '8px' }}>
                  <input
                    type="checkbox"
                    id={`bodyType_${option}`}
                    value={option}
                    checked={selectedBodyTypes.includes(option)}
                    onChange={() => handleBodyStyleSelection(option)}
                    style={{ marginRight: '4px' }}
                  />
                  <label htmlFor={`bodyType_${option}`} style={{ marginLeft: '4px', color: 'black' }}>{option}</label>
                </div>
              ))}
            </div>
            <button className="nextButton" onClick={handleNextStep}>Next</button>
          </div>
        );
      case 2:
        return (
          <div>
            <p>Which fuel type would you like?</p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {fuelTypeOptions.map((option) => (
                <div key={option} style={{ marginBottom: '8px' }}>
                  <input
                    type="checkbox"
                    id={`fuelType_${option}`}
                    value={option}
                    checked={selectedFuelType.includes(option)}
                    onChange={() => handleFuelTypeSelection(option)}
                    style={{ marginRight: '4px' }}
                  />
                  <label htmlFor={`fuelType_${option}`} style={{ marginLeft: '4px', color: 'black' }}>{option}</label>
                </div>
              ))}
            </div>
            <button className="nextButton" onClick={handleNextStep}>Next</button>
          </div>
        );

      case 3:
        return (
          <div>
            <p>Are you considering any specific brands?</p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {vehicleBrandsOptions.map((option) => (
                <div key={option} style={{ marginBottom: '8px' }}>
                  <input
                    type="checkbox"
                    id={`brand_${option}`}
                    value={option}
                    checked={selectedVehicleBrands.includes(option)}
                    onChange={() => handleVehicleBrandOptions(option)}
                    style={{ marginRight: '4px' }}
                  />
                  <label htmlFor={`brand_${option}`} style={{ marginLeft: '4px', color: 'black' }}>{option}</label>
                </div>
              ))}
            </div>
            <button className="nextButton" onClick={handleNextStep}>Next</button>
          </div>
        );
      case 4:
        return (
          <div>
            <p>What is your expected budget?</p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {budgetOptions.map((option) => (
                <div key={option} style={{ marginBottom: '8px' }}>
                  <input
                    type="checkbox"
                    id={`brand_${option}`}
                    value={option}
                    checked={selectedBudget.includes(option)}
                    onChange={() => handleBudgetSelection(option)}
                    style={{ marginRight: '4px' }}
                  />
                  <label htmlFor={`brand_${option}`} style={{ marginLeft: '4px', color: 'black' }}>{option}</label>
                </div>
              ))}
            </div>
            <button className="nextButton" onClick={handleNextStep}>Next</button>
          </div>
        );
      case 5:
        return (
          <div>
            <p>What are the popular features you want?</p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {featureOptions.map((option) => (
                <div key={option} style={{ marginBottom: '8px' }}>
                  <input
                    type="checkbox"
                    id={`brand_${option}`}
                    value={option}
                    checked={selectedFeatures.includes(option)}
                    onChange={() => handleFeatureSelection(option)}
                    style={{ marginRight: '4px' }}
                  />
                  <label htmlFor={`brand_${option}`} style={{ marginLeft: '4px', color: 'black' }}>{option}</label>
                </div>
              ))}
            </div>
            <button className="nextButton" onClick={handleFinalNextStep}>Next</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`chatbot-container ${isOpen ? 'open' : ''}`}>
      {isOpen && (
        <div className="header">
          <pheader>Find Cars - Assistant</pheader>
          <div className="close-button" onClick={toggleChatbot}>
            <i className="fas fa-sort-down fa-lg"></i>
          </div>
        </div>
      )}
      {isOpen && (
        <div className="chatbot-content">
          <div className="messages">
            <div className="" style={{ margin: '10px', display: 'flex', justifyContent: 'center' }}>
              <img
                src={chatbot}
                alt="Chatbot Icon"
                className="chatbot-icon"
              />
            </div>
            <div className="msg">
              <div className="bot-message">Need help choosing the right car for your lifestyle? I've got you covered!😊</div>
              {messages.map((message, index) => (
                <div key={index} className={message.role === 'user' ? 'user-message' : 'bot-message'}>
                  {message.content && typeof message.content === 'string' ? (
                    <div dangerouslySetInnerHTML={{ __html: message.content.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>').replace(/\n\n/g, '<br><br>') }} />
                  ) : (
                    <div>{message.content}</div>
                  )}
                </div>
              ))}
            </div>
            {isLoading && !isSaving && (
              <div className="bot-message">{typingMessage}</div>
            )}
            {(messages.length === 0 || suggestions.length > 0) && showPrompts && (
              <div>
                <button className='genq' onClick={() => handlePromptClick("knowWhatIWant")}>I know what I wanted</button>
                <button className='genq' onClick={() => handlePromptClick("notSure")}>I am not sure, let me know about options</button>
              </div>
            )}
            {suggestions.map((question, index) => (
              <button className='genq' key={index} onClick={() => handleSuggestionClick(question)} >
                {question}
              </button>
            ))}
            {renderCurrentStep()}
            {currentDynamicQuestion && renderDynamicQuestionStep()}
          </div>
          <div className="chat-input">
            <input type="text" value={userInput} onChange={handleUserInput} onKeyDown={handleKeyPress} placeholder="Ask your question related to vehicles..." />
            <IconButton>
              <SendIcon onClick={handleSendMessage} />
            </IconButton>
          </div>
        </div>
      )}
      <div className={`chatbot-trigger ${isOpen ? 'expanded' : ''}`} onClick={toggleChatbot}>
        <Tooltip className="toolTip" title="Need help choosing the right car for your lifestyle? I've got you covered!😊" open={!isOpen && !hasClicked} arrow>
          <img
            src={chatbot}
            alt="Chatbot Icon"
            className="chatbot-icon"
          />
        </Tooltip>
      </div>
    </div>
  );
};
