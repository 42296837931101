function performRedirect(plateNumber, VIN, previousLocation, setPreviousLocation, history) {
    if (previousLocation === '/details') {
        if (!plateNumber && !VIN) {
            history.replace('/');
        } else {
            const plateOrvin = plateNumber ? `plate=${plateNumber}` : `vin=${VIN}`;
            history.replace('/details', { plateOrvin });
        }
        setPreviousLocation('');
    } else {
        history.replace('/');
    }
}

export { performRedirect };
