// Purchase Complete Buyer side
import axios from 'axios';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../assets/CSS/PurchaseComplete.css';
import { jwtToken } from '../Context/DataContext';
import { Spinner, ButtonSpinner } from './Spinner';
import { UserContext } from '../Context/UserContext';
import swal from 'sweetalert';
import { BsFillCheckCircleFill, BsFillXCircleFill, BsFillQuestionCircleFill } from 'react-icons/bs';
import { getCurrentUTCTime, TimestampToUTC, UTCdatetimeToFormattedDateTime } from '../utils/DateAndTimeUtils';

import ListGroup from 'react-bootstrap/ListGroup';

export default function BuyerAmountOffer(props) {
  const history = useHistory();
  const location = useLocation();
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const [stolen, setStolen] = useState();
  const [initialLoading, setInitialLoading] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const { plateNumber, VIN, offerID, amount } = location?.state || '';
  //Outage Scenario
  const [stolenCheckOutage, setStolenCheckOutage] = useState(false);

  const checkStolen = useCallback(async () => {
    if (VIN && userID && token) {
      setInitialLoading(true);
      try {
        const outageResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}get-outagebystatus`, {}, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const currentDateTime = getCurrentUTCTime();
        const isMatchingStolenOutage = await outageResponse.data.some(item => {
          if (item.API === 'STOLEN' && item.OutageType === 'NZTA' &&
            currentDateTime > TimestampToUTC(item.OutageStartDateTime) && currentDateTime < TimestampToUTC(item.OutageEndDateTime)) {
            setStolenCheckOutage(true);
            return true;
          }
          return false;
        });

        if (!isMatchingStolenOutage) {
          const response = await axios.post(`${process.env.REACT_APP_API_COMMON}stolencheck`,
            { VIN, userID }, { headers: { Authorization: `Bearer ${token}` } }
          );
          if (response.status === 200) {
            if (response.data.ReportedStolen === 'true') {
              setStolen('true');
              swal({
                text: `You can't purchase this ${plateNumber || VIN} vehicle because it's a stolen vehicle.`,
                icon: 'error',
                dangerMode: true,
              }).then(() => {
                history.push(`/placedOffers`);
              });
            } else {
              setStolen('false');
            }
          }
        } else {
          swal({
            title: 'Services Unavailable',
            text: 'NZTA Reported planned outage\nCurrently could not check whether the vehicle is stolen.\n If you wish to continue click on Continue else Cancel',
            icon: 'warning',
            buttons: {
              cancel: "Cancel",
              defeat: {
                text: 'Continue',
                className: 'request-button' // Add a custom class name for the button
              }
            },
            dangerMode: true,
          }).then(async (willDelete) => {
            if (!willDelete) {
              history.goBack();
            }
          });
        }
      } catch (error) {
        setStolen('unavailable');
        if (error.response.status === 429) {
          swal({
            text: `${error.response.data}`,
            icon: 'warning',
            dangerMode: true,
          });
        } else {
          swal({
            title: 'Something went wrong!',
            text: 'Could not check whether the vehicle is stolen for now. If you wish to continue click on continue else cancel',
            icon: 'warning',
            buttons: {
              cancel: "Cancel",
              defeat: {
                text: 'Continue',
                className: 'request-button' // Add a custom class name for the button
              }
            },
            dangerMode: true,
          }).then(async (willDelete) => {
            if (!willDelete) {
              history.push(`/placedOffers`);
            }
          });
        }
      } finally {
        setInitialLoading(false);
      }
    }
  }, [VIN, userID, token]);

  useEffect(() => {
    checkStolen();
  }, [checkStolen]);

  const purchaseVehicle = async () => {
    swal({
      title: `Complete purchasing for ${plateNumber || VIN} vehicle.`,
      text: `I confirm the purchase $${amount} of amount for the ${plateNumber || VIN} vehicle!`,
      icon: "info",
      buttons: {
        cancel: "Cancel",
        defeat: {
          text: 'Purchase',
          className: 'request-button' // Add a custom class name for the button
        }
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setPurchaseLoading(true);
          try {
            const response = await axios.post(`${process.env.REACT_APP_API_COMMON}purchase`,
              { userID, offerID }, { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.status === 200) {
              swal({
                text: 'Purchase Completed successfully!',
                icon: 'success',
                dangerMode: true,
              }).then(() => {
                history.push(`/boughtVehicles`);
              });
            }
          } catch (error) {
            swal({
              text: 'Purchase unsuccessful. Please try again!',
              icon: 'error',
              dangerMode: true,
            }).then(() => {
              history.push(`/placedOffers`);
            });
          } finally {
            setPurchaseLoading(false);
          }
        }
      });
  };

  return (
    <div>
      {initialLoading ? (<Spinner></Spinner>) : (
        <div className="container">
          <h2 style={{ color: 'black' }} className="text-center">
            Vehicle Purchase
          </h2>
          &nbsp;
          <div className="form-group mt-2">
            <dl className="row">
              <dt className="col-sm-3">Plate Number/VIN:</dt>
              <dd className="col-sm-3">{plateNumber || VIN}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Vehicle Price:</dt>
              <dd className="col-sm-4">${amount}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">
                Stolen vehicle
              </dt>
              <dd className="col-sm-4">
                {stolen === 'true' ? (
                  <ListGroup.Item className="stolen-failed">
                    <BsFillXCircleFill /> &nbsp; Failed
                  </ListGroup.Item>
                ) : stolen === 'false' ? (
                  <ListGroup.Item className="stolen-passed" >
                    <BsFillCheckCircleFill /> &nbsp; Passed
                  </ListGroup.Item>
                ) : (
                  <ListGroup.Item className="stolen-unavailable">
                    <BsFillQuestionCircleFill /> &nbsp; Unavailable
                  </ListGroup.Item>
                )}
              </dd>
            </dl>
          </div>
          <br></br>
          <div align="center">
            <Button
              onClick={purchaseVehicle}
              variant="danger"
              disabled={stolen === true || stolen === '' || purchaseLoading ? true : false}
            >
              {purchaseLoading ? <ButtonSpinner /> : 'Complete Purchase'}
            </Button>
          </div>
        </div>
      )}
    </div >
  );
}
