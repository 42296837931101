import React from 'react';
import './CSS/Faq.css';
import Accordion from 'react-bootstrap/Accordion';

function FAQs() {
  return (
    <div className="faq-container container-fluid p-spacing">
      <div className='row my-5'>
        <center>
          {' '}
          <h1 className="faq-heading" style={{ fontSize: '2.5rem', fontFamily: 'bookman old style', letterSpacing: '4px' }}>Frequently Asked Questions</h1>
        </center>
        <hr />
        <Accordion defaultActiveKey={['0']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>General</Accordion.Header>
            <Accordion.Body>
              <section className="faq-section">
                <div>
                  <details>
                    <summary className="summary">User profile</summary>
                    <div className="answer">
                      Users can log into the system using their Google or Facebook accounts.
                      For more details <a href="https://help.wakaonline.nz/user-profile.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How to search a vehicle?</summary>
                    <div className="answer">
                      A vehicle can be searched by entering the Plate number or Vehicle Identification Number(VIN) of the
                      vehicle. Also a vehicle can be searched by using Make or Model.
                      For more details <a href="https://help.wakaonline.nz/search-vehicle.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How do I create an account?</summary>
                    <div className="answer">
                      You can create your account using your Gmail Account or Facebook Account.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Do I have to pay to create an account?</summary>
                    <div className="answer">
                      No. You do not need to pay to create an account.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">What fees are involved in using this platform?</summary>
                    <div className="answer">
                      There is no fee involved in using the platform as a general user to search for vehicles or as a buyer. For listing a vehicle, you need to purchase passes, otherwise, you cannot list a vehicle.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How to close an account?</summary>
                    <div className="answer">
                      If the users want to close the account, they can do so by choosing the option to "close account."
                      For more details <a href="https://help.wakaonline.nz/close-account.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                </div>
              </section>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Seller</Accordion.Header>
            <Accordion.Body>
              <section className="faq-section">
                <div>
                  <details>
                    <summary className="summary">What does claim mean?</summary>
                    <div className="answer">
                      Claim, in the context of our platform, refers to the act of asserting ownership or rights to a specific vehicle. It is the process by which a user declares that they own a particular vehicle and wish to list it for sale or otherwise establish their ownership within our system.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">What benefits do I get by claiming a vehicle?</summary>
                    <div className="answer">
                      Claiming a vehicle provides you with a sense of ownership, credibility, and a streamlined process for interacting with interested parties.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How to claim a vehicle?</summary>
                    <div className="answer">
                      Owner should login to the application first. After searching a vehicle, as the registered party you can claim the vehicle. Also, the registered party can claim the vehicle as a trader as well.
                      For more details <a href="https://help.wakaonline.nz/claim-vehicle.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How much does it cost to claim a vehicle?</summary>
                    <div className="answer">
                      Claiming a vehicle on this platform is currently free of charge. There are no fees associated with asserting ownership of a vehicle and enjoying the benefits of 	verified ownership.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Will my personal details be published if I claim a vehicle?</summary>
                    <div className="answer">
                      No, your personal details will not be published when you claim a vehicle. We prioritize the security and privacy of our users.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">What is the difference between claim and list a vehicle?</summary>
                    <div className="answer">
                      Claiming a vehicle establishes ownership and credibility, while listing a vehicle involves creating a public advertisement to showcase the vehicle's details to potential buyers or users. Listing a vehicle also enables interested buyers to make offers for the vehicle.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Why do claims expire?</summary>
                    <div className="answer">
                      Claims on vehicles expire to 	ensure the accuracy and relevancy of our platform's information. As the automotive landscape constantly evolves, vehicles may change ownership, become unavailable, or undergo modifications. By setting a claim expiration period, we encourage users to regularly review and update their claimed vehicles' status.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How to list a vehicle?</summary>
                    <div className="answer">
                      Owner can list the vehicle after they claim vehicle and when a pass is available.
                      For more details <a href="https://help.wakaonline.nz/list-vehicle.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How can I know the market value of my vehicle?</summary>
                    <div className="answer">
                      You can list with the price that you see fit, but buyers can also make offers, and then you can choose the appropriate offer.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How to accept or reject an offer?</summary>
                    <div className="answer">
                      Once the seller receives an offer, they have the option to either accept or reject it.
                      For more details <a href="https://help.wakaonline.nz/accept-reject-offer.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Can I contact the buyer again after rejecting the offer?</summary>
                    <div className="answer">
                      Yes, you can contact the buyer after rejecting an offer. On the 'Received Offers' page, there 	is an option to message buyers. From there, as a seller, you can initiate contact and continue the conversation with the buyer even if you have previously rejected their offer.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Can I reject and offer back a different amount?</summary>
                    <div className="answer">
                      Yes, you can reject an offer and accept another offer. Our platform allows you to consider different offers and choose the one that best suits your preferences.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How do I know when the claims expire? </summary>
                    <div className="answer">
                      You will see the claim expiry date in your account under claims.
                      For more details <a href="https://help.wakaonline.nz/claim-reminder.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How to purchase a pass?</summary>
                    <div className="answer">
                      Go to your account and you will see purchase new passes under Passes.
                      For more details <a href="https://help.wakaonline.nz/purchasing-pass.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">What is the difference between single and bundle pass? </summary>
                    <div className="answer">
                      A Single Pass provides access 	to features for a single listing or vehicle, a Bundle Pass, on the other hand, offers access to a package of features that can be used for multiple listings or vehicles.
                      For more details <a href="https://help.wakaonline.nz/fees.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Do passes expire? </summary>
                    <div className="answer">
                      Expiry of passes differ. You can see them when purchasing and also in your account under passes.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How long can we list using a single pass?</summary>
                    <div className="answer">
                      Once the vehicle is listed using a pass, listings don't expire as long as you keep the claim active until the vehicle is sold.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How do listings expire?</summary>
                    <div className="answer">
                      Listings don't expire unless claim is not active or sell your vehicle.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How to chat with buyers?</summary>
                    <div className="answer">
                      When a seller receives an offer from a buyer, they can initiate a chat conversation with the buyer before accepting or rejecting the offer through the chat button.
                      For more details <a href="https://help.wakaonline.nz/chat-with-buyer.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Is there a way to verify the buyer? </summary>
                    <div className="answer">
                      Currently, we do not offer a buyer verification process on our platform. You may verify when they meet you in person to inspect or test drive the vehicle by verifying their driver license.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How will I get payment from the buyer?</summary>
                    <div className="answer">
                      The payment for the vehicle will occur once the seller and buyer physically meet as you would normally do in any private vehicle sale. All payments for the vehicle are completely outside Waka Online and we will never ask you to pay.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">The buyer failed to notify purchase on the system. What do I do as seller?</summary>
                    <div className="answer">
                      You can contact the buyer directly to inquire about the status of their notification. Politely remind them of the importance of updating the system to complete the purchase process. If you cannot get hold of the buyer, wait for the accepted offer to expire and unclaim your vehicle.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Do I have to pay any additional fees to Waka Online on completing the purchase? </summary>
                    <div className="answer">
                      No, there are no additional fees to be paid to Waka Online upon completing the purchase. Your payment for the listing pass is the only financial transaction expected as part of the completion process.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Do I have to complete purchases on the platform after selling the vehicle?</summary>
                    <div className="answer">
                      Yes, for transactions facilitated through our platform, we encourage both buyers and sellers to complete the purchase process on the platform.
                    </div>
                  </details>
                </div>
              </section>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>Buyer</Accordion.Header>
            <Accordion.Body>
              <section className="faq-section">
                <div>
                  <details>
                    <summary className="summary">How to request to list a vehicle?</summary>
                    <div className="answer">
                      Once the owner claims the vehicle but does not list it for sale, a potential buyer has the option to request the seller to list the vehicle by clicking request to list button.
                      For more details <a href="https://help.wakaonline.nz/request-list-vehicle.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How do I know if the vehicle has been reported stolen? </summary>
                    <div className="answer">
                      You can verify the vehicle's status by conducting a background check in the vehicle details. This check will provide information about whether the vehicle has been reported stolen, helping you make an informed decision before purchasing or engaging with the vehicle.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Do I have to pay for the vehicle reports?</summary>
                    <div className="answer">
                      No, there is no charge for accessing vehicle reports.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">Is there a way to verify the seller?</summary>
                    <div className="answer">
                      Currently, we do not offer a seller verification process on our platform. But their relationship with the vehicle as the registered party is verified when they claimed the vehicle.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How to offer an amount for a listed vehicle?</summary>
                    <div className="answer">
                      When you attempt to offer an amount for a listed vehicle, you have the option to modify the offered price before finalizing. This enables the you to adjust the offered amount and propose a new price.
                      For more details <a href="https://help.wakaonline.nz/offer-amount-list-vehicle.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How to complete the purchase?</summary>
                    <div className="answer">
                      Once the seller accepted the buyer's offer, the buyer can purchase the vehicle.
                      For more details <a href="https://help.wakaonline.nz/purchase-accepted-vehicle-offer.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How to chat with seller?</summary>
                    <div className="answer">
                      When searching for a vehicle and finding a listed one, users have the option to communicate with the sellers.
                      For more details <a href="https://help.wakaonline.nz/chat-seller.html" target='_blank' rel='noreferrer'>click here</a>.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How do I pay for the vehicle?</summary>
                    <div className="answer">
                      Payment will occur once the seller and buyer physically meet. At the time of the meeting, the buyer can provide the agreed-upon payment method, such as cash or a certified check, directly to the seller.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">What advantages do I get by completing purchase on my profile after buying the vehicle?</summary>
                    <div className="answer">
                      Updating your profile with the completed purchase confirms your ownership of the vehicle, providing a clear record of the transaction. Also, A complete purchase history on your profile can establish a positive track record for potential future transactions on the platform and enhances your credibility within the platform's community.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">What does ‘Failed’ on Recall Status mean? </summary>
                    <div className="answer">
                      A 'Failed' status on Recall Status indicates that there is a known 	recall associated with the vehicle. This recall has been identified by the manufacturer or regulatory authorities due to safety concerns 	or defects.
                    </div>
                  </details>
                  <details>
                    <summary className="summary">How trustworthy are the information you provide on odometer and security? </summary>
                    <div className="answer">
                      The information we provide on odometer readings and vehicle security is sourced directly from the New Zealand government database. This ensures a high level of accuracy and reliability in the data presented to users.
                    </div>
                  </details>
                </div>
              </section>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default FAQs;
