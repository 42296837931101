import { createContext, useState } from 'react';

export const ChatBotContext = createContext();

export const ChatBotProvider = ({ children }) => {
    const [chatMessages, setChatMessages] = useState([]);

    const addMessage = (role, content) => {
        setChatMessages((prevMessages) => [...prevMessages, { role, content }]);
    };

    const clearChat = () => {
        setChatMessages([]);
    };

    return (
        <ChatBotContext.Provider value={{ chatMessages, addMessage, clearChat, setChatMessages }}>
            {children}
        </ChatBotContext.Provider>
    );
};
