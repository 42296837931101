import React, { useEffect, useState, useContext } from 'react';
import '../assets/CSS/Regisration.css';
import google from '../assets/social-icons/google.png';
import facebook from '../assets/social-icons/facebook.png';
import amazon from '../assets/social-icons/amazon.png';
import apple from '../assets/social-icons/apple.png';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Spinner } from './Spinner';
import { UserContext } from '../Context/UserContext';
import swal from '@sweetalert/with-react';
import { clearDetailsLocalStorage } from './../utils/LocalStorageUtils';
import waka_logo from '../assets/img/waka-logo.png';
import { performRedirect } from '../utils/Redirect';
import { VehicleDataContext } from '../Context/VehicleDataContext';
import { GlobalContext } from '../Context/GlobalContext';

export default function SingleSignOn() {
  const history = useHistory();
  const { setUserID, setUserStatus, setUser } = useContext(UserContext);
  const [loaded, setLoaded] = useState(true);
  const { plateNumber, VIN } = useContext(VehicleDataContext);
  const { previousLocation, setPreviousLocation } = useContext(GlobalContext);

  useEffect(() => {
    (async () => {
      setLoaded(true);
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: false,
        });

        // Fetch user details
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_COMMON}user`,
            { email: user.signInUserSession.idToken.payload.email },
            { headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` } }
          );

          setUserID(response.data.UserID);
          setUserStatus(response.data.Status);
          setUser(response.data);


          if (response.data.Status !== 'Active') {
            swal({
              text: response.data.Status === 'Blocked' ? "Your account has been blocked!"
                : response.data.Status === 'Suspended' ? "Your account has been suspended!"
                  : "Your account status is invalid!",
              icon: 'error',
              dangerMode: true,
            }).then(async () => {
              clearDetailsLocalStorage();
              try {
                await Auth.signOut();
              } catch (error) {
                swal({
                  text: 'Failed to sign out',
                  icon: 'error',
                  dangerMode: true,
                });
              }
            });
          } else {
            performRedirect(plateNumber, VIN, previousLocation, setPreviousLocation, history);
          }

        } catch (error) {
          swal({
            text: 'Failed to retrieve the user details',
            icon: 'error',
            dangerMode: true,
          }).then(async () => {
            clearDetailsLocalStorage();
            try {
              await Auth.signOut();
            } catch (error) {
              swal({
                text: 'Failed to sign out',
                icon: 'error',
                dangerMode: true,
              });
            }
          });
        }

      } catch (error) {
        console.log(error);
      } finally {
        setLoaded(false); // Set loaded to false after the processing is done
      }
    })();
  }, []);

  return (
    <>
      {loaded ? (<div className="middle"><Spinner></Spinner></div>) : (
        <div className="App" >
          <div className="outer chat_texts">
            <div className="inner">
              <div className="waka_logo">
                <img src={waka_logo} alt="Waka Logo" />
              </div>
              <div className="">
                <label className="custom-control-label">Sign in With Your Social Account</label>
              </div>
              <div className="social-buttons mt-2">
                {process.env.REACT_APP_ENABLE_GOOGLE_SSO === 'true' && <button
                  onClick={() => {
                    Auth.federatedSignIn({ provider: 'Google' });
                  }}
                  className="SSO-Google btn-lg btn-block mt-3"
                >
                  <img className="social" src={google} alt="google-sign" />
                  Continue with Google
                </button>}
                {process.env.REACT_APP_ENABLE_FACEBOOK_SSO === 'true' && <button
                  className="SSO-Facebook btn-lg btn-block mt-3"
                  onClick={() => {
                    Auth.federatedSignIn({ provider: 'Facebook' });
                  }}
                >
                  <img className="social" src={facebook} alt="facebook-sign" />
                  Continue with Facebook
                </button>}
                {process.env.REACT_APP_ENABLE_AMAZON_SSO === 'true' && <button
                  className="SSO-Amazon btn-lg btn-block mt-3"
                  onClick={() => {
                    Auth.federatedSignIn({ provider: 'LoginWithAmazon' });
                  }}
                >
                  <img className="social" src={amazon} alt="Amazon-sign" />
                  Continue with Amazon
                </button>}
                {process.env.REACT_APP_ENABLE_APPLE_SSO === 'true' && <button
                  className="SSO-Apple btn-lg btn-block mt-3"
                  onClick={() => {
                    Auth.federatedSignIn({ provider: 'SignInWithApple' });
                  }}
                >
                  <img className="social" src={apple} alt="Apple-sign" />
                  Continue with Apple
                </button>}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
