import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export const SearchContext = createContext({});

export const SearchProvider = ({ children }) => {
  const location = useLocation();

  const [selectedMake, setSelectedMake] = useState(
    localStorage.getItem('selectedMake') ? JSON.parse(localStorage.getItem('selectedMake')) : '',
  );
  const [selectedModel, setSelectedModel] = useState(
    localStorage.getItem('selectedModel') ? JSON.parse(localStorage.getItem('selectedModel')) : '',
  );
  const [selectedYearRangeStart, setSelectedYearRangeStart] = useState(
    localStorage.getItem('selectedYearRangeStart') ? JSON.parse(localStorage.getItem('selectedYearRangeStart')) : '',
  );
  const [selectedYearRangeEnd, setSelectedYearRangeEnd] = useState(
    localStorage.getItem('selectedYearRangeEnd') ? JSON.parse(localStorage.getItem('selectedYearRangeEnd')) : '',
  );
  const [selectedFuelType, setSelectedFuelType] = useState(
    localStorage.getItem('selectedFuelType') ? JSON.parse(localStorage.getItem('selectedFuelType')) : '',
  );
  const [selectedBodyStyle, setSelectedBodyStyle] = useState(
    localStorage.getItem('selectedBodyStyle') ? JSON.parse(localStorage.getItem('selectedBodyStyle')) : '',
  );
  const [selectedLocation, setSelectedLocation] = useState(
    localStorage.getItem('selectedLocation') ? JSON.parse(localStorage.getItem('selectedLocation')) : '',
  );
  const [selectedStatus, setSelectedStatus] = useState(
    localStorage.getItem('selectedStatus') ? JSON.parse(localStorage.getItem('selectedStatus')) : '',
  );
  const [selectedOdometerRange, setSelectedOdometerRange] = useState(
    localStorage.getItem('selectedOdometerRange') ? JSON.parse(localStorage.getItem('selectedOdometerRange')) : null,
  );
  const [selectedPriceRange, setSelectedPriceRange] = useState(
    localStorage.getItem('selectedPriceRange') ? JSON.parse(localStorage.getItem('selectedPriceRange')) : null,
  );
  const [searchResults, setSearchResults] = useState(
    localStorage.getItem('searchResults') ? JSON.parse(localStorage.getItem('searchResults')) : [],
  );
  const [selectedDealerCompany, setSelectedDealerCompany] = useState(
    localStorage.getItem('selectedDealerCompany') ? JSON.parse(localStorage.getItem('selectedDealerCompany')) : '',
  );

  const [loading, setLoading] = useState(false);
  const [searchMessage, setSearchMessage] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    localStorage.setItem('selectedMake', JSON.stringify(selectedMake));
  }, [selectedMake]);

  useEffect(() => {
    localStorage.setItem('selectedModel', JSON.stringify(selectedModel));
  }, [selectedModel]);

  useEffect(() => {
    localStorage.setItem('selectedYearRangeStart', JSON.stringify(selectedYearRangeStart));
  }, [selectedYearRangeStart]);

  useEffect(() => {
    localStorage.setItem('selectedYearRangeEnd', JSON.stringify(selectedYearRangeEnd));
  }, [selectedYearRangeEnd]);

  useEffect(() => {
    localStorage.setItem('selectedFuelType', JSON.stringify(selectedFuelType));
  }, [selectedFuelType]);

  useEffect(() => {
    localStorage.setItem('selectedBodyStyle', JSON.stringify(selectedBodyStyle));
  }, [selectedBodyStyle]);

  useEffect(() => {
    localStorage.setItem('selectedLocation', JSON.stringify(selectedLocation));
  }, [selectedLocation]);

  useEffect(() => {
    localStorage.setItem('selectedStatus', JSON.stringify(selectedStatus));
  }, [selectedStatus]);

  useEffect(() => {
    localStorage.setItem('selectedOdometerRange', JSON.stringify(selectedOdometerRange));
  }, [selectedOdometerRange]);

  useEffect(() => {
    localStorage.setItem('selectedPriceRange', JSON.stringify(selectedPriceRange));
  }, [selectedPriceRange]);

  useEffect(() => {
    localStorage.setItem('searchResults', JSON.stringify(searchResults));
  }, [searchResults]);

  useEffect(() => {
    localStorage.setItem('selectedDealerCompany', JSON.stringify(selectedDealerCompany));
  }, [selectedDealerCompany]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    const traderNo = queryParameters.get("trader-no");

    const fetchData = async () => {
      const startIndex = 0;
      const endIndex = 20;
      const perPage = 20;
      const searchParams = {
        selectedMake,
        selectedModel,
        selectedYearRangeStart,
        selectedYearRangeEnd,
        selectedFuelType,
        selectedBodyStyle,
        selectedLocation,
        selectedOdometerRange,
        selectedPriceRange,
        selectedStatus,
        selectedDealer: selectedDealerCompany,
        startIndex,
        endIndex,
        maxResults: perPage,
      };

      try {
        const response = await axios.post(`${process.env.REACT_APP_SEARCH_API}find`, searchParams);
        const { vehicles, totalPages } = response.data;
        setVehicles(vehicles);
        setTotalPages(totalPages);
        if (vehicles.length === 0) {
          setSearchResults([]);
          setSearchMessage('No Results Found, Maybe not in the Active State');
        } else {
          const updatedSearchResults = await Promise.all(
            vehicles.map(async (vehicle) => {
              return { ...vehicle };
            }),
          );
          setSearchResults(updatedSearchResults);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (!traderNo) {
      fetchData();
    } else {
      setLoading(true);
    }
  }, []);

  return (
    <SearchContext.Provider
      value={{
        selectedMake,
        setSelectedMake,
        selectedModel,
        setSelectedModel,
        selectedYearRangeStart,
        setSelectedYearRangeStart,
        selectedYearRangeEnd,
        setSelectedYearRangeEnd,
        selectedFuelType,
        setSelectedFuelType,
        selectedBodyStyle,
        setSelectedBodyStyle,
        selectedLocation,
        setSelectedLocation,
        selectedStatus,
        setSelectedStatus,
        selectedOdometerRange,
        setSelectedOdometerRange,
        selectedPriceRange,
        setSelectedPriceRange,
        searchResults,
        setSearchResults,
        searchMessage,
        setSearchMessage,
        totalPages,
        setTotalPages,
        vehicles,
        setVehicles,
        selectedDealerCompany,
        setSelectedDealerCompany,
        loading,
        setLoading
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  return useContext(SearchContext);
};

export default SearchContext;
