import { Card, CardContent, CardHeader, Grid, Rating, Typography } from '@mui/material';
import { FaCar, FaCalendarAlt } from 'react-icons/fa';
import { GrStatusGood } from "react-icons/gr";
import { BsFillGearFill } from "react-icons/bs";
import { IoIosColorPalette, IoIosSpeedometer } from "react-icons/io";
import { MdClass } from "react-icons/md";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import { UserContext } from '../Context/UserContext';
import { jwtToken } from '../Context/DataContext';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import swal from 'sweetalert';
import Button from '@mui/material/Button';

const ImportedVehicles = () => {

  const [importedVehicles, setImportedVehicles] = useState(null);
  const { userID } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const [loading, setLoading] = useState(false);


  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#ff3d47',
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47',
    },
  });

  const fetchImportedVehicles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_COMMON}importedVehicles`, {});
      setImportedVehicles(response.data);
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleInterest = async (vehicle) => {
    setLoading(true);
    let interest = true;
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_COMMON}importedVehicles`,
        { chassisNo: vehicle.ChassisNo, userID, interest, token },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        setLoading(false);
        setImportedVehicles((prevVehicles) =>
          prevVehicles.map((v) =>
            v.ChassisNo === vehicle.ChassisNo
              ? { ...v, Interests: [{ UserID: userID, Interest: true }] }
              : v
          )
        );
        swal({
          title: 'Success!',
          text: 'Vehicle added to your interests',
          icon: 'success',
          button: 'Ok',
        });
      }
    }
    catch (error) {
      setLoading(false);
      swal({
        title: 'Error!',
        text: 'Error occured while adding vehicle to your interests',
        icon: 'error',
        button: 'Ok',
      });
    }
  };

  useEffect(() => {
    fetchImportedVehicles();
  }, []);

  return (
    <>
      <div style={{ margin: '20px 0px 10px 0px' }}>
        {importedVehicles && importedVehicles.length > 0 && (
          <Button className="buttonOncard" variant="contained"
            style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3)' }}>
            Imported Vehicles
          </Button>
        )}
        <div className="vehiclecards" style={{ display: 'flex', flexWrap: 'wrap' }}>
          {importedVehicles && importedVehicles.map((vehicle, index) => (
            <div key={index} className="col-md-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Card sx={{ maxWidth: 300, minHeight: 300 }} className="SS  d-felx justify-content-center" key={index}>
                {vehicle.CompanyName ? (
                  <>
                    <CardHeader
                      avatar={vehicle.CompanyLogoURL ?
                        <img
                          src={vehicle.CompanyLogoURL}
                          alt="Company Logo"
                          style={{ width: '40px', height: '40px' }}
                        />
                        : null}
                      title={vehicle.CompanyName}
                    />
                  </>
                ) : <CardHeader
                  avatar={
                    <div style={{ height: '40px' }}></div>
                  }
                />}
                <CardContent style={{ padding: '0px 8px 30px 8px' }}>
                  <Typography variant="body2" color="text.secondary" >
                    <Grid container spacing={2} >
                      <Grid item xs={12} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '12px' }}>
                          <div>
                            <div style={{ paddingLeft: '15px', fontSize: '20px', fontWeight: 'bold', color: '#000' }} >{vehicle.Model}</div>
                          </div>
                          {userID && (
                            <div >
                              <StyledRating
                                name="customized-color"
                                defaultValue={
                                  (vehicle.Interests && vehicle.Interests.length > 0)
                                    ? vehicle.Interests.find(interest => interest.UserID === userID)?.Interest || false
                                    : false
                                }
                                max={1}
                                readOnly={
                                  (vehicle.Interests && vehicle.Interests.length > 0)
                                    ? vehicle.Interests.find(interest => interest.UserID === userID)?.Interest || false
                                    : false
                                }
                                disabled={loading}
                                icon={<StarIcon />}
                                emptyIcon={<StarBorderIcon />}
                                style={{ margin: '10px' }}
                                onClick={() => {
                                  handleInterest(vehicle);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <FaCar /> &nbsp; ChassisNo: &nbsp; {vehicle.ChassisNo}
                      </Grid>
                      <Grid item xs={12}>
                        <MdClass /> &nbsp; Grade: &nbsp; {vehicle.Grade}
                      </Grid>
                      <Grid item xs={12}>
                        <FaCalendarAlt />  &nbsp; Year: &nbsp; {vehicle.Year}
                      </Grid>
                      <Grid item xs={12}>
                        <IoIosSpeedometer /> &nbsp; Engine: &nbsp; {vehicle.Engine}
                      </Grid>
                      <Grid item xs={12}>
                        <IoIosColorPalette /> &nbsp; Color: &nbsp; {vehicle.Color}
                      </Grid>
                      <Grid item xs={12} >
                        <BsFillGearFill /> &nbsp; Transmission: &nbsp; {vehicle.Transmission}
                      </Grid>
                      <Grid item xs={12}>
                        <GrStatusGood /> &nbsp; Status: &nbsp; {vehicle.Status}
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
              </Card>
            </div>
          ))
          }
        </div >
      </div>
    </>
  );
};

export default ImportedVehicles;