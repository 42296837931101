import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import { ButtonSpinner } from '../components/Spinner';
import Button from '../pages/claim/button';
import axios from 'axios';
import swal from 'sweetalert';
import { jwtToken } from '../Context/DataContext';
import { VehicleDataContext } from '../Context/VehicleDataContext';
import { MetadataContext } from '../Context/MetadataContext';
import { GlobalContext } from '../Context/GlobalContext';
import { UserContext } from '../Context/UserContext';

export default function ReClaimModel({ show, claimDetails, reclaimmessage, setShowClaim }) {
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const { setClaimObj, setVehiData, vehiData } = useContext(VehicleDataContext);
  const { setDealerClaims, setIndividualClaims } = useContext(GlobalContext);
  const { locations } = useContext(MetadataContext);
  const [reclaimLoading, setReclaimLoading] = useState(false);
  const [licenseNumber, setlicenseNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [traderNumber, setTraderNumber] = useState('');
  const [ErrorMessage, setErrMessage] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(claimDetails.Location);
  const [locationErrorMessage, setLocationErrMessage] = useState();
  const [limitType, setLimitType] = useState('');
  const claimID = claimDetails.ClaimID;
  const licensePlateRegex = /^[a-zA-Z]{2}\d{6}$/i;
  const traderNumberRegex = /^M/;

  async function reclaim() {
    let errorMessage = '';
    let locationErrorMessage = '';

    if (selectedLocation === '') {
      setSelectedLocation(claimDetails.Location);
    }

    if (!selectedLocation) {
      locationErrorMessage = 'Please select the location!';
    }
    if (claimDetails.IsTrader === 'true') {
      if (!traderNumber) {
        errorMessage = 'Please enter the trader number!';
      } else if (!traderNumberRegex.test(traderNumber)) {
        errorMessage = 'Please enter a valid trader number!';
      }
    } else {
      if (claimDetails.Type === 'Individual') {
        if (!licenseNumber) {
          errorMessage = 'Please enter the license number!';
        } else if (!licensePlateRegex.test(licenseNumber)) {
          errorMessage = 'Please enter a valid license number!';
        }
      } else if (claimDetails.Type === 'Company' && !companyName) {
        errorMessage = 'Please enter the company name!';
      }
    }

    if (errorMessage || locationErrorMessage) {
      setErrMessage(errorMessage);
      setLocationErrMessage(locationErrorMessage);
      return;
    }

    setErrMessage('');
    setLocationErrMessage('');
    setReclaimLoading(true);

    try {
      const reclaimResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}reclaim`,
        { userID, claimID, licenseNumber, companyName, traderNumber, selectedLocation }, { headers: { Authorization: `Bearer ${token}` } }
      );
      if (reclaimResponse.status === 200) {
        if (claimDetails.IsTrader === 'true') {
          setVehiData(prevVehiData => ({
            ...prevVehiData,
            statusDetails: {
              ...prevVehiData.statusDetails,
              ClaimRecord: reclaimResponse.data,
            },
          }));
          setClaimObj(reclaimResponse.data);
          setDealerClaims(prevItems => {
            const index = prevItems.findIndex(item => item.ClaimID === reclaimResponse.data.ClaimID);
            if (index !== -1) {
              const updatedItems = [...prevItems];
              updatedItems[index] = reclaimResponse.data;
              return updatedItems;
            }
            return prevItems;
          });

        } else if (claimDetails.Type === 'Individual') {
          setVehiData(prevVehiData => ({
            ...prevVehiData,
            statusDetails: {
              ...prevVehiData.statusDetails,
              ClaimRecord: reclaimResponse.data,
            },
          }));
          setClaimObj(reclaimResponse.data);
          setIndividualClaims(prevItems => {
            const index = prevItems.findIndex(item => item.ClaimID === reclaimResponse.data.ClaimID);
            if (index !== -1) {
              const updatedItems = [...prevItems];
              updatedItems[index] = reclaimResponse.data;
              return updatedItems;
            }
            return prevItems;
          });

        }

        swal({
          text: 'Re-claim successful. You own this vehicle!',
          icon: 'success',
          dangerMode: true,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 503) {
        swal({
          text: 'The re-claim function is not available now',
          icon: 'error',
          dangerMode: true,
        });
      } else if (error.response && error.response.status === 429) {
        setLimitType(error.response.data);
        swal({
          text: `${limitType}`,
          icon: 'error',
          dangerMode: true,
        });
      } else {
        swal({
          text: 'Re-claim unsuccessful!',
          icon: 'error',
          dangerMode: true,
        });
      }
    } finally {
      setShowClaim(false);
      setReclaimLoading(false);
    }
  }

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowClaim(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <h2 style={{ color: 'black' }} className="text-center">
            Vehicle Re-Claiming Form
          </h2>
          &nbsp;
          <label style={{ color: 'black' }}>{reclaimmessage}</label>
          <br></br>
          &nbsp;
          {claimDetails.PlateNumber ? (
            <div className="form-group">
              <label style={{ color: 'black' }}>Plate Number</label>
              <input type="text" className="form-control mt-2" placeholder={claimDetails.PlateNumber} readOnly />
            </div>
          ) : (
            <div className="form-group">
              <label style={{ color: 'black' }}>VIN</label>
              <input type="text" className="form-control mt-2" placeholder={claimDetails.VIN} readOnly />
            </div>
          )
          }
          &nbsp;
          {/* check if itemslected authorize render */}
          {claimDetails.IsTrader === 'true' ? (
            <div className="form-group">
              <label style={{ color: 'black' }}>Trader Number</label>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter Trader Number"
                onChange={(e) => {
                  setTraderNumber(e.target.value);
                }}
              />
              <p style={{ color: 'red' }}>{ErrorMessage}</p>
            </div>
          ) : (
            <>
              {claimDetails.Type === 'Individual' && (
                <div className="form-group">
                  <label style={{ color: 'black' }}>License Number</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Enter License Number"
                    onChange={(e) => {
                      setlicenseNumber(e.target.value);
                    }}
                  />
                  <p style={{ color: 'red' }}>{ErrorMessage}</p>
                </div>
              )}
              {claimDetails.Type === 'Company' && (
                <div className="form-group">
                  <label style={{ color: 'black' }}>Company Name</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Enter Company Name"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  />
                  <p style={{ color: 'red' }}>{ErrorMessage}</p>
                </div>
              )}
            </>
          )}
          &nbsp;
          <div>
            {/* <label style={{ color: 'black' }}>Location</label> */}
            <FormControl sx={{ m: 0, minWidth: 120, zIndex: 10 }} color="error">
              <InputLabel id="demo-simple-select-helper-label">Location</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedLocation}
                label="Location"
                onChange={(e) => setSelectedLocation(e.target.value)}
              >
                <MenuItem value="">
                  <em>Select a location</em>
                </MenuItem>
                {Object.entries(locations).flatMap(([Region, City], regionIndex) => [
                  <ListSubheader key={regionIndex + '-Region'} sx={{ color: 'Black', fontWeight: 'bold', paddingLeft: '16px' }}>
                    {Region}
                  </ListSubheader>,
                  ...City.map((city, cityIndex) => (
                    <MenuItem key={`${regionIndex}-${cityIndex}-city`} value={city} sx={{ paddingLeft: '32px' }}>
                      {city}
                    </MenuItem>
                  )),
                ])}
              </Select>
            </FormControl>
            <p style={{ color: 'red' }}>{locationErrorMessage}</p>
          </div>
          &nbsp;
          <div className="pb-4" align="center">
            <Button onClick={reclaim} bsStyle="danger" variant="danger" bsSize="large" disabled={reclaimLoading}>
              {reclaimLoading ? <ButtonSpinner /> : 'Re Claim'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal >
  );
}
