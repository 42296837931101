import React, { useEffect, useContext, useState } from "react";
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../Context/UserContext';
import { Auth } from 'aws-amplify';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userStatus } = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const getAuthenticatedUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    getAuthenticatedUser();
  }, []);

  if (isAuthenticated === null) {
    return null;
  }

  return (
    <>
      <Route {...rest} render={(props) =>
        isAuthenticated && userStatus === 'Active' ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect to={{ pathname: "/signin" }} />
        )
      }
      />
    </>
  );
};

export default PrivateRoute;