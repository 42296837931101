import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import BuyerAmountOffer from './components/BuyerAmountOffer';
import SingleSignOn from './components/single-sign-on';
import Details from './pages/details/Details';
import Home from './pages/Home';
import ReceivedOffers from './components/ReceivedOffers';
import placedOffers from './components/PlacedOffers';
import purchaseComplete from './components/PurchaseComplete';
import RequestedVehiList from './components/RequestedVehiList';
import SoldVehicles from './components/SoldVehicles';
import BoughtVehicles from './components/BoughtVehicles';
import { EmailContext, jwtToken } from './Context/DataContext';
import Pricing from './components/Pricing';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import Claim from './pages/claim/Claim';
import FAQs from './pages/FAQs';
import { Amplify, Auth } from 'aws-amplify';
import Photoupload from './pages/claim/Steptwo';
import Chat from './pages/chat/chatScreen';
import AdvancedSearch from './pages/AdvancedSearch';
import { GlobalProvider } from './Context/GlobalContext';
import { ChatProvider } from './Context/ChatContext';
import { UserProvider } from './Context/UserContext';
import { VehicleDataProvider } from './Context/VehicleDataContext';
import Account from './pages/Account';
import Profile from './pages/Profile';
import { MetadataProvider } from './Context/MetadataContext';
import Footer from "./components/Footer";
import PrivateRoute from './components/PrivateRoute';
import { SearchProvider } from './Context/SearchContext';
import awsConfig from './aws-config';
import { ChatBotProvider } from './Context/ChatBotContext';

Amplify.configure(awsConfig);

function App() {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  // compentDidMount() function
  useEffect(() => {
    const getUserAttributes = async () => {
      try {
        const session = await Auth.currentSession();
        if (session) {
          const user = await Auth.currentAuthenticatedUser();
          setEmail(user.signInUserSession.idToken.payload.email);
          setToken(user.signInUserSession.idToken.jwtToken);
        }
      } catch (error) {
        console.log('Error On NavBar', error.message);
      }
    };
    getUserAttributes();
  }, []);

  return (
    <>
      <Router>
        {/* Other Routes*/}
        <div className="app-wrapper">
          <div className="content">
            <Switch>
              <MetadataProvider>
                <SearchProvider>
                  <jwtToken.Provider value={{ token, setToken }}>
                    <EmailContext.Provider value={{ email, setEmail }}>
                      <VehicleDataProvider>
                        <UserProvider>
                          <GlobalProvider>
                            <ChatProvider>
                              <ChatBotProvider>
                                <Navbar />
                                <Route path="/signin" component={SingleSignOn} />
                                <Route path="/" exact component={Home} />
                                <Route path="/details" component={Details} />
                                <PrivateRoute exact path="/purchase" component={purchaseComplete} />
                                <PrivateRoute exact path="/claim/:listStatus" component={Claim} />
                                <PrivateRoute exact path="/photoupload" component={Photoupload} />
                                <PrivateRoute exact path="/BuyerAmountOffer" component={BuyerAmountOffer} />
                                <PrivateRoute exact path="/receivedOffers" component={ReceivedOffers} />
                                <PrivateRoute exact path="/requestedVehiList" component={RequestedVehiList} />
                                <PrivateRoute exact path="/placedOffers" component={placedOffers} />
                                <PrivateRoute exact path="/soldVehicles" component={SoldVehicles} />
                                <PrivateRoute exact path="/boughtVehicles" component={BoughtVehicles} />
                                <PrivateRoute exact path="/vehiclePasses" component={Pricing} />
                                <Route path="/privacyPolicy" component={PrivacyPolicy} />
                                <Route path="/termsConditions" component={TermsConditions} />
                                <Route path="/FAQs" component={FAQs} />
                                <PrivateRoute exact path="/message" component={Chat} />
                                <Route path="/advancedSearch" component={AdvancedSearch} />
                                <PrivateRoute exact path="/account" component={Account} />
                                <PrivateRoute exact path="/profile" component={Profile} />
                              </ChatBotProvider>
                            </ChatProvider>
                          </GlobalProvider>
                        </UserProvider>
                      </VehicleDataProvider>
                    </EmailContext.Provider>
                  </jwtToken.Provider>
                </SearchProvider>
              </MetadataProvider>
            </Switch >
          </div>
        </div>
        <Footer />
      </Router >
    </>
  );
}

export default App;
