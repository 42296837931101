import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../assets/CSS/Navbar.css';
import { jwtToken } from '../Context/DataContext';
import { Auth } from 'aws-amplify';
import logo from '../assets/waka-logo/waka-logo-dark.png';
import profile_icon from '../assets/img/profile.png';
import { clearDetailsLocalStorage } from './../utils/LocalStorageUtils';
import { ChatContext } from '../Context/ChatContext';
import ChatNotificationModal from './ChatNotificationModal';

function Navbar() {
  const { token } = useContext(jwtToken);
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const location = useLocation();
  var duration = 300; //for 5 minutes

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    let interval = setInterval(updateTimer, 1000);
    window.addEventListener('mousemove', resetTimer);

    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', resetTimer);
    };
  }, []);

  function resetTimer() {
    duration = 300;
  }

  function updateTimer() {
    duration--;
    if (duration < 1) {
      signOut();
    }
  }

  async function signOut() {
    try {
      clearDetailsLocalStorage(); //Clear the current user details before signOut
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
        <div className='row'>
          <div className="navbar-container"><div className="menu-icon my-auto" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img className="logo-img" src={logo} alt={'logo'} />
            </Link>

            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className="nav-item">
                <Link to="/" className={location.pathname === '/' ? 'nav-links-active' : 'nav-links'} onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>
              {token && (
                <li className="nav-item">
                  <Link to="/placedOffers" className={location.pathname === '/placedOffers' ? 'nav-links-active' : 'nav-links'} onClick={closeMobileMenu}>
                    Placed Offers
                  </Link>
                </li>
              )}
              {token && (
                <li className="nav-item">
                  <Link to="/receivedOffers" className={location.pathname === '/receivedOffers' ? 'nav-links-active' : 'nav-links'} onClick={closeMobileMenu}>
                    Received Offers
                  </Link>
                </li>
              )}
              {token && (
                <li className="nav-item">
                  <Link to="/requestedVehiList" className={location.pathname === '/requestedVehiList' ? 'nav-links-active' : 'nav-links'} onClick={closeMobileMenu}>
                    Listing Requests
                  </Link>
                </li>
              )}
              {token && (
                <li className="nav-item">
                  <Link to="/soldVehicles" className={location.pathname === '/soldVehicles' ? 'nav-links-active' : 'nav-links'} onClick={closeMobileMenu}>
                    Sold
                  </Link>
                </li>
              )}
              {token && (
                <li className="nav-item">
                  <Link to="/boughtVehicles" className={location.pathname === '/boughtVehicles' ? 'nav-links-active' : 'nav-links'} onClick={closeMobileMenu}>
                    Bought
                  </Link>
                </li>
              )}
              {!token && (
                <li>
                  <Link to="/signin" className="nav-links-mobile" onClick={closeMobileMenu}>
                    Sign In
                  </Link>
                </li>
              )}
              {token && (
                <li>
                  <Link
                    to="/signin"
                    className="nav-links-mobile"
                    onClick={function () {
                      closeMobileMenu();
                      signOut();
                    }}
                  >
                    Sign Out
                  </Link>
                </li>
              )}
            </ul>
            {token && (
              <ul className="user-profile" style={{ listStyle: 'none', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                <ChatNotificationModal />
                <li className="nav-item dropdown nav-user" >
                  <a
                    className="nav-link nav-user-img"
                    href="#"
                    id="navbarDropdownMenuLink2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      className="user-avatar-md rounded-circle dropdown-btn mt-3"
                      src={profile_icon}
                      alt="profile-icon"
                    />
                  </a>
                  <div className="dropdown" aria-labelledby="navbarDropdownMenuLink2">
                    <div className="dropdown-content">
                      <Link className="dropdown-item" to="/profile">
                        Profile
                      </Link>
                      <Link className="dropdown-item" to="/account">
                        Account
                      </Link>
                      <Link className="dropdown-item" to="/message">
                        Messages
                      </Link>
                      <Link to="/signin" onClick={signOut} className="dropdown-item">
                        SIGN OUT
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            {!token && (
              <ul style={{ listStyle: 'none' }}>
                <li className="nav-item mob_resp">
                  <Link to="/signin" className={location.pathname === '/signin' ? 'nav-links-active ' : 'nav-links'} onClick={closeMobileMenu}>
                    SIGN IN
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
