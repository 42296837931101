import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { ButtonSpinner } from './Spinner';
import '../assets/CSS/BuyerAmountOffer.css';
import { jwtToken } from '../Context/DataContext';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../Context/UserContext';
import { VehicleDataContext } from '../Context/VehicleDataContext';
import swal from 'sweetalert';

export default function BuyerAmountOffer() {
  const { claimObj, plateNumber, VIN } = useContext(VehicleDataContext);
  const { userID } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const history = useHistory();
  const [amount, setAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [offerLoading, setOfferLoading] = useState(false);
  const claimID = claimObj.ClaimID;

  const OfferingAmount = async (e) => {
    //validation for offer amount
    const priceReg = /^\d*(\.\d{2})?$/;
    if (amount == null) {
      setErrorMessage('Offer Amount Required');
      e.preventDefault();
    } else if (!amount) {
      setErrorMessage('Offer Amount Required');
      e.preventDefault();
    } else if (!priceReg.test(amount)) {
      setErrorMessage('Offer Amount invalid!');
    } else {
      setErrorMessage('');
      setOfferLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}placeoffer`,
          { claimID, buyerID: userID, amount }, { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          swal({
            text: 'Amount offered successfully!',
            icon: 'success',
            dangerMode: true,
          }).then(() => {
            history.push('/placedOffers');
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 429) {
          swal({
            text: 'Exceeded daily offer count on this vehicle!',
            icon: 'warning',
            dangerMode: true,
          }).then(() => {
            history.push(`/details`);
          });
        } else {
          swal({
            text: 'Unsuccessful. Please try again!!',
            icon: 'error',
            dangerMode: true,
          }).then(() => {
            history.push(`/details`);
          });
        }
      } finally {
        setOfferLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="container">
        <h2 style={{ color: 'black' }} className="text-center">
          Offer Amount
        </h2>
        &nbsp;
        <div className="form-group mt-2">
          <dl className="row">
            <dt className="col-sm-3">Plate Number/VIN:</dt>
            <dd className="col-sm-3">{plateNumber || VIN}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-3">Offer Amount (NZ$) </dt>
            <dd className="col-sm-4">
              <input type="text" className="form-control" placeholder="Offer Amount" onChange={(e) => setAmount(e.target.value)} value={amount} />{' '}
              <p style={{ color: 'red' }}>{errorMessage}</p>
            </dd>
          </dl>
          {'You can place an offer on this vehicle once per day.'}
        </div>
        <br></br>
        <div align="center">
          <Button
            onClick={OfferingAmount}
            variant="danger"
            size="lg"
            disabled={offerLoading}
          >
            {offerLoading ? <ButtonSpinner /> : 'Offer Amount'}
          </Button>
        </div>
      </div>
    </div>
  );
}
