import React from 'react';
import '../../assets/CSS/DetailField.css';
import { UTCdateToFormattedDate } from '../../utils/DateAndTimeUtils';

export default function InspectionDetails({ vehicleDetails }) {
  return (
    <>
      {vehicleDetails?.SubjectToCOF === "true" ? (
        <div className="text-cell">
          <div className="part1">Subject To COF</div>
          <div className="part2">Yes</div>
        </div>
      ) : vehicleDetails?.SubjectToWOF === "true" ? (
        <div className="text-cell">
          <div className="part1">Subject To WOF</div>
          <div className="part2">Yes</div>
        </div>
      ) : (
        <>
          <div className="text-cell">
            <div className="part1">Subject To COF</div>
            <div className="part2">{vehicleDetails?.SubjectToCOF ? vehicleDetails?.SubjectToCOF === "false" ? "No" : "Yes" : 'N/A'}</div>
          </div>
          <div className="text-cell">
            <div className="part1">Subject To WOF</div>
            <div className="part2">{vehicleDetails?.SubjectToWOF ? vehicleDetails?.SubjectToWOF === "false" ? "No" : "Yes" : 'N/A'}</div>
          </div>
        </>
      )}
      <div className="text-cell">
        <div className="part1">Subject To RUC</div>
        <div className="part2">{vehicleDetails?.SubjectToRUC ? vehicleDetails?.SubjectToRUC === "false" ? "No" : "Yes" : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Last Inspection</div>
        <div className="part2">{vehicleDetails?.Inspections?.Date ? UTCdateToFormattedDate(vehicleDetails?.Inspections.Date) : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Expiry Date</div>
        <div className="part2">{vehicleDetails?.Inspections?.ExpiryDate ? UTCdateToFormattedDate(vehicleDetails?.Inspections.ExpiryDate) : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Inspection Result</div>
        <div className="part2">{vehicleDetails?.Inspections?.Description || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">IsLatest</div>
        <div className="part2">{vehicleDetails?.Inspections?.IsLatest ? vehicleDetails?.Inspections?.IsLatest === "false" ? "No" : "Yes" : 'N/A'}</div>
      </div>
      {vehicleDetails?.SubjectToCOF !== "true" && vehicleDetails?.SubjectToWOF !== "true" && (
        <div className="text-cell">
          <div className="part1">Type</div>
          <div className="part2">{vehicleDetails?.Inspections?.Type || 'N/A'}</div>
        </div>)}
    </>
  );
}
