import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { jwtToken } from './DataContext';
import { UserContext } from './UserContext';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const [chatsAsSellerContext, setChatsAsSellerContext] = useState([]);
  const [chatsAsDealerSellerContext, setChatsAsDealerSellerContext] = useState([]);
  const [chatsAsBuyerContext, setChatsAsBuyerContext] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [claimID, setClaimID] = useState('');
  const [plateNumber, setPlateNumber] = useState('');
  const [buyerID, setBuyerID] = useState('');
  const [userRole, setUserRole] = useState('');
  const [isMessagePath, setIsMessagePath] = useState(false);
  const [VIN, setVIN] = useState('');
  const [chatModalShown, setChatModalShown] = useState(localStorage.getItem('chatModalShown') ? localStorage.getItem('chatModalShown') : 'false');

  useEffect(() => {
    localStorage.setItem('chatModalShown', chatModalShown);
  }, [chatModalShown]);

  // Add useEffect to fetch data
  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_COMMON}getMessages`, { userID },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          const sellerChatsWithUnreadMessages = [];
          const dealerSellerChatsWithUnreadMessages = [];
          const buyerChatsWithUnreadMessages = [];
          response.data.forEach((chats) => {
            if (chats.hasOwnProperty('S')) {
              chats.S.forEach((sElement) => {
                // Check if sElement has hasUnreadMessage === true
                if (sElement.hasOwnProperty('chats') && Array.isArray(sElement.chats)) {
                  sElement.chats.forEach((chat, index) => {
                    if (chat.hasUnreadMessage) {
                      sellerChatsWithUnreadMessages.push({
                        plateNo: sElement.plateNo,
                        VIN: sElement.VIN,
                        chats: [chat],
                      });
                      setChatsAsSellerContext(sellerChatsWithUnreadMessages);
                    }
                  });
                }
              });
            } else if (chats.hasOwnProperty('DS')) {
              chats['DS'].forEach((sElement) => {
                // Check if sElement has hasUnreadMessage === true
                if (sElement.hasOwnProperty('chats') && Array.isArray(sElement.chats)) {
                  sElement.chats.forEach((chat, index) => {
                    if (chat.hasUnreadMessage) {
                      dealerSellerChatsWithUnreadMessages.push({
                        plateNo: sElement.plateNo,
                        VIN: sElement.VIN,
                        chats: [chat],
                      });
                      setChatsAsDealerSellerContext(dealerSellerChatsWithUnreadMessages);
                    }
                  });
                }
              });
            } else if (chats.hasOwnProperty('B')) {
              chats.B.forEach((bElement) => {
                // Check if bElement has hasUnreadMessage === true
                if (bElement.hasOwnProperty('hasUnreadMessage') && bElement.hasUnreadMessage === true) {
                  buyerChatsWithUnreadMessages.push(bElement);
                  setChatsAsBuyerContext(buyerChatsWithUnreadMessages);
                }
              });
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (token && userID) {
      fetchChats();
    }
  }, [token, userID]);

  useEffect(() => {
    let notificationCount = 0;
    chatsAsSellerContext.forEach((chats, index) => {
      notificationCount += chats.chats.length;
    });
    chatsAsDealerSellerContext.forEach((chats, index) => {
      notificationCount += chats.chats.length;
    });
    notificationCount += chatsAsBuyerContext.length;
    setNotificationCount(notificationCount);
  }, [chatsAsBuyerContext, chatsAsSellerContext, chatsAsDealerSellerContext, notificationCount]);

  return (
    <ChatContext.Provider value={{ notificationCount, setNotificationCount, chatsAsSellerContext, chatsAsBuyerContext, chatsAsDealerSellerContext, setChatsAsDealerSellerContext, setChatsAsSellerContext, setChatsAsBuyerContext, chatModalShown, setChatModalShown, claimID, setClaimID, plateNumber, setPlateNumber, buyerID, setBuyerID, userRole, setUserRole, VIN, setVIN, isMessagePath, setIsMessagePath }}>
      {children}
    </ChatContext.Provider>
  );
};