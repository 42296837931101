import React, { useState, useEffect, useContext, useCallback } from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../assets/CSS/PlacedOffers.css';
import axios from 'axios';
import { jwtToken } from '../Context/DataContext';
import { Spinner } from './Spinner';
import { UserContext } from '../Context/UserContext';
import { TimestampToDate } from '../utils/DateAndTimeUtils';
import swal from 'sweetalert';
import '../assets/CSS/SoldBought.css';
import { Accordion } from 'react-bootstrap';

export default function Offers() {
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [personalVehicles, setPersonalVehicles] = useState([]);
  const [dealerVehicles, setDealerVehicles] = useState([]);


  const fetchSoldVehicles = useCallback(async () => {
    if (token && userID) {
      try {
        setLoaded(true);
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}soldvehicles`,
          { userID }, { headers: { Authorization: `Bearer ${token}` } }
        );
        const { personalSoldVehicles, dealerSoldVehicles } = response.data;
        setData(response.data);
        console.log(response.data);
        setPersonalVehicles(personalSoldVehicles);
        setDealerVehicles(dealerSoldVehicles);

      } catch (error) {
        swal({
          text: 'Failed to retrieve sold vehicles!',
          icon: 'error',
          dangerMode: true,
        });
      } finally {
        setLoaded(false);
      }
    }
  }, [token, userID]);

  useEffect(() => {
    fetchSoldVehicles();
  }, [fetchSoldVehicles]);

  const isSoldVehiclesEmpty = [
    personalVehicles,
    dealerVehicles
  ].every(arr => arr.length === 0);

  return (
    <>
      {loaded ? (<Spinner></Spinner>) : (
        <div className="App p-spacing">
          <div className="container m-spacing">
            <>
              {isSoldVehiclesEmpty ? (<div className="text-center mt-5 pt-5 pb-5 mb-5">No Vehicles</div>) : (
                <>
                  {/* Form Section*/}
                  <div className="offer-details">
                    <div className="form-group mb-2">
                      <div className="row mb-5">
                        <div className="col mb-2">
                          <input
                            type="text"
                            placeholder="Search from plate number/VIN"
                            className="form-control"
                            onChange={(e) => {
                              setSearchData(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-3">
                          <button className="btn btn-danger" id="search-button" type="button" style={{ height: '90%' }}>
                            {' '}
                            Search &nbsp;
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                      {/* Table Section */}
                      <div>
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                          {personalVehicles && personalVehicles.length > 0 && (
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>Personal sold vehicles</Accordion.Header>
                              <Accordion.Body>
                                <table className="table align-middle mb-0 mt-2 bg-white" style={{ margin: 'auto' }}>
                                  <thead className="bg-light">
                                    <tr>
                                      <th>Plate Number/VIN</th>
                                      <th>Purchased Price</th>
                                      <th>Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {personalVehicles
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">${item.Amount}</p>
                                          </td>
                                          <td>
                                            <span>{TimestampToDate(item.CreatedAt)}</span>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                        <br />
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                          {dealerVehicles && dealerVehicles.length > 0 && (
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>Dealer sold vehicles</Accordion.Header>
                              <Accordion.Body>
                                <table className="table align-middle mb-0 mt-2 bg-white" style={{ margin: 'auto' }}>
                                  <thead className="bg-light">
                                    <tr>
                                      <th>Plate Number/VIN</th>
                                      <th>Purchased Price</th>
                                      <th>Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dealerVehicles
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">${item.Amount}</p>
                                          </td>
                                          <td>
                                            <span>{TimestampToDate(item.CreatedAt)}</span>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
}
