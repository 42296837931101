import { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {});
  const [userID, setUserID] = useState(localStorage.getItem('userID') ? localStorage.getItem('userID') : '');
  const [userStatus, setUserStatus] = useState(localStorage.getItem('userStatus') ? localStorage.getItem('userStatus') : '');
  const [nicknameModalShown, setNicknameModalShown] = useState(localStorage.getItem('nicknameModalShown') ? localStorage.getItem('nicknameModalShown') : 'false');
  const [recaptchaToken, setRecaptchaToken] = useState('');

  // When the user ID changes, update it in local storage
  useEffect(() => {
    localStorage.setItem('userID', userID);
  }, [userID]);

  useEffect(() => {
    localStorage.setItem('userStatus', userStatus);
  }, [userStatus]);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    localStorage.setItem('nicknameModalShown', nicknameModalShown);
  }, [nicknameModalShown]);

  // Render the provider component with the user ID value and setter function in the context
  return (
    <UserContext.Provider value={{ userID, setUserID, userStatus, setUserStatus, recaptchaToken, setRecaptchaToken, user, setUser, nicknameModalShown, setNicknameModalShown }}>
      {props.children}
    </UserContext.Provider>
  );
};
