import React from 'react';
import '../../assets/CSS/DetailField.css';
import { UTCdateToFormattedDate } from '../../utils/DateAndTimeUtils';

export default function LisenceDetails({ vehicleDetails }) {
  return (
    <>
      <div className="text-cell">
        <div className="part1">Continuous License:</div>
        <div className="part2">{vehicleDetails?.Registrations?.IsContinous ? vehicleDetails.Registrations?.IsContinous === "false" ? "No" : "Yes" : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">License Number:</div>
        <div className="part2">{vehicleDetails?.Registrations?.Number || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">License Issue Date:</div>
        <div className="part2">{vehicleDetails?.Registrations?.IssueDateTime ? UTCdateToFormattedDate(vehicleDetails.Registrations.IssueDateTime) : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Expiry DateTime:</div>
        <div className="part2">{vehicleDetails?.Registrations?.ExpiryDateTime ? UTCdateToFormattedDate(vehicleDetails.Registrations.ExpiryDateTime) : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">License Type:</div>
        <div className="part2">{vehicleDetails?.Registrations?.LicenseType || 'N/A'}</div>
      </div>
    </>
  );
}
