import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { jwtToken } from '../Context/DataContext';
import swal from 'sweetalert';
import { Button } from 'react-bootstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { ButtonSpinner } from './Spinner';
import '../assets/CSS/BuyerAmountOffer.css';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../Context/UserContext';
import { VehicleDataContext } from '../Context/VehicleDataContext';
import '../assets/CSS/OfferAmountModel.css';

export default function OfferAmountModel({ show, setShowModalOffer, offerAmount }) {
  const { claimObj, plateNumber, VIN } = useContext(VehicleDataContext);
  const { userID } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const history = useHistory();
  const [amount, setAmount] = useState(offerAmount);
  const [errorMessage, setErrorMessage] = useState();
  const [offerLoading, setOfferLoading] = useState(false);
  const claimID = claimObj.ClaimID;
  var priceReg = /^(?=.*[1-9])\d*(\.\d{1,2})?$/;
  const OfferingAmount = async (e) => {
    //validation for offer amount
    if (!amount || !priceReg.test(amount)) {
      setErrorMessage('The offer amount should be greater than 0 and can have up to two decimal places.');
    } else {
      setErrorMessage('');
      setOfferLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}placeoffer`,
          { claimID, buyerID: userID, amount }, { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          swal({
            text: 'Amount offered successfully!',
            icon: 'success',
            dangerMode: true,
          }).then(() => {
            history.push('/placedOffers');
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 429) {
          swal({
            text: 'Exceeded daily offer count on this vehicle!',
            icon: 'warning',
            dangerMode: true,
          }).then(() => {
            setShowModalOffer(false);
            history.push(`/details`);
          });
        } else {
          swal({
            text: 'Unsuccessful. Please try again!!',
            icon: 'error',
            dangerMode: true,
          }).then(() => {
            history.push(`/details`);
          });
        }
      } finally {
        setOfferLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setAmount(offerAmount);
          setErrorMessage('');
          setShowModalOffer(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Offer amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mt-2">
            <dl className="row">
              <dt className="col-sm-3">Plate Number/VIN:</dt>
              <dd className="col-sm-3">{plateNumber || VIN}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Offer Amount (NZ$) </dt>
              <dd className="col-sm-4">
                <input type="text" min="0" className="form-control" placeholder="Offer Amount" onChange={(e) => {
                  if (priceReg.test(e.target.value.replace(/[^\d.]/g, ''))) {
                    setErrorMessage('');
                  }
                  setAmount(e.target.value.replace(/[^\d.]/g, ''));
                }} value={amount} />{' '}
                <p style={{ color: 'red' }}>{errorMessage}</p>
              </dd>
            </dl>
            {'You can place an offer on this vehicle once per day.'}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="offers d-flex justify-content-center">
            <Button
              onClick={OfferingAmount}
              variant="danger"
              size="lg"
              disabled={offerLoading}
            >
              {offerLoading ? <ButtonSpinner /> : 'Offer Amount'}
            </Button>
          </div>
        </Modal.Footer>

      </Modal >
    </>
  );
}
