import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const MetadataContext = React.createContext();

export const MetadataProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);
  const [bodyStyles, setBodyStyles] = useState([]);
  const [activeDealers, setActiveDealers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const locationsResponse = await axios.get(`${process.env.REACT_APP_SEARCH_API}getlocation`);
        setLocations(locationsResponse.data.locations);

        const bodystylesResponse = await axios.get(`${process.env.REACT_APP_SEARCH_API}getbodystyles`);
        const bodyStyles = Array.from(new Set(bodystylesResponse.data.bodystyleCombinations.map((item) => item.description)));
        setBodyStyles(bodyStyles);

        const activeDealersResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}get-dealer`);
        setActiveDealers(activeDealersResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <MetadataContext.Provider
      value={{
        locations, setLocations, bodyStyles, setBodyStyles, activeDealers, setActiveDealers
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
};

export default MetadataProvider;
