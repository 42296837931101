import React from 'react';
import '../../assets/CSS/DetailField.css';
import { UTCdateToFormattedDate } from '../../utils/DateAndTimeUtils';

export default function VehicleUsageDetails({ vehicleDetails }) {
  return (
    <>
      <div className="text-cell">
        <div className="part1">{vehicleDetails?.EffectiveDate ? UTCdateToFormattedDate(vehicleDetails.EffectiveDate) : 'N/A'}</div>
        <div className="part2">{vehicleDetails?.VehicleUsageCode || 'N/A'}</div>
      </div>
    </>
  );
}
