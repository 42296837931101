import React, { useState, useContext } from 'react';
import Button from '../claim/button';
import { useHistory } from 'react-router-dom';
import { ButtonSpinner } from '../../components/Spinner';
import 'cropperjs/dist/cropper.css';
import { VehicleDataContext } from '../../Context/VehicleDataContext';
import { FiUpload } from 'react-icons/fi';
import MultipleImageUpload from '../../components/MultipleImageUpload';
import '../CSS/Steps.css';

export default function Steptwo() {
  const history = useHistory();
  const { claimObj } = useContext(VehicleDataContext);
  const [imgUploadLoading, setImgUploadLoading] = useState(false);
  const [imgUploadModel, setImgUploadModel] = useState(false);

  return (
    <div className="container two">
      <h5 className="claim-topic-color py-5">Upload Vehicle Image</h5>
      {imgUploadLoading ? (
        <ButtonSpinner />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <FiUpload className='no-img-btn' onClick={() => setImgUploadModel(true)} />
        </div>
      )}
      <Button className="right-btn"
        onClick={() => history.replace('/claim/Not%20Listed?activeStep=3')}
        disabled={imgUploadLoading}
      >
        Next
      </Button>

      <MultipleImageUpload
        imgUploadModel={imgUploadModel}
        setImgUploadModel={setImgUploadModel}
        claimID={claimObj.ClaimID}
        fetchVehicleImages={null}
        images={claimObj.Images || []}
        setIsLoading={setImgUploadLoading}
      />
    </div>
  );
}