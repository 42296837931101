import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function TopFeatures({ vehicleDetails, odometer }) {

  const dataCellStyle = { backgroundColor: '#800000', width: '50vw' }; // Background color for dessert cell
  const contentCellStyle = { width: '50vw' };

  return (
    <>
      <TableContainer component={Paper} style={{ width: '100%' }}>
        <Table sx={{ minWidth: 100 }} aria-label="simple table">
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell style={{ ...dataCellStyle, color: 'white' }} component="th" scope="row">Odometer Reading</TableCell>
              <TableCell style={{ ...contentCellStyle }} align="right">{odometer?.Reading ? odometer.Reading + ' Km' : 'N/A'}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell style={{ ...dataCellStyle, color: 'white' }} component="th" scope="row">Year</TableCell>
              <TableCell style={{ ...contentCellStyle }} align="right">{vehicleDetails?.YearOfManufacture || 'N/A'}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell style={{ ...dataCellStyle, color: 'white' }} component="th" scope="row">Fuel Type</TableCell>
              <TableCell style={{ ...contentCellStyle }} align="right">{vehicleDetails?.FuelType || 'N/A'}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell style={{ ...dataCellStyle, color: 'white' }} component="th" scope="row">Color</TableCell>
              <TableCell style={{ ...contentCellStyle }} align="right">{vehicleDetails?.BasicColour || 'N/A'}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell style={{ ...dataCellStyle, color: 'white' }} component="th" scope="row">Seats</TableCell>
              <TableCell style={{ ...contentCellStyle }} align="right">{vehicleDetails?.NumberOfSeats || 'N/A'}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell style={{ ...dataCellStyle, color: 'white' }} component="th" scope="row">Engine Capacity</TableCell>
              <TableCell style={{ ...contentCellStyle }} align="right">{vehicleDetails?.CCRating ? vehicleDetails?.CCRating + 'cc' : 'N/A'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
