import React from 'react';
import '../../assets/CSS/DetailField.css';
import { UTCdateToFormattedDate } from '../../utils/DateAndTimeUtils';

export default function RegistrationDetails({ vehicleDetails }) {
  return (
    <>
      <div className="text-cell">
        <div className="part1">Vehicle Registration Status</div>
        <div className="part2">{vehicleDetails?.VehicleRegistrationStatus || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Date Of First NZ Registration</div>
        <div className="part2">{vehicleDetails?.DateOfFirstNZRegistration ? UTCdateToFormattedDate(vehicleDetails.DateOfFirstNZRegistration) : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Registration Plate Number</div>
        <div className="part2">{vehicleDetails?.PlateNumber || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Plate Status</div>
        <div className="part2">{vehicleDetails?.Status || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Plate Type</div>
        <div className="part2">{vehicleDetails?.PlateType || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Plate Effective Date</div>
        <div className="part2">{vehicleDetails?.EffectiveDate ? UTCdateToFormattedDate(vehicleDetails.EffectiveDate) : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Previous Country Of Registration</div>
        <div className="part2">{vehicleDetails?.PreviousCountryOfRegistration || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Is Imported Damaged</div>
        <div className="part2">{vehicleDetails?.IsImportedDamaged ? vehicleDetails?.IsImportedDamaged === "false" ? "No" : "Yes" : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Is Registered Overseas</div>
        <div className="part2">{vehicleDetails?.IsRegisteredOverseas ? vehicleDetails?.IsRegisteredOverseas === "false" ? "No" : "Yes" : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Cause Of Latest Registration</div>
        <div className="part2">{vehicleDetails?.CauseOfLatestRegistration || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Country Of Origin</div>
        <div className="part2">{vehicleDetails?.CountryOfOrigin || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Date Of Last Registration</div>
        <div className="part2">{vehicleDetails?.DateOfLastRegistration ? UTCdateToFormattedDate(vehicleDetails.DateOfLastRegistration) : 'N/A'}</div>
      </div>
    </>
  );
}
