function clearDetailsLocalStorage() {
  localStorage.removeItem('claims');
  localStorage.removeItem('userID');
  localStorage.removeItem('userStatus');
  localStorage.removeItem('vehiData');
  localStorage.removeItem('claimObj');
  localStorage.removeItem('offer');
  localStorage.removeItem('plateNumber');
  localStorage.removeItem('VIN');
  localStorage.removeItem('nicknameModalShown');
  localStorage.removeItem('user');
  localStorage.removeItem('expiredClaimModalShown');
  localStorage.removeItem('selectedMake');
  localStorage.removeItem('selectedModel');
  localStorage.removeItem('selectedYearRangeStart');
  localStorage.removeItem('selectedYearRangeEnd');
  localStorage.removeItem('selectedFuelType');
  localStorage.removeItem('selectedBodyStyle');
  localStorage.removeItem('selectedLocation');
  localStorage.removeItem('selectedStatus');
  localStorage.removeItem('selectedOdometerRange');
  localStorage.removeItem('selectedPriceRange');
  localStorage.removeItem('selectedDealerCompany');
  localStorage.removeItem('searchResults');
  localStorage.removeItem('chatModalShown');
  localStorage.removeItem('previousLocation');
  localStorage.removeItem('chatMessages');
}

export { clearDetailsLocalStorage };
