import React from 'react';
import '../../assets/CSS/DetailField.css';
import { UTCdateToFormattedDate } from '../../utils/DateAndTimeUtils';

export default function VehicleHistory({ vehicleDetails, odometer }) {
  return (
    <>
      <div className="text-cell">
        <div className="part1">Reliable Odometer</div>
        <div className="part2">{vehicleDetails?.ReliableOdometer ? vehicleDetails?.ReliableOdometer === "false" ? "No" : "Yes" : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Reading</div>
        <div className="part2">{odometer?.Reading || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Reading Date</div>
        <div className="part2">{odometer?.ReadingDate ? UTCdateToFormattedDate(odometer.ReadingDate) : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Unit</div>
        <div className="part2">{odometer?.Unit || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Source</div>
        <div className="part2">{odometer?.Source || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Plate Effective Date</div>
        <div className="part2">{vehicleDetails?.EffectiveDate ? UTCdateToFormattedDate(vehicleDetails.EffectiveDate) : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Previous Country Of Registration</div>
        <div className="part2"> {vehicleDetails?.PreviousCountryOfRegistration || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Is Registered Overseas</div>
        <div className="part2">{vehicleDetails?.IsRegisteredOverseas ? vehicleDetails?.IsRegisteredOverseas === "false" ? "No" : "Yes" : 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Cause Of Latest Registration</div>
        <div className="part2">{vehicleDetails?.CauseOfLatestRegistration || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Country Of Origin</div>
        <div className="part2">{vehicleDetails?.CountryOfOrigin || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Date Of Last Registration</div>
        <div className="part2">{vehicleDetails?.DateOfLastRegistration ? UTCdateToFormattedDate(vehicleDetails.DateOfLastRegistration) : 'N/A'}</div>
      </div>
    </>
  );
}
