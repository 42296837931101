import styled from 'styled-components';

const Button = styled.button`
  background-color: #c9302c;
  padding: 8px 40px;
  border: 1px solid #c9302c;
  color: #fff;
  margin: 2px 5px;
  cursor: pointer;
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 7px;

  &:disabled {
    background-color: #ccc;
    border-color: #ccc;
    cursor: not-allowed;
  }
`;

export default Button;
