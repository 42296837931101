import React from 'react';
import '../../assets/CSS/DetailField.css';

export default function VehicleDetails({ vehicleDetails }) {
  return (
    <>
      <div className="text-cell">
        <div className="part1">Year Of Manufacture</div>
        <div className="part2">{vehicleDetails?.YearOfManufacture || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Make</div>
        <div className="part2">{vehicleDetails?.Make || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Model</div>
        <div className="part2">{vehicleDetails?.Model || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">VIN</div>
        <div className="part2 vin">{vehicleDetails?.VIN || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Chassis Number</div>
        <div className="part2">{vehicleDetails?.ChassisNumber || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Body Style</div>
        <div className="part2">{vehicleDetails?.BodyStyle || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Vehicle Type</div>
        <div className="part2">{vehicleDetails?.VehicleType || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Body Basic Colour</div>
        <div className="part2">{vehicleDetails?.BasicColour || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Assembly Type</div>
        <div className="part2 assembly">{vehicleDetails?.AssemblyType || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Number Of Seats</div>
        <div className="part2">{vehicleDetails?.NumberOfSeats || 'N/A'}</div>
      </div>
      {vehicleDetails?.BanReason && (
        <div className="text-cell">
          <div className="part1">Ban Reason</div>
          <div className="part2">{vehicleDetails?.BanReason}</div>
        </div>
      )}
    </>
  );
}
