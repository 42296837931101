import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MultiStepForm, Step } from 'react-multi-form';
import StepOne from './Stepone';
import StepTwo from './Steptwo';
import StepThree from './Stepthree';
import StepFour from './Stepfour';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  max-width: 700px;
  margin: 40px auto;
  padding-left:20px;
  padding-right:20px;
  @media (max-width: 590px) {
    /* width: 300px; */
    overflowX:"hidden !important";
    width:"100% !important";
  }
`;

const Claim = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeStepFromUrl = searchParams.get('activeStep');
  const [active, setActive] = useState(activeStepFromUrl ? parseInt(activeStepFromUrl) : 1);

  useEffect(() => {
    setActive(activeStepFromUrl ? parseInt(activeStepFromUrl) : 1);
  }, [activeStepFromUrl]);

  return (
    <div className="p-spacing">
      <div className="wrapper my-5" style={{ minHeight: "100%", position: "relative" }}>
        <Container >
          <MultiStepForm activeStep={active}>
            <Step label="Claim">
              <StepOne />
            </Step>
            <Step label="Photo Upload">
              <StepTwo />
            </Step>
            <Step label="Listing Pass">
              <StepThree isActive={active === 3} />
            </Step>
            <Step label="List">
              <StepFour />
            </Step>
          </MultiStepForm>
        </Container>
      </div>
    </div>
  );
};

export default Claim;
