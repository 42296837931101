import React from 'react';
import '../assets/CSS/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <>
      <div className='container-fluid p-spacing'>
        <div className='row m-spacing'>
          <p className="waka-online" style={{ color: "#b91d1d" }}>
            <b><span className="first-letter">W</span>AKA &nbsp; <span className="first-letter">O</span>NLINE &nbsp; <span className="pri_pol" style={{ color: "#444444" }}>PRIVACY&nbsp;POLICY</span></b>
          </p>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }}> INTRODUCTION </h4>
            <p className="pri_para" style={{ color: "black" }}>Waka Online Limited <b>(we, us, our)</b> is the operator of the Waka Online motor vehicle marketplace at wakaonline.nz and wakaonline.co.nz <b>(Waka Online)</b>. </p>
            <p className="pri_para" style={{ color: "black" }}>We comply with the New Zealand Privacy Act 2020 (the Act) when dealing with personal information.  Personal information is information about an identifiable individual (a natural person).</p>
            <p className="pri_para" style={{ color: "black" }}>This policy sets out how we will collect, use, disclose and protect your personal information. </p>
            <p className="pri_para" style={{ color: "black" }}>This policy does not limit or exclude any of your rights under the Act.  If you wish to seek further information on the Act, see <a href="https://www.privacy.org.nz" target="_blank" rel="noopener noreferrer">www.privacy.org.nz.</a></p>
          </div>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }} >CHANGES TO THIS POLICY</h4>
            <p className="pri_para" style={{ color: "black" }}>We may change this policy by uploading a revised policy onto Waka Online.  The change will apply from the date that we upload the revised policy.</p>
          </div>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }} >WHO DO WE COLLECT YOUR PERSONAL INFORMATION FROM</h4>
            <p className="pri_para" style={{ color: "black" }}>We collect personal information about you from:</p>
            <ul className="pri_ul">
              <li className="pri_li">
                you, when you provide that personal information to us, including through any registration or subscription process, through any contact with us (e.g. telephone call or email), or when you use Waka Online and related services.
              </li>
              <li className="pri_li">
                third parties where you have authorised this or the information is publicly available.
              </li>
            </ul>
            <p className="pri_para" style={{ color: "black" }}>If possible, we will collect personal information from you directly.  </p>
            <p className="pri_para" style={{ color: "black" }}>You are under no obligation to provide any such information.  However, if you choose to withhold requested information, we may not be able to provide you with certain services or functionality.</p>
            <p className="pri_para" style={{ color: "black" }}>You can stop the collection of your personal information by closing your account, or by ceasing to use Waka Online NZ. By closing the account, it deletes your personally identifiable information such as email address, unless you are blocked for a breach of terms and conditions.</p>
          </div>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }}>HOW WE USE YOUR PERSONAL INFORMATION</h4>
            <p className="pri_para" style={{ color: "black" }}>We will use your personal information:</p>
            <ul className="pri_ul">
              <li className="pri_li">to verify your identity</li>
              <li className="pri_li">to provide Waka Online and related services to you</li>
              <li className="pri_li">to market Waka Online and related services to you, including contacting you electronically (e.g. by text or email for this purpose) </li>
              <li className="pri_li">to improve Waka Online and related services, including by analysing how you interact with Waka Online and related services </li>
              <li className="pri_li">to verify the ownership of a vehicle</li>
              <li className="pri_li">to enable users of Waka Online and related services to communicate with each other</li>
              <li className="pri_li">to bill you and to collect money that you owe us, including authorising and processing credit card transactions</li>
              <li className="pri_li">to respond to communications from you, including a complaint</li>
              <li className="pri_li">to conduct research and statistical analysis (on an anonymised basis)</li>
              <li className="pri_li">to protect and/or enforce our legal rights and interests, including defending any claim</li>
              <li className="pri_li">for any other purpose authorised by you or the Act.</li>
            </ul>
            <p className="pri_para" style={{ color: "black" }}>You can unsubscribe from any marketing communications from us by following the instructions on any communications sent to you.  You can also exercise this right at any time by contacting us using the details at the end of this privacy policy. </p>
            <p className="pri_para" style={{ color: "black" }}>We use a third party service provider to process credit card transactions.  We do not have access to your credit card information.  The name of this third party provider will generally be displayed when you are requested to enter your credit card information.  You can see further information about how they process your credit card information in their privacy policy. </p>
          </div>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }}>DISCLOSING PERSONAL INFORMATION ABOUT SELLERS</h4>
            <p className="pri_para" style={{ color: "black" }}>If you are a seller on Waka Online, the information that you include in any listing will be disclosed to other users on Waka Online. You should ensure that, when setting up your listing, you do not include any information that you do not wish to be available to other users.  </p>
            <p className="pri_para" style={{ color: "black" }}>If you list a vehicle for sale, users that view your listing will have the opportunity to make an offer to buy your vehicle.  If you accept any such offer through Waka Online, the contact details that you have provided to us will be provided to the buyer so that you can finalise the terms of the sale between you and the buyer.  </p>
          </div>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }}>DISCLOSING PERSONAL INFORMATION ABOUT BUYERS</h4>
            <p className="pri_para" style={{ color: "black" }}>If you are a buyer on Waka Online and you make an offer to buy and the seller accepts that offer to buy, the contact details that you have provided to us will be provided to the seller so that you can finalise the terms of the sale between you and the seller. </p>
          </div>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }}>GENERAL DISCLOSURE OF YOUR PERSONAL INFORMATION</h4>
            <p className="pri_para" style={{ color: "black" }}>We may disclose your personal information to:  </p>
            <ul className="pri_ul">
              <li className="pri_li">another company within our group</li>
              <li className="pri_li">any business that supports our services and products, including any person that hosts or maintains any underlying IT system or data centre that we use to provide Waka Online or other services and products and any third party service provider that processes credit card transactions </li>
              <li className="pri_li">other third parties (for anonymised statistical information)</li>
              <li className="pri_li">a person who can require us to supply your personal information (e.g. a regulatory authority)</li>
              <li className="pri_li">any other person authorised by the Act or another law (e.g. a law enforcement agency) </li>
              <li className="pri_li">any other person authorised by you.</li>
            </ul>
            <p className="pri_para" style={{ color: "black" }}> The information that you include in any comment on a Listing, and in any message that you send via Waka Online, will be disclosed to other users of Waka Online.</p>
            <p className="pri_para" style={{ color: "black" }}>A business that supports our services and products may be located outside New Zealand.  This may mean your personal information is held and processed outside New Zealand.</p>
            <p className="pri_para" style={{ color: "black" }}>We may transfer your information in the case of a sale, merger, consolidation, liquidation, reorganisation or acquisition. </p>
          </div>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }}>PROTECTING YOUR PERSONAL INFORMATION</h4>
            <p className="pri_para" style={{ color: "black" }}>We will take reasonable steps to keep your personal information safe from loss, unauthorised activity, or other misuse.</p>
            <p className="pri_para" style={{ color: "black" }}>You can play an important role in keeping your personal information secure by maintaining the confidentiality of any password and accounts used in relation to our products and services, and by limiting the personal information you include in Listings and messages.  Please notify us immediately if there is any unauthorised use of your account or any other breach of security.</p>
          </div>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }}>ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION</h4>
            <p className="pri_para" style={{ color: "black" }}>Subject to certain grounds for refusal set out in the Act, you have the right to access your readily retrievable personal information that we hold and to request a correction to your personal information.  Before you exercise this right, we will need evidence to confirm that you are the individual to whom the personal information relates.</p>
            <p className="pri_para" style={{ color: "black" }}>In respect of a request for correction, if we think the correction is reasonable and we are reasonably able to change the personal information, we will make the correction.  If we do not make the correction, we will take reasonable steps to note on the personal information that you requested the correction.</p>
            <p className="pri_para" style={{ color: "black" }}>If you want to exercise either of the above rights, email us at <a href="mailto:contact@wakaonline.nz">contact@wakaonline.nz</a>. Your email should provide evidence of who you are and set out the details of your request (e.g. the personal information, or the correction, that you are requesting).</p>
            <p className="pri_para" style={{ color: "black" }}>We may charge you our reasonable costs of providing to you copies of your personal information or correcting that information. </p>
          </div>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }}>INTERNET USE</h4>
            <p className="pri_para" style={{ color: "black" }}>While we take reasonable steps to maintain secure internet connections, if you provide us with personal information over the internet, the provision of that information is at your own risk.</p>
            <p className="pri_para" style={{ color: "black" }}>If you follow a link on Waka Online to another site, the owner of that site will have its own privacy policy relating to your personal information.  We suggest you review that site’s privacy policy before you provide personal information.</p>
            <p className="pri_para" style={{ color: "black" }}>We use cookies (an alphanumeric identifier that we transfer to your computer’s hard drive so that we can recognise your browser) to monitor your use of Waka Online.  You may disable cookies by changing the settings on your browser, although this may mean that you cannot use all of the features of Waka Online or the Service.</p>
          </div>
          <div>
            <h4 className='pri_subheading' style={{ color: "#b91d1d" }}>CONTACT US</h4>
            <p className="pri_para" style={{ color: "black" }}>If you have any questions about this privacy policy, our privacy practices, or if you would like to request access to, or correction of, your personal information, you can contact us here <a href="mailto:contact@wakaonline.nz">contact@wakaonline.nz</a>.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
