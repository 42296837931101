import React, { useContext } from 'react';
import { EmailContext } from '../Context/DataContext';
import { UserContext } from '../Context/UserContext';

export default function Subscription() {
  const { email } = useContext(EmailContext);
  const { userID } = useContext(UserContext);

  return (
    <stripe-pricing-table
      pricing-table-id={process.env.REACT_APP_PT_ID}
      publishable-key={process.env.REACT_APP_PUB_KEY}
      customer-email={email}
      client-reference-id={userID}
    ></stripe-pricing-table>
  );
}
