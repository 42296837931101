import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import '../assets/CSS/HeroSection.css';

const MakeModelDropdown = ({
  selectedMake, setSelectedMake, selectedModel, setSelectedModel, disabled,
}) => {
  const [makeArray, setMakeArray] = useState([]);
  const [modelArray, setModelArray] = useState([]);


  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SEARCH_API}getmakesmodelsorcombination`, { selectedMake, selectedModel });

        if (selectedMake) {
          const modelArray = response.data.models;
          setModelArray(modelArray);
        }
        else if (selectedModel) {
          const makeArray = response.data.makes;
          setMakeArray(makeArray);
        }
        else if (!selectedMake && !selectedModel) {
          const makeArray = response.data.makes.map(item => item.make);
          setMakeArray(makeArray);
          const modelArray = response.data.models.map(item => item.model);
          setModelArray(modelArray);
        }

      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [selectedMake, selectedModel]);



  const handleMakeChange = (event, selectedMake) => {
    setSelectedMake(selectedMake);
  };

  const handleModelChange = (event, selectedModel) => {
    setSelectedModel(selectedModel);
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => option.toLowerCase().startsWith(inputValue.toLowerCase()));
  };

  return (
    <div className='make-model-container' style={{ display: 'flex' }}>
      <FormControl sx={{ m: 1, minWidth: 100, mx: 3 }} color="error">
        <Autocomplete
          id="demo-simple-select-helper-make"
          labelId="demo-simple-select-helper-label-make"
          options={makeArray}
          value={selectedMake}
          getOptionLabel={(makeoption) => makeoption}
          filterOptions={filterOptions}
          onChange={handleMakeChange}
          disabled={disabled}
          renderInput={(params) => <TextField {...params} label="Make" />}
        />
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 100, mx: 3 }} color="error">
        <Autocomplete
          id="demo-simple-select-helper-model"
          value={selectedModel}
          onChange={handleModelChange}
          options={modelArray}
          getOptionLabel={(model) => String(model)}
          filterOptions={filterOptions}
          disabled={disabled}
          renderInput={(params) => <TextField {...params} label="Model" />}
        />
      </FormControl>
    </div>
  );
};

export default MakeModelDropdown;
