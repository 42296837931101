import axios from 'axios';

export const getUserPreferences = (userID, token) => {
  return axios.get(
    `${process.env.REACT_APP_API_COMMON}user-preference/${userID}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const deleteUserPreferences = (userID, token) => {
  return axios.delete(`${process.env.REACT_APP_API_COMMON}user-preference/${userID}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addUserPreferences = (userID, token, preferences) => {
  const { Make, Model, StartYear, EndYear, FuelType } = preferences;
  return axios.post(`${process.env.REACT_APP_API_COMMON}user-preference`,
    { userID, make: Make, model: Model, startYear: StartYear, endYear: EndYear, fuelType: FuelType },
    { headers: { Authorization: `Bearer ${token}` } });
};
