import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import Card from '@mui/material/Card';
import { CardContent, Typography, CardHeader, TextField, List, ListItem, IconButton, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import '../CSS/Chat.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import swal from 'sweetalert';
import axios from 'axios';
import { jwtToken } from './../../Context/DataContext';
import { Spinner, ButtonSpinner } from './../../components/Spinner';
import { UserContext } from '../../Context/UserContext';
import { UTCdateToFullFormattedDate } from '../../utils/DateAndTimeUtils';
import { ChatContext } from '../../Context/ChatContext';

const ChatScreen = (props) => {
  const { notificationCount, setNotificationCount, setChatsAsDealerSellerContext, setChatsAsSellerContext, setChatsAsBuyerContext, claimID, setClaimID, plateNumber, setPlateNumber, buyerID, setBuyerID, userRole, setUserRole, VIN, setVIN, chatsAsSellerContext, chatsAsBuyerContext, chatsAsDealerSellerContext, isMessagePath, setIsMessagePath } = useContext(ChatContext);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [showNewCard, setShowNewCard] = useState(false); // Added state to control card replacement
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [chatsAsSeller, setChatsAsSeller] = useState([]);
  const [chatsAsDealerSeller, setChatsAsDealerSeller] = useState([]);
  const [chatsAsBuyer, setChatsAsBuyer] = useState([]);
  const [showDealerChats, setShowDealerChats] = useState(false);
  const [showPersonalSellerChats, setShowPersonalSellerChats] = useState(false);
  const [showPersonalBuyerChats, setShowPersonalBuyerChats] = useState(false);
  const [showForWebView, setShowForWebView] = useState(true);
  const [chat, setChat] = useState([]);
  const [userType, setUserType] = useState('');
  const [message, setMessage] = useState('');
  const [inputRows, setInputRows] = useState(1);
  const [initialLoading, setInitialLoading] = useState(false);
  const [sendMsgLoading, setSendMsgLoading] = useState(false);
  const [existChatAsBuyer, setExistChatAsBuyer] = useState(false);
  const [existChatAsSeller, setExistChatAsSeller] = useState(false);
  const [existChatAsDealerSeller, setExistChatAsDealerSeller] = useState(false);
  const chatRef = useRef(null);

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    const lines = inputText.split('\n');

    // Update the number of rows dynamically
    setInputRows(Math.min(Math.max(lines.length, 1), 2));

    // Get the last three lines of the input text
    const lastThreeLines = lines.slice(Math.max(lines.length - 2, 0)).join('\n');
    setMessage(lastThreeLines);
  };

  useEffect(() => {
    const chatContainer = chatRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [chatsAsSeller, chatsAsDealerSeller, chatsAsBuyer, selectedIndex, showNewCard]);

  const fetchMessages = useCallback(async () => {
    if (token && userID) {
      const sellerChatsWithUnreadMessages = [];
      const dealerSellerChatsWithUnreadMessages = [];
      const buyerChatsWithUnreadMessages = [];
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}getMessages`,
          { userID }, { headers: { Authorization: `Bearer ${token}` } },
        );
        if (response.status === 200) {
          response.data.forEach((chats) => {
            if (Object.keys(chats)[0] === 'S') {
              setChatsAsSeller(chats['S']);
              chats['S'].forEach((sElement) => {
                // Check if sElement has hasUnreadMessage === true
                if (sElement.hasOwnProperty('chats') && Array.isArray(sElement.chats)) {
                  sElement.chats.forEach((chat, index) => {
                    if (chat.hasUnreadMessage) {
                      sellerChatsWithUnreadMessages.push({
                        plateNo: sElement.plateNo,
                        VIN: sElement.VIN,
                        chats: [chat],
                      });
                      setChatsAsSellerContext(sellerChatsWithUnreadMessages);
                    }
                  });
                }
              });
            } else if (Object.keys(chats)[0] === 'B') {
              setChatsAsBuyer(chats.B);
              chats.B.forEach((bElement) => {
                // Check if bElement has hasUnreadMessage === true
                if (bElement.hasOwnProperty('hasUnreadMessage') && bElement.hasUnreadMessage === true) {
                  buyerChatsWithUnreadMessages.push(bElement);
                  setChatsAsBuyerContext(buyerChatsWithUnreadMessages);
                }
              });
            } else if (Object.keys(chats)[0] === 'DS') {
              setChatsAsDealerSeller(chats['DS']);
              chats['DS'].forEach((sElement) => {
                // Check if sElement has hasUnreadMessage === true
                if (sElement.hasOwnProperty('chats') && Array.isArray(sElement.chats)) {
                  sElement.chats.forEach((chat, index) => {
                    if (chat.hasUnreadMessage) {
                      dealerSellerChatsWithUnreadMessages.push({
                        plateNo: sElement.plateNo,
                        VIN: sElement.VIN,
                        chats: [chat],
                      });
                      setChatsAsDealerSellerContext(dealerSellerChatsWithUnreadMessages);
                    }
                  });
                }
              });
            }
          });
        }
      } catch (error) {
        swal({
          text: 'Failed to fetch messages!',
          icon: 'error',
          dangerMode: true,
        });
      } finally {
        setInitialLoading(false);
      }
    }
  }, [userID, token]);

  useEffect(() => {
    if (isMobileView && showNewCard) {
      setShowForWebView(false);
    }
  }, [selectedIndex]);


  useEffect(() => {
    setInitialLoading(true);
    fetchMessages();
  }, [fetchMessages]);

  const handleSubmit = async (ClaimID, BuyerID, userType) => {
    setSendMsgLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_COMMON}message`, { claimID: ClaimID, buyerID: BuyerID, message, userType, repliedUserID: userID }, { headers: { Authorization: `Bearer ${token}` } },)
      .then(async (res) => {
        if (res.data) {
          setInitialLoading(true);
          await fetchMessages();
          setUserRole('')
        }
        setSendMsgLoading(false);
        setMessage('');
      })
      .catch((err) => {
        setSendMsgLoading(false);
      });
  };

  const updateMessageStatus = (claimID, buyerID, userType) => {
    axios
      .post(`${process.env.REACT_APP_API_COMMON}updateMessageStatus`, { claimID, buyerID, userType, readByUser: userID }, { headers: { Authorization: `Bearer ${token}` } },)
      .then(async (res) => {
        if (res.data) {
          await fetchMessages();
          setUserRole('')
        }
        setSendMsgLoading(false);
        setMessage('');
      })
      .catch((err) => {
        setSendMsgLoading(false);
      });
  };

  useEffect(() => {
    let notificationCount = 0;
    chatsAsSellerContext.forEach((chats, index) => {
      notificationCount += chats.chats.length;
    });
    chatsAsDealerSellerContext.forEach((chats, index) => {
      notificationCount += chats.chats.length;
    });
    notificationCount += chatsAsBuyerContext.length;
    setNotificationCount(notificationCount);
  }, [existChatAsBuyer, existChatAsSeller, existChatAsDealerSeller, buyerID, claimID, userRole, notificationCount, chatsAsSellerContext, chatsAsDealerSellerContext, chatsAsBuyerContext]);

  useEffect(() => {
    if (userRole === 'buyer') {
      const chatExistAsBuyer = chatsAsBuyer.some((chat) => chat.ClaimID === claimID);
      if (chatExistAsBuyer) {
        setExistChatAsBuyer(true);
        setSelectedIndex(claimID.concat(buyerID));
      } else {
        const newChat = { ClaimID: claimID, BuyerID: buyerID, PlateNumber: plateNumber, Conversation: [], ClaimStatus: 'Active', buyerStatus: 'Active', VIN: VIN };
        setShowPersonalBuyerChats(true);
        setShowNewCard(true);
        setChatsAsBuyer([...chatsAsBuyer, newChat]);
        setSelectedIndex(claimID.concat(buyerID));
      }
    }
    if (userRole === 'seller') {
      const chatExistAsSeller = chatsAsSeller.some((chat) => chat.plateNo === plateNumber);
      if (chatExistAsSeller) {
        const sellerChat = chatsAsSeller.find((chat) => chat.plateNo === plateNumber);
        const existingChat = sellerChat.chats.some((chat) => chat.BuyerID === buyerID);
        if (existingChat) {
          setExistChatAsSeller(true);
          setSelectedIndex(claimID.concat(buyerID));
        } else {
          const newChat = { BuyerID: buyerID, ClaimID: claimID, Conversation: [], buyerStatus: 'Active' };
          sellerChat.chats.push(newChat);
          setSelectedIndex(claimID.concat(buyerID));
          setShowPersonalSellerChats(true);
          setShowNewCard(true);
        }
      } else {
        const newChat = {
          plateNo: plateNumber,
          VIN: VIN,
          chats: [{ BuyerID: buyerID, ClaimID: claimID, Conversation: [], buyerStatus: 'Active' }]
        };
        setChatsAsSeller([...chatsAsSeller, newChat]);
        setSelectedIndex(claimID.concat(buyerID));
        setShowPersonalSellerChats(true);
        setShowNewCard(true);
      }
    }

    if (userRole === 'dealerSeller') {
      const chatExistAsDealerSeller = chatsAsDealerSeller.some((chat) => chat.plateNo === plateNumber);
      if (chatExistAsDealerSeller) {
        const dealerSellerChat = chatsAsDealerSeller.find((chat) => chat.plateNo === plateNumber);
        const existingDealerChat = dealerSellerChat.chats.some((chat) => chat.BuyerID === buyerID);
        if (existingDealerChat) {
          setExistChatAsDealerSeller(true);
          setSelectedIndex(claimID.concat(buyerID));
        } else {
          const newChat = { BuyerID: buyerID, ClaimID: claimID, Conversation: [], buyerStatus: 'Active' };
          dealerSellerChat.chats.push(newChat);
          setSelectedIndex(claimID.concat(buyerID));
          setShowDealerChats(true);
          setShowNewCard(true);
        }
      } else {
        const newChat = {
          plateNo: plateNumber,
          VIN: VIN,
          chats: [{ BuyerID: buyerID, ClaimID: claimID, Conversation: [], buyerStatus: 'Active' }]
        };
        setChatsAsDealerSeller([...chatsAsDealerSeller, newChat]);
        setSelectedIndex(claimID.concat(buyerID));
        setShowDealerChats(true);
        setShowNewCard(true);
      }
    }
  }, [chatsAsBuyer, chatsAsSeller, chatsAsDealerSeller]);

  useEffect(() => {
    if (userRole === 'buyer') {
      if (isMessagePath) {
        setShowPersonalBuyerChats(true);
        setShowForWebView(true);
        setShowDealerChats(false);
        setShowPersonalSellerChats(false);
        setShowNewCard(true);
        setSelectedIndex(claimID.concat(buyerID));
        updateMessageStatus(claimID, buyerID, 's');
      }
      if (existChatAsBuyer) {
        updateMessageStatus(claimID, buyerID, 's');
      }
    }
    if (userRole === 'dealerSeller') {
      if (isMessagePath) {
        setShowDealerChats(true);
        setShowForWebView(true);
        setShowPersonalSellerChats(false);
        setShowPersonalBuyerChats(false);
        setShowNewCard(true);
        setSelectedIndex(claimID.concat(buyerID));
        updateMessageStatus(claimID, buyerID, 'b');
      }
      if (existChatAsDealerSeller) {
        updateMessageStatus(claimID, buyerID, 'b');
      }
    }
    if (userRole === 'seller') {
      if (isMessagePath) {
        setShowPersonalSellerChats(true);
        setShowForWebView(true);
        setShowPersonalBuyerChats(false);
        setShowDealerChats(false);
        setShowNewCard(true);
        setSelectedIndex(claimID.concat(buyerID));
        updateMessageStatus(claimID, buyerID, 'b');
      }
      if (existChatAsSeller) {
        updateMessageStatus(claimID, buyerID, 'b');
      }
    }
  }, [existChatAsBuyer, existChatAsSeller, existChatAsDealerSeller, buyerID, claimID, userRole]);


  const handleListItemClick = (index) => {
    setSelectedIndex(index);
    setShowNewCard(true);
  };


  useEffect(() => {
    const handleResize = () => {
      // Update the state based on the screen width
      setIsMobileView(window.innerWidth <= 767);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="container chatContain" style={{ overflow: 'scroll' }}>
      <div>
        {initialLoading && !sendMsgLoading ? (<Spinner></Spinner>) : (
          <>
            {chatsAsSeller.length === 0 && chatsAsDealerSeller.length === 0 && chatsAsBuyer.length === 0 ? (<div className="text-center">No Messages</div>) : (
              <>
                <div className="chatSelect d-flex justify-content-center">
                  {chatsAsDealerSeller.length > 0 && (
                    <Button className="chatSelectButton" style={{ borderRadius: '10px 0px 0px 10px' }} onClick={() => {
                      setShowDealerChats(true);
                      setShowForWebView(true);
                      setShowPersonalSellerChats(false);
                      setShowPersonalBuyerChats(false);
                      setShowNewCard(false);
                      setSelectedIndex(0);
                      setClaimID('');
                      setPlateNumber('');
                      setBuyerID('');
                      setUserRole('');
                      setVIN('');
                    }}>
                      Dealer chats
                    </Button>
                  )}
                  {chatsAsSeller.length > 0 && (
                    <Button className="chatSelectButton" style={{ borderRadius: '0px 0px 0px 0px' }} onClick={() => {
                      setShowPersonalSellerChats(true);
                      setShowForWebView(true);
                      setShowPersonalBuyerChats(false);
                      setShowDealerChats(false);
                      setShowNewCard(false);
                      setSelectedIndex(0);
                      setClaimID('');
                      setPlateNumber('');
                      setBuyerID('');
                      setUserRole('');
                      setVIN('');
                    }}>
                      Personal seller chats
                    </Button>
                  )}
                  {chatsAsBuyer.length > 0 && (
                    <Button className="chatSelectButton" style={{ borderRadius: '0px 10px 10px 0px' }} onClick={() => {
                      setShowPersonalBuyerChats(true);
                      setShowForWebView(true);
                      setShowDealerChats(false);
                      setShowPersonalSellerChats(false);
                      setShowNewCard(false);
                      setSelectedIndex(0);
                      setClaimID('');
                      setPlateNumber('');
                      setBuyerID('');
                      setUserRole('');
                      setVIN('');
                    }}>
                      Personal buyer chats
                    </Button>
                  )}
                </div>
                <div className="row align-items-start">
                  {isMobileView ? (
                    <>
                      {showPersonalSellerChats && showForWebView && (
                        <>
                          {chatsAsSeller && chatsAsSeller.map((chats, index) => (
                            <div key={index} className="row">
                              <div className="col-12 col-md-4 sm-6 xs-6 d-flex justify-content-center persons">
                                <Card style={{ width: '100%', borderRadius: '10px' }}>
                                  <CardHeader
                                    title=""
                                    subheader={chats.plateNo || chats.VIN.substr(-4)}
                                    style={{ backgroundColor: '#f0ebeb', color: '#fff', fontWeight: chats.chats.some(chat => chat.hasUnreadMessage) ? '900' : '100' }}
                                  />
                                  <CardContent>
                                    <List>
                                      {chats.chats.length > 0 && chats.chats.map((chat, index) => (
                                        <React.Fragment key={index}>
                                          <ListItem
                                            selected={selectedIndex === chat.ClaimID.concat(chat.BuyerID)}
                                            onClick={(event) => {
                                              setChat(chat);
                                              setUserType('b');
                                              handleListItemClick(chat.ClaimID.concat(chat.BuyerID));
                                              if (chat.hasUnreadMessage) {
                                                updateMessageStatus(chat.ClaimID, chat.BuyerID, 'b');
                                              }
                                            }}>
                                            <Typography variant="body1" color="textSecondary">
                                              <span
                                                style={{
                                                  backgroundColor: chat.hasUnreadMessage ? '' : 'transparent',
                                                  fontWeight: chat.hasUnreadMessage ? '900' : '100'
                                                }}>
                                                {chat.buyerNickName ? `${chat.buyerNickName}${chat.BuyerID.substr(-4)}` : `Buyer: ${chat.BuyerID.substr(-4)}`}</span>
                                            </Typography>
                                          </ListItem>
                                          {index < chats.chats.length - 1 && <hr />}
                                        </React.Fragment>
                                      ))}
                                    </List>
                                  </CardContent>
                                </Card>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {showPersonalSellerChats && (
                        <>
                          {chatsAsSeller && chatsAsSeller.map((chats, index) => (
                            <div key={index} className="col-12 col-md-8 sm-6 xs-6 d-flex justify-content-center">
                              {showNewCard ? (
                                <>
                                  {chats.chats && chats.chats.map((chat, index) => (
                                    selectedIndex === chat.ClaimID.concat(chat.BuyerID) &&
                                    <Card key={index} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', borderRadius: '10px' }}>
                                      <CardHeader
                                        title=""
                                        subheader={
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton onClick={() => {
                                              setShowNewCard(false);
                                              setSelectedIndex(0);
                                              if (isMobileView) {
                                                setShowPersonalSellerChats(true);
                                                setShowForWebView(true);
                                              }
                                            }}>
                                              <ArrowBackIcon />
                                            </IconButton>
                                            {chats.plateNo || chats.VIN.substr(-4)} -: {chat.buyerNickName ? `${chat.buyerNickName}${chat.BuyerID.substr(-4)}` : `Buyer: ${chat.BuyerID.substr(-4)}`}
                                          </div>
                                        }
                                        style={{ backgroundColor: '#f0ebeb', color: '#fff' }}
                                      />
                                      <CardContent ref={chatRef} className="theChat">
                                        <>
                                          {chat.Conversation && chat.Conversation.map((msg, index) => (
                                            <div key={index}>
                                              {msg['s'] && (
                                                <div className="msges" style={{ marginLeft: '35%', backgroundColor: '#e3a1a1', padding: '5px', borderRadius: '7px', width: '65%', wordBreak: 'break-all' }}>
                                                  <div className="chatmsg">{msg['s'].message} <br /></div>
                                                  <div className="time">{UTCdateToFullFormattedDate(msg['s'].time)}<br /></div>
                                                </div>
                                              )}
                                              {msg['b'] && (
                                                <div className="msges" style={{ backgroundColor: '#e0dcdc', padding: '5px', borderRadius: '7px', width: '65%', wordBreak: 'break-all' }}>
                                                  <div className="chatmsg">{msg['b'].message} <br /></div>
                                                  <div className="time2">{UTCdateToFullFormattedDate(msg['b'].time)}<br /></div>
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                        </>
                                      </CardContent>
                                      <div className="" style={{ backgroundColor: '#f0ebeb', color: '#fff' }}>
                                        <CardContent style={{ borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                                          {chat.buyerStatus === 'Active' ? (
                                            <>
                                              <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                                <TextField
                                                  id="description"
                                                  label=""
                                                  placeholder="Enter your message here"
                                                  multiline
                                                  value={message}
                                                  onChange={handleInputChange}
                                                  fullWidth
                                                  rows={inputRows} // Dynamic number of visible lines
                                                  style={{ backgroundColor: '#fcfcfc', color: '#fff', borderRadius: '10px', width: '100%', border: '1px solid #8f8585' }}
                                                />
                                              </div>
                                              <IconButton onClick={() => {
                                                handleSubmit(chat.ClaimID, chat.BuyerID, 's');
                                              }}
                                                disabled={sendMsgLoading}>
                                                {sendMsgLoading ? <ButtonSpinner /> : <SendIcon />}
                                              </IconButton>
                                            </>
                                          ) : (
                                            <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                              <div className='cht-disable'>
                                                Sorry! Buyer account has been closed or blocked.
                                              </div>
                                            </div>
                                          )}
                                        </CardContent>
                                      </div>
                                    </Card>
                                  ))}
                                </>
                              ) : (
                                <Card style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', borderRadius: '10px' }}>
                                </Card>
                              )}
                            </div>
                          ))}
                        </>
                      )}


                      {showDealerChats && showForWebView && (
                        <>
                          {chatsAsDealerSeller && chatsAsDealerSeller.map((chats, index) => (
                            <div key={index} className="row theChat">
                              <div className="col-12 col-md-4 sm-6 xs-6 d-flex justify-content-center persons">
                                <Card style={{ width: '100%', borderRadius: '10px', marginBottom: '10px' }}>
                                  <CardHeader
                                    title={chats.dealerCompanyName}
                                    subheader={chats.plateNo || chats.VIN.substr(-4)}
                                    style={{ backgroundColor: '#f0ebeb', color: '#fff', fontWeight: chats.chats.some(chat => chat.hasUnreadMessage) ? '900' : '100' }}
                                  />
                                  <CardContent>
                                    <List>
                                      {chats.chats.length > 0 && chats.chats.map((chat, index) => (
                                        <React.Fragment key={index}>
                                          <ListItem
                                            selected={selectedIndex === chat.ClaimID.concat(chat.BuyerID)}
                                          >
                                            <Typography variant="body1" color="textSecondary"
                                              onClick={(event) => {
                                                setChat(chat);
                                                setUserType('b');
                                                handleListItemClick(chat.ClaimID.concat(chat.BuyerID));
                                                if (chat.hasUnreadMessage) {
                                                  updateMessageStatus(chat.ClaimID, chat.BuyerID, 'b');
                                                }
                                              }}>
                                              <span
                                                style={{
                                                  backgroundColor: chat.hasUnreadMessage ? '' : 'transparent',
                                                  fontWeight: chat.hasUnreadMessage ? '900' : '100'
                                                }}
                                              >
                                                {chat.buyerNickName ? `${chat.buyerNickName}${chat.BuyerID.substr(-4)}` : `Buyer: ${chat.BuyerID.substr(-4)}`}</span>
                                            </Typography>
                                          </ListItem>
                                          {index < chats.chats.length - 1 && <hr />}
                                        </React.Fragment>
                                      ))}
                                    </List>
                                  </CardContent>
                                </Card>
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      {showDealerChats && (
                        <>
                          {chatsAsDealerSeller && chatsAsDealerSeller.map((chats, index) => (
                            <div key={index} className="col-12 col-md-8 sm-6 xs-6 d-flex justify-content-center">
                              {showNewCard ? (
                                <>
                                  {chats.chats && chats.chats.map((chat, index) => (
                                    selectedIndex === chat.ClaimID.concat(chat.BuyerID) &&
                                    <div key={index} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', borderRadius: '10px' }}>
                                      <Card style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', borderRadius: '10px' }}>
                                        <CardHeader
                                          title=""
                                          subheader={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <IconButton onClick={() => {
                                                setShowNewCard(false);
                                                setSelectedIndex(0);
                                                if (isMobileView) {
                                                  setShowDealerChats(true);
                                                  setShowForWebView(true);
                                                }
                                              }}>
                                                <ArrowBackIcon />
                                              </IconButton>
                                              {chats.plateNo || chats.VIN.substr(-4)} -: {chat.buyerNickName ? `${chat.buyerNickName}${chat.BuyerID.substr(-4)}` : `Buyer: ${chat.BuyerID.substr(-4)}`}
                                            </div>
                                          }
                                          style={{ backgroundColor: '#f0ebeb', color: '#fff' }}
                                        />
                                        <CardContent ref={chatRef} className="theChat">
                                          <>
                                            {chat.Conversation && chat.Conversation.map((msg, index) => (
                                              <div key={index}>
                                                {msg['s'] && (
                                                  <div className="msges" style={{ marginLeft: '35%', backgroundColor: '#e3a1a1', padding: '5px', borderRadius: '7px', width: '65%', wordBreak: 'break-all' }}>
                                                    <div className="chatmsg">{msg['s'].message} <br /></div>
                                                    <div className="time">{UTCdateToFullFormattedDate(msg['s'].time)}<br /></div>
                                                  </div>
                                                )}
                                                {msg['b'] && (
                                                  <div className="msges" style={{ backgroundColor: '#e0dcdc', padding: '5px', borderRadius: '7px', width: '65%', wordBreak: 'break-all' }}>
                                                    <div className="chatmsg">{msg['b'].message} <br /></div>
                                                    <div className="time2">{UTCdateToFullFormattedDate(msg['b'].time)}<br /></div>
                                                  </div>
                                                )}
                                              </div>
                                            ))}
                                          </>
                                        </CardContent>
                                        <div className="" style={{ backgroundColor: '#f0ebeb', color: '#fff' }}>
                                          <CardContent style={{ borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                                            {chat.buyerStatus === 'Active' ? (
                                              <>
                                                <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                                  <TextField
                                                    id="description"
                                                    label=""
                                                    placeholder="Enter your message here"
                                                    multiline
                                                    value={message}
                                                    onChange={handleInputChange}
                                                    fullWidth
                                                    rows={inputRows} // Dynamic number of visible lines
                                                    style={{ backgroundColor: '#fcfcfc', color: '#fff', borderRadius: '10px', width: '100%', border: '1px solid #8f8585' }}
                                                  />
                                                </div>
                                                <IconButton onClick={() => {
                                                  handleSubmit(chat.ClaimID, chat.BuyerID, 's');
                                                }}
                                                  disabled={sendMsgLoading}>
                                                  {sendMsgLoading ? <ButtonSpinner /> : <SendIcon />}
                                                </IconButton>
                                              </>
                                            ) : (
                                              <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                                <div className='cht-disable'>
                                                  Sorry! Buyer account has been closed or blocked.
                                                </div>
                                              </div>
                                            )}
                                          </CardContent>
                                        </div>
                                      </Card>
                                    </div>
                                  ))
                                  }
                                </>
                              ) : (
                                <Card style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', borderRadius: '10px' }}>
                                </Card>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                      {showPersonalBuyerChats && (
                        <>
                          {chatsAsBuyer && chatsAsBuyer.map((chat, index) => (
                            <div key={index} className="row">
                              {showForWebView && (
                                <div className="col-12 col-md-4 sm-6 xs-6 d-flex justify-content-center persons">
                                  <Card style={{ width: '100%', borderRadius: '10px', marginBottom: '10px' }}>
                                    <CardContent>
                                      <List>
                                        <React.Fragment key={index}>
                                          <ListItem
                                            selected={selectedIndex === chat.ClaimID.concat(chat.BuyerID)}
                                          >
                                            <Typography variant="body1" color="textSecondary">
                                              <span
                                                style={{
                                                  backgroundColor: chat.hasUnreadMessage ? '' : 'transparent',
                                                  fontWeight: chat.hasUnreadMessage ? '900' : '100'
                                                }}
                                                onClick={(event) => {
                                                  setChat(chat);
                                                  setUserType('s');
                                                  handleListItemClick(chat.ClaimID.concat(chat.BuyerID));
                                                  if (chat.hasUnreadMessage) {
                                                    updateMessageStatus(chat.ClaimID, chat.BuyerID, 's');
                                                  }
                                                }}
                                              >
                                                {chat.SellerNickName ? `${chat.SellerNickName}: ${chat.PlateNumber || chat.VIN.substr(-6)}` : `Seller: ${chat.PlateNumber || chat.VIN.substr(-6)}`}
                                              </span>
                                            </Typography>
                                          </ListItem>
                                          {index < chat.length - 1 && <hr />}
                                        </React.Fragment>
                                      </List>
                                    </CardContent>
                                  </Card>
                                </div>
                              )}
                              <div className="col-12 col-md-8 sm-6 xs-6 d-flex justify-content-center">
                                {showNewCard ? (
                                  // <NewCard />
                                  selectedIndex === chat.ClaimID.concat(chat.BuyerID) &&
                                  <Card style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', borderRadius: '10px' }}>
                                    <CardHeader
                                      title=""
                                      subheader={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <IconButton onClick={() => {
                                            setShowNewCard(false);
                                            setSelectedIndex(0);
                                            if (isMobileView) {
                                              setShowPersonalBuyerChats(true);
                                              setShowForWebView(true);
                                            }
                                          }}>
                                            <ArrowBackIcon />
                                          </IconButton>
                                          {chat.SellerNickName ? `${chat.SellerNickName}: ${chat.PlateNumber || chat.VIN.substr(-6)}` : `Seller: ${chat.PlateNumber || chat.VIN.substr(-6)}`}
                                        </div>
                                      }
                                      style={{ backgroundColor: '#f0ebeb', color: '#fff' }}
                                    />
                                    <CardContent ref={chatRef} className="theChat">
                                      <>
                                        {chat.Conversation && chat.Conversation.map((msg, index) =>
                                        (
                                          <div key={index}>
                                            {msg['s'] && (
                                              <div className="msges" style={{ backgroundColor: '#e0dcdc', padding: '5px', borderRadius: '7px', width: '65%', wordBreak: 'break-all' }}>
                                                <div className="chatmsg">{msg['s'].message} <br /></div>
                                                <div className="time">{UTCdateToFullFormattedDate(msg['s'].time)}<br /></div>
                                              </div>
                                            )}
                                            {msg['b'] && (
                                              <div className="msges" style={{ marginLeft: '35%', backgroundColor: '#e3a1a1', padding: '5px', borderRadius: '7px', width: '65%', wordBreak: 'break-all' }}>
                                                <div className="chatmsg">{msg['b'].message} <br /></div>
                                                <div className="time2">{UTCdateToFullFormattedDate(msg['b'].time)}<br /></div>
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                      </>
                                    </CardContent>
                                    <div className="" style={{ backgroundColor: '#f0ebeb', color: '#fff' }}>
                                      <CardContent style={{ borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                                        {chat.ClaimStatus === 'Active' ? (
                                          <>
                                            <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                              <TextField
                                                id="description"
                                                label=""
                                                placeholder="Enter your message here"
                                                multiline
                                                value={message}
                                                onChange={handleInputChange}
                                                fullWidth
                                                rows={inputRows} // Dynamic number of visible lines
                                                style={{ backgroundColor: '#fcfcfc', color: '#fff', borderRadius: '10px', width: '100%', border: '1px solid #8f8585' }}
                                              />
                                            </div>
                                            <IconButton onClick={() => {
                                              handleSubmit(chat.ClaimID, chat.BuyerID, 'b');
                                            }}
                                              disabled={sendMsgLoading}>
                                              {sendMsgLoading ? <ButtonSpinner /> : <SendIcon />}
                                            </IconButton>
                                          </>
                                        ) : (
                                          <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                            <div className='cht-disable'>
                                              Sorry! Seller has already sold the vehicle or the account has been closed or blocked.
                                            </div>
                                          </div>
                                        )}
                                      </CardContent>
                                    </div>
                                  </Card>
                                ) : (
                                  <Card style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', borderRadius: '10px' }}>
                                  </Card>
                                )}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    // Render the existing components for the non-mobile view
                    <>
                      <div className="col-12 col-md-4 sm-6 xs-6 d-flex justify-content-center persons">

                        {showPersonalBuyerChats && chatsAsBuyer && chatsAsBuyer.map((chat, index) => (
                          <Card className="ss" key={index} style={{ width: '100%', borderRadius: '10px', marginBottom: '10px' }}>
                            <CardContent>
                              <List>
                                <React.Fragment key={index}>
                                  <ListItem
                                    selected={selectedIndex === chat.ClaimID.concat(chat.BuyerID)}
                                  >
                                    <Typography variant="body1" color="textSecondary">
                                      <span
                                        style={{
                                          backgroundColor: chat.hasUnreadMessage ? '' : 'transparent',
                                          fontWeight: chat.hasUnreadMessage ? '900' : '100'
                                        }}
                                        onClick={(event) => {
                                          setChat(chat);
                                          setUserType('s');
                                          handleListItemClick(chat.ClaimID.concat(chat.BuyerID));
                                          if (chat.hasUnreadMessage) {
                                            updateMessageStatus(chat.ClaimID, chat.BuyerID, 's');
                                          }
                                        }}
                                      >
                                        {chat.SellerNickName ? `${chat.SellerNickName}: ${chat.PlateNumber || chat.VIN.substr(-6)}` : `Seller: ${chat.PlateNumber || chat.VIN.substr(-6)}`}
                                      </span>
                                    </Typography>
                                  </ListItem>
                                  {index < chat.length - 1 && <hr />}
                                </React.Fragment>
                              </List>
                            </CardContent>
                          </Card>
                        ))}

                        {showPersonalSellerChats && chatsAsSeller && chatsAsSeller.map((chats, index) => (
                          <Card key={index} style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }}>
                            <CardHeader
                              title=""
                              subheader={chats.plateNo || chats.VIN.substr(-4)}
                              style={{ backgroundColor: '#f0ebeb', color: '#fff', fontWeight: chats.chats.some(chat => chat.hasUnreadMessage) ? '900' : '100' }}
                            />
                            <CardContent>
                              <List>
                                {chats.chats.length > 0 && chats.chats.map((chat, index) => (
                                  <React.Fragment key={index}>
                                    <ListItem
                                      selected={selectedIndex === chat.ClaimID.concat(chat.BuyerID)}
                                      onClick={(event) => {
                                        setChat(chat);
                                        setUserType('b');
                                        handleListItemClick(chat.ClaimID.concat(chat.BuyerID));
                                        if (chat.hasUnreadMessage) {
                                          updateMessageStatus(chat.ClaimID, chat.BuyerID, 'b');
                                        }
                                      }}>
                                      <Typography variant="body1" color="textSecondary">
                                        <span
                                          style={{
                                            backgroundColor: chat.hasUnreadMessage ? '' : 'transparent',
                                            fontWeight: chat.hasUnreadMessage ? '900' : '100'
                                          }}>
                                          {chat.buyerNickName ? `${chat.buyerNickName}${chat.BuyerID.substr(-4)}` : `Buyer: ${chat.BuyerID.substr(-4)}`}</span>
                                      </Typography>
                                    </ListItem>
                                    {index < chats.chats.length - 1 && <hr />}
                                  </React.Fragment>
                                ))}
                              </List>
                            </CardContent>
                          </Card>
                        ))}
                        <Card style={{ overflowY: 'scroll', maxHeight: '550px' }}>
                          {showDealerChats && chatsAsDealerSeller && chatsAsDealerSeller.map((chats, index) => (
                            <Card key={index} style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }}>
                              <CardHeader
                                title={chats.dealerCompanyName}
                                subheader={chats.plateNo || chats.VIN.substr(-4)}
                                style={{ backgroundColor: '#f0ebeb', color: '#fff', fontWeight: chats.chats.some(chat => chat.hasUnreadMessage) ? '900' : '100' }}
                              />
                              <CardContent>
                                <List>
                                  {chats.chats.length > 0 && chats.chats.map((chat, index) => (
                                    <React.Fragment key={index}>
                                      <ListItem
                                        selected={selectedIndex === chat.ClaimID.concat(chat.BuyerID)}
                                        onClick={(event) => {
                                          setChat(chat);
                                          setUserType('b');
                                          handleListItemClick(chat.ClaimID.concat(chat.BuyerID));
                                          if (chat.hasUnreadMessage) {
                                            updateMessageStatus(chat.ClaimID, chat.BuyerID, 'b');
                                          }
                                        }}>
                                        <Typography variant="body1" color="textSecondary">
                                          <span
                                            style={{
                                              backgroundColor: chat.hasUnreadMessage ? '' : 'transparent',
                                              fontWeight: chat.hasUnreadMessage ? '900' : '100'
                                            }}>
                                            {chat.buyerNickName ? `${chat.buyerNickName}${chat.BuyerID.substr(-4)}` : `Buyer: ${chat.BuyerID.substr(-4)}`}</span>
                                        </Typography>
                                      </ListItem>
                                      {index < chats.chats.length - 1 && <hr />}
                                    </React.Fragment>
                                  ))}
                                </List>
                              </CardContent>
                            </Card>
                          ))}
                        </Card>
                      </div>
                      <div className="col-12 col-md-8 sm-6 xs-6 d-flex justify-content-center">
                        {showNewCard ? (
                          <>
                            {/* // Render the existing chat card */}
                            {showPersonalBuyerChats && chatsAsBuyer && chatsAsBuyer.map((chat, index) => (
                              selectedIndex === chat.ClaimID.concat(chat.BuyerID) &&
                              <Card key={index} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', borderRadius: '10px' }}>
                                <CardHeader
                                  title=""
                                  subheader={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {chat.SellerNickName ? `${chat.SellerNickName}: ${chat.PlateNumber || chat.VIN.substr(-6)}` : `Seller: ${chat.PlateNumber || chat.VIN.substr(-6)}`}
                                    </div>
                                  }
                                  style={{ backgroundColor: '#f0ebeb', color: '#fff' }}
                                />
                                <CardContent ref={chatRef} className="theChat">
                                  <>
                                    {chat.Conversation && chat.Conversation.map((msg, index) =>
                                    (
                                      <div key={index}>
                                        {msg['s'] && (
                                          <div className="msges" style={{ backgroundColor: '#e0dcdc', padding: '5px', borderRadius: '7px', width: '70%', wordBreak: 'break-all' }}>
                                            <div className="chatmsg">{msg['s'].message} <br /></div>
                                            <div className="time">{UTCdateToFullFormattedDate(msg['s'].time)}<br /></div>
                                          </div>
                                        )}
                                        {msg['b'] && (
                                          <div className="msges" style={{ marginLeft: '30%', backgroundColor: '#e3a1a1', padding: '5px', borderRadius: '7px', width: '70%', wordBreak: 'break-all' }}>
                                            <div className="chatmsg">{msg['b'].message} <br /></div>
                                            <div className="time2">{UTCdateToFullFormattedDate(msg['b'].time)}<br /></div>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </>
                                </CardContent>
                                <div className="" style={{ backgroundColor: '#f0ebeb', color: '#fff' }}>
                                  <CardContent style={{ borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                                    {chat.ClaimStatus === 'Active' ? (
                                      <>
                                        <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                          <TextField
                                            id="description"
                                            label=""
                                            placeholder="Enter your message here"
                                            multiline
                                            value={message}
                                            onChange={handleInputChange}
                                            fullWidth
                                            rows={inputRows} // Dynamic number of visible lines
                                            style={{ backgroundColor: '#fcfcfc', color: '#fff', borderRadius: '10px', width: '100%', border: '1px solid #8f8585' }}
                                          />
                                        </div>
                                        <IconButton onClick={() => {
                                          handleSubmit(chat.ClaimID, chat.BuyerID, 'b');
                                        }}
                                          disabled={sendMsgLoading}>
                                          {sendMsgLoading ? <ButtonSpinner /> : <SendIcon />}
                                        </IconButton>
                                      </>
                                    ) : (
                                      <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                        <div className='cht-disable'>
                                          Sorry! Seller has already sold the vehicle or the account has been closed or blocked.
                                        </div>
                                      </div>
                                    )}
                                  </CardContent>
                                </div>
                              </Card>
                            ))}

                            {showPersonalSellerChats && chatsAsSeller && chatsAsSeller.map((chats, index) => (
                              <div key={index}>
                                {chats.chats && chats.chats.map((chat, index) => (
                                  selectedIndex === chat.ClaimID.concat(chat.BuyerID) &&
                                  <Card className="chatCard" key={index} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', borderRadius: '10px' }}>
                                    <CardHeader
                                      title=""
                                      subheader={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          {chats.plateNo || chats.VIN.substr(-4)} : {chat.buyerNickName ? `${chat.buyerNickName}${chat.BuyerID.substr(-4)}` : `Buyer: ${chat.BuyerID.substr(-4)}`}
                                        </div>
                                      }
                                      style={{ backgroundColor: '#f0ebeb', color: '#fff' }}
                                    />
                                    <CardContent ref={chatRef} className="theChat">
                                      <>
                                        {chat.Conversation && chat.Conversation.map((msg, index) => (
                                          <div key={index}>
                                            {msg['s'] && (
                                              <div className="msges" style={{ marginLeft: '30%', backgroundColor: '#e3a1a1', padding: '5px', borderRadius: '7px', width: '70%', wordBreak: 'break-all' }}>
                                                <div className="chatmsg">{msg['s'].message} <br /></div>
                                                <div className="time">{UTCdateToFullFormattedDate(msg['s'].time)}<br /></div>
                                              </div>
                                            )}
                                            {msg['b'] && (
                                              <div className="msges" style={{ backgroundColor: '#e0dcdc', padding: '5px', borderRadius: '7px', width: '70%', wordBreak: 'break-all' }}>
                                                <div className="chatmsg">{msg['b'].message} <br /></div>
                                                <div className="time2">{UTCdateToFullFormattedDate(msg['b'].time)}<br /></div>
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                      </>
                                    </CardContent>
                                    <div className="" style={{ backgroundColor: '#f0ebeb', color: '#fff' }}>
                                      <CardContent style={{ borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                                        {chat.buyerStatus === 'Active' ? (
                                          <>
                                            <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                              <TextField
                                                id="description"
                                                label=""
                                                placeholder="Enter your message hesre"
                                                multiline
                                                value={message}
                                                onChange={handleInputChange}
                                                fullWidth
                                                rows={inputRows} // Dynamic number of visible lines
                                                style={{ backgroundColor: '#fcfcfc', color: '#fff', borderRadius: '10px', width: '100%', border: '1px solid #8f8585' }}
                                              />
                                            </div>
                                            <IconButton onClick={() => {
                                              handleSubmit(chat.ClaimID, chat.BuyerID, 's');
                                            }}
                                              disabled={sendMsgLoading}>
                                              {sendMsgLoading ? <ButtonSpinner /> : <SendIcon />}
                                            </IconButton>
                                          </>
                                        ) : (
                                          <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                            <div className='cht-disable'>
                                              Sorry! Buyer account has been closed or blocked.
                                            </div>
                                          </div>
                                        )}
                                      </CardContent>
                                    </div>
                                  </Card>
                                ))}
                              </div>
                            ))}

                            {showDealerChats && chatsAsDealerSeller && chatsAsDealerSeller.map((chats, index) => (
                              <div key={index}>
                                {chats.chats && chats.chats.map((chat, index) => (
                                  selectedIndex === chat.ClaimID.concat(chat.BuyerID) &&
                                  <Card className="chatCard" key={index} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', borderRadius: '10px' }}>
                                    <CardHeader
                                      title=""
                                      subheader={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          {chats.plateNo || chats.VIN.substr(-4)} -: {chat.buyerNickName ? `${chat.buyerNickName}${chat.BuyerID.substr(-4)}` : `Buyer: ${chat.BuyerID.substr(-4)}`}
                                        </div>
                                      }
                                      style={{ backgroundColor: '#f0ebeb', color: '#fff' }}
                                    />
                                    <CardContent ref={chatRef} className="theChat">
                                      <>
                                        {chat.Conversation && chat.Conversation.map((msg, index) => (
                                          <div key={index}>
                                            {msg['s'] && (
                                              <div className="msges" style={{ marginLeft: '30%', backgroundColor: '#e3a1a1', padding: '5px', borderRadius: '7px', width: '70%', wordBreak: 'break-all' }}>
                                                <div className="chatmsg">{msg['s'].message} <br /></div>
                                                <div className="time"> {UTCdateToFullFormattedDate(msg['s'].time)}<br /> </div>
                                              </div>
                                            )}
                                            {msg['b'] && (
                                              <div className="msges" style={{ backgroundColor: '#e0dcdc', padding: '5px', borderRadius: '7px', width: '70%', wordBreak: 'break-all' }}>
                                                <div className="chatmsg">{msg['b'].message} <br /></div>
                                                <div className="time2">{UTCdateToFullFormattedDate(msg['b'].time)}<br /></div>
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                      </>
                                    </CardContent>
                                    <div className="" style={{ backgroundColor: '#f0ebeb', color: '#fff' }}>
                                      <CardContent style={{ borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                                        {chat.buyerStatus === 'Active' ? (
                                          <>
                                            <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                              <TextField
                                                id="description"
                                                label=""
                                                placeholder="Enter your message here"
                                                multiline
                                                value={message}
                                                onChange={handleInputChange}
                                                fullWidth
                                                rows={inputRows} // Dynamic number of visible lines
                                                style={{ backgroundColor: '#fcfcfc', color: '#fff', borderRadius: '10px', width: '100%', border: '1px solid #8f8585' }}
                                              />
                                            </div>
                                            <IconButton onClick={() => {
                                              handleSubmit(chat.ClaimID, chat.BuyerID, 's');
                                            }}
                                              disabled={sendMsgLoading}>
                                              {sendMsgLoading ? <ButtonSpinner /> : <SendIcon />}
                                            </IconButton>
                                          </>
                                        ) : (
                                          <div style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #8f8585', flex: '1' }}>
                                            <div className='cht-disable'>
                                              Sorry! Buyer account has been closed or blocked.
                                            </div>
                                          </div>
                                        )}
                                      </CardContent>
                                    </div>
                                  </Card>
                                ))}
                              </div>
                            ))}
                          </>
                        ) : (
                          // Render the new card after clicking
                          <Card style={{ width: '500px', height: '300px', borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <p style={{ color: 'black' }}>Select chat to continue</p>
                          </Card>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChatScreen;