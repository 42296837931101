import React from 'react';
import '../assets/CSS/TermsConditions.css';

const TermsConditions = () => {
  return (

    <div className='container-fluid p-spacing'>
      <div className='row m-spacing'>
        <p className="waka-online" style={{ color: "#b91d1d" }}>
          <b style={{ fontFamily: 'Montserrat, sans-serif' }}><span className="first-letter">W</span>AKA &nbsp; <span className="first-letter">O</span>NLINE &nbsp; <span className="pri_pol" style={{ color: "#444444" }}>TERMS&nbsp;AND&nbsp;CONDITIONS</span></b>
        </p>
        <ol className='tc_first'>
          <li className="tc_list" >
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}> APPLICATION OF TERMS </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>1.1	These Terms apply to your use of Waka Online. By accessing and using Waka Online:</p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>you accept and agree to be bound by these Terms; and</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>where your access and use is on behalf of another person (e.g. a company), you confirm that you are authorised to, and do in fact, agree to these Terms on that person’s behalf and that, by agreeing to these Terms on that person’s behalf, that person is bound by these Terms. </li>
            </ol>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>1.2	If you do not agree to these Terms, you are not authorised to access and use Waka Online, and you must immediately stop doing so.</p>
          </li>

          <li className="tc_list" >
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}> CHANGES </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>2.1	We may change these Terms at any time by updating them on Waka Online.  We will use reasonable efforts to provide advance notice of our changes, by notifying you of the change by email or by posting a notice on the Website.  Unless stated otherwise, any change takes effect immediately.  You are responsible for ensuring you are familiar with the latest Terms.  By continuing to access and use Waka Online from the date on which the Terms are changed, you agree to be bound by the changed Terms.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>2.2	We may change, suspend, discontinue, or restrict access to, Waka Online without notice or liability.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>2.3	<b>Terms and Conditions last updated:  These Terms were last updated on 15th June, 2023.</b> </p>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}> DEFINITIONS AND INTERPRETATION </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>3.1	Definitions:  In these Terms:</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Account</i> means the unique account allocated to you to allow you to access Waka Online </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Buyer</i> means a person who has set up an account with Waka Online to buy a vehicle via Waka Online </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>CGA </i> means the Consumer Guarantees Act 1993  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Content  </i> means content, data, and information (including personal information) that is owned, held, used or created by you or on your behalf, and that is then stored, transmitted via, input into or displayed via Waka Online including, in the case of a Seller, all content, data and information uploaded into Waka Online by the Seller when creating a Listing   </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Fees  </i> means the fees payable by Sellers to us in respect of Waka Online as set out on our fees page at <a href="/vehiclePasses">vehicle passes</a>.   </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Intellectual Property Rights</i> includes copyright and all rights anywhere in the world conferred under statute, common law or equity relating to inventions (including patents), registered and unregistered trade marks and designs, circuit layouts, data and databases, confidential information, know-how, and all other rights resulting from intellectual activity. <i>Intellectual Property</i>  has a consistent meaning, and includes any enhancement, modification or derivative work of the Intellectual Property.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Listing  </i> means
              <ul className="pri_ul">
                <li className="pri_li" style={{ fontFamily: 'Montserrat, sans-serif' }}>a post by a Seller on Waka Online claiming ownership of a vehicle; or </li>
                <li className="pri_li" style={{ fontFamily: 'Montserrat, sans-serif' }}>a Sale Listing </li>
              </ul>
            </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Loss  </i> includes loss of profits, savings, revenue or data, and any other claim, damage, loss, liability and cost, including legal costs on a solicitor and own client basis  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Motor Vehicle Trader   </i> means a person that is a motor vehicle trader under the MVSA   </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>MVSA    </i> means the Motor Vehicle Sales Act 2003    </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Objectionable </i>  includes being objectionable, defamatory, obscene, harassing, threatening or unlawful, in any way   </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Personal information     </i> means information about an identifiable, living person   </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Sale Listing     </i> means a post by a Seller on Waka Online offering a vehicle for sale     </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Seller     </i> means a person who has set up an account with Waka Online to claim ownership of a vehicle or to sell a vehicle via Waka Online     </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Terms     </i> means these terms and conditions titled <i>Waka Online Terms and Conditions</i>     </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Underlying Systems     </i> means the IT solutions, systems and networks (including software and hardware) used to provide Waka Online, including any third party solutions, systems and networks     </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Waka Online     </i> means the Website and the services provided by us via the Website  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>We, us or our     </i> means Waka Online Limited, company number 8560729     </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>Website     </i> means <i>[wakaonline.nz, wakaonline.co.nz and wakaonline.ltd] </i>   </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}><i>You     </i> means you or, if clause 1.1b applies, both you and the other person on whose behalf you are acting    </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>3.2	In these Terms:
            </p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>clause and other headings are for ease of reference only and do not affect the interpretation of these Terms;</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>words in the singular include the plural and vice versa; and</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>a reference to:
                <ol>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>i	a person includes an individual, a body corporate, an association of persons (whether corporate or not), a trust, a government department, or any other entity;</li>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>including and similar words do not imply any limit; and </li>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>a statute includes references to regulations, orders or notices made under or in connection with the statute or regulations and all amendments, replacements or other changes to any of them.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}> ABOUT WAKA ONLINE </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>4.1	Waka Online is an online marketplace that allows users to search for, create profiles of, and buy and sell, motor vehicles.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>4.2	We are a car market operator (for the purposes of the MVSA).  We act as an intermediary for Waka Online users to transact and are not a party to any agreement to buy, sell or provide the vehicles advertised on Waka Online.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>4.3	We are not a Motor Vehicle Trader nor are we an auctioneer or broker.  We do not own any of the vehicles on Waka Online and we do not offer them for sale.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>4.4	We have no control over any aspect of any vehicle listed on Waka Online nor do we have any control over the accuracy or truth of any Listings or other information provided by the Seller. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>4.5	Sellers can accept or decline offers from Buyers at their discretion.  When a Buyer agrees to purchase a vehicle and a Seller agrees to sell a vehicle, they are entering into an agreement directly with each other.  We cannot ensure that a Seller is entitled to sell a vehicle or that a Buyer or Seller completes a sale transaction. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>4.6	Any interaction between you and another Waka Online user, including any agreement to buy or sell a vehicle, is a matter directly between you and them only.  Other than our obligations set out in these Terms, we are not liable to you for any failure by any Waka Online user to comply with these Terms or any other legal obligation. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>4.7	We will use reasonable efforts to provide Waka Online in accordance with these Terms and New Zealand law.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>4.8	Our provision of Waka Online to you is non-exclusive.  Nothing in these Terms prevents us from providing Waka Online to any other person.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>4.9	Subject to clause 4.10, we will use reasonable efforts to ensure Waka Online is available on a 24/7 basis.  However, it is possible that on occasion Waka Online may be unavailable to permit maintenance or other development activity to take place, or due to an event that is beyond our reasonable control.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>4.10	Through the use of web services and APIs, Waka Online may interact with a range of third party service features.  We do not make any warranty or representation on the availability or performance of those features.  Without limiting the previous sentence, if a third party feature provider ceases to provide that feature or ceases to make that feature available on reasonable terms, we may cease to make available that feature to you. To avoid doubt, if we exercise our right to cease the availability of a third party feature, you are not entitled to any refund, discount or other compensation.</p>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}> USE OF WAKA ONLINE  </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>5.1	You must:</p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>use Waka Online in accordance with these Terms solely for lawful purposes (including complying with the MVSA (if applicable) and Unsolicited Electronic Messaging Act 2007); and</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>not resell or make Waka Online available to any third party, or otherwise commercially exploit Waka Online, other than to sell vehicles in accordance with these Terms.</li>
            </ol>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>5.2	You must provide true, current and complete information in your dealings with us (including in a Listing) and must promptly update that information as required so that the information remains true, current and complete.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>5.3	To create and access your Account, you are required to log in via a third party identity account (e.g. Facebook or Google).    </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>5.4	You must keep your Account secure and:</p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>not permit any other person to use your Account, including not allowing any other person to use the third party identity account that you use to access your Account; and </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>immediately notify us if you become aware of any unauthorised use of your Account, by sending an email to [insert email address].  </li>
            </ol>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>5.5	You must obtain our written permission to establish a link to Waka Online on your own or a third party website.  If you wish to do so, email your request to <i>[insert email address]</i>. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>5.6	When accessing and using Waka Online, you must:</p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>not permit any other person to use your Account, including not allowing any other person to use the third party identity account that you use to access your Account; and </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>not attempt to undermine the security or integrity of the Underlying Systems; </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>not use, or misuse, Waka Online in any way which may impair the functionality of the Underlying Systems or impair the ability of any other user to use Waka Online;</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>not attempt to view, access or copy any material or data other than:
                <ol>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>that which you are authorised to access; and</li>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>to the extent necessary for you to use Waka Online in accordance with these Terms; </li>
                </ol>
              </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>neither use Waka Online, nor transmit, input or store any Content, that breaches any third party right (including Intellectual Property Rights and privacy rights) or is Objectionable, incorrect or misleading; and</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>f	unless with our agreement, access Waka Online via standard web browsers and not by any other method.  Other methods include scraping, deep-linking, harvesting, data mining, use of a robot or spider, automation, or any similar data gathering, extraction, monitoring or input method.   </li>
            </ol>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>5.7	You are responsible for procuring all licences, authorisations and consents required for you to access and use Waka Online, including to use, store and input Content into, and display Content using, Waka Online. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>5.8	You indemnify us against all Loss we suffer or incur as a direct or indirect result of:</p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>any actual or alleged claim by a third party that any Content infringes the rights of that third party (including Intellectual Property Rights and privacy rights) or that the Content is Objectionable, incorrect or misleading; </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>your failure to comply with these Terms, including any failure of a person who accesses and uses Waka Online using your Account; or </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>any Loss arising from or in connection with an actual or alleged breach by you of any legal or regulatory requirements which occurs in connection with your Listing or as a result of any other relationship established through Waka Online.</li>
            </ol>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}> ADDITIONAL TERMS FOR SELLERS   </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>6.1	This clause 6 applies to Sellers. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>6.2	All descriptions and information in a Listing must be accurate, complete, up-to-date and truthful to the best of your knowledge and belief. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>6.3	You must: </p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }} >not list a vehicle that is not safe to drive; </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>only use a Listing to profile or advertise a specific vehicle, and not multiple or generic vehicles; </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>not reuse or edit a Listing to profile or advertise a different vehicle; and </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>d	not attempt to manipulate search results or artificially improve the search position of a Listing.   </li>
            </ol>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>6.4	On creating a Sale Listing, you:</p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>must provide accurate information on the vehicle offered (including whether there is any fault or damage to the vehicle);  </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>must provide any additional terms and conditions of sale that apply to the vehicle offered;  </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>must not include any Content that breaches any third party right (including Intellectual Property Rights and privacy rights) or is Objectionable, incorrect or misleading; </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>warrant that:
                <ol>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>you are the owner of the vehicle in the Sale Listing or that you have authority to create the Sale Listing and enter into an agreement for sale of the vehicle; and </li>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>the vehicle has a current licence (registration) or, if it does not, you will inform the Buyer that it is unregistered before the Buyer commits to buy the vehicle; and </li>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>iii	the vehicle has a warrant of fitness or (if applicable) certificate of fitness, in each case less than one month old or, if it does not, you will inform the Buyer before the Buyer commits to buy the vehicle.</li>
                </ol>
              </li>
            </ol>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>6.5	If a vehicle becomes unavailable for sale for any reason, you agree to remove or suspend the Listing as soon as practicable.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>6.6	You acknowledge and agree that you are responsible for all Listings that you post on Waka Online.  Accordingly, you represent and warrant that any Listing you post (and any subsequent sale of a vehicle by you) will:</p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>comply with any agreements you have entered into with any third parties;  </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>comply with all applicable laws; and</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>not conflict with the rights of third parties.  </li>
            </ol>
            <p className="pri_para" style={{ color: "black" }}>6.7	You must promptly provide to us any evidence we request of your compliance with the terms of this clause 6 and (if applicable) clause 7.</p>
          </li>

          <li style={{ color: "#b91d1d" }}>
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}> ADDITIONAL TERMS FOR MOTOR VEHICLE TRADERS</h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>7.1	If you are a Motor Vehicle Trader, this clause 7 applies, in addition to clause 6. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>7.2	You agree to us searching the Motor Vehicle Traders Register to verify your status as a registered Motor Vehicle Trader.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>7.3	If you are a Motor Vehicle Trader offering or displaying a used motor vehicle for sale to users who are not registered Motor Vehicle Traders, you must comply with all of your obligations under the MVSA (including your obligations under sections 14 and 16) and the Energy Efficiency (Vehicle Energy Economy Labelling) Regulations 2007.  </p>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}>SALE TERMS </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>8.1	This clause 8 sets out standard terms that apply to the agreement for sale or purchase of a vehicle between the Seller and Buyer (Sale Contract).  Except as set out in clause 8.4, the Buyer and Seller may agree alternative or additional terms to apply to the Sale Contract.    </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>8.2	The Seller must transfer the vehicle to the Buyer with clear title, free of all security interests and other encumbrances, and with all charges and fees owing in connection with the vehicle fully paid.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>8.3	The Buyer and Seller must arrange handover of the vehicle in a prompt manner, unless there is an exceptional circumstance that prevents this.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>8.4	Where the Buyer is a consumer (as defined in the CGA), the Sale Contract must not contract out of, or purport to contract out of, the CGA or any other applicable consumer protection law that cannot be excluded.  </p>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}>FEES AND PAYMENT </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>9.1	If you are a Seller, you must pay us the applicable Fees in advance of a Sale Listing being made live using the payment method set out on the Fees page at <a href="/vehiclePasses">vehicle passes</a> . </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>9.2	We may change our Fees from time to time.  If you want to know what our current Fees are at any time, you must visit our Fees page at <a href="/vehiclePasses">vehicle passes </a> . </p>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}>CONTENT</h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>10.1	Title to, and all Intellectual Property Rights in, the Content (as between the parties) remains your property.  You grant us a worldwide, non-exclusive, fully paid up, transferable, irrevocable licence to use, store, copy, modify, make available and communicate the Content for any purpose in connection with the exercise of our rights and performance of our obligations in accordance with these Terms.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>10.2	Without limiting clause 10.1, you acknowledge that we may use Content for our internal research, analytical and product development purposes, to conduct statistical analysis and identify trends and insights (on an anonymised and aggregated basis) and for our internal reporting requirements (and these rights will survive termination and expiration of these Terms).</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>10.3	You must ensure you have obtained all necessary consents for us to access, collect, hold, process and distribute the Content as described in these Terms.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>10.4	While we will use reasonable endeavours to back up all Content stored using Waka Online, you must keep separate and regular back-up copies of all Content uploaded by you onto Waka Online. </p>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}>OUR INTELLECTUAL PROPERTY</h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>11.1	Other than your Content, we (and our licensors) own all proprietary and intellectual property rights in Waka Online and the Underlying Systems, including all information, data, text, graphics, artwork, photographs, trade marks, logos, icons, sound recordings, videos and look and feel, and including any modification, enhancement or derivative work of any of the foregoing.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>11.2	If you provide us with ideas, comments or suggestions relating to Waka Online or Underlying Systems (together <b>feedback</b>):</p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>all Intellectual Property Rights in that feedback, and anything created as a result of that feedback (including new material, enhancements, modifications or derivative works), are owned solely by us; and</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>we may use or disclose the feedback for any purpose.</li>
            </ol>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}>CONFIDENTIALITY </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>12.1	You must treat all information available and otherwise provided through Waka Online as strictly confidential, and may only use that information for the purpose of buying, selling and providing vehicles through Waka Online.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>12.2	Confidential Information expressly includes contact details of each Waka Online user, but does not include any information already in the public domain, or independently known to you.</p>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}>PRIVACY </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>13.1	You are not required to provide personal information to us, although in some cases if you choose to not do so then we will be unable to make certain functions of Waka Online unavailable to you. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>13.2	When you provide personal information to us, we will comply with the New Zealand Privacy Act 2020 and with our privacy policy set out at <a href="/privacyPolicy" target="_blank">privacy policy</a> .</p>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}>DISCLAIMERS </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>14.1	To the extent permitted by law, we have no liability or responsibility to you or any other person for any Loss in connection with: </p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>any Listing; </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>any sale or purchase of a vehicle through Waka Online or any other relationship established through Waka Online or a Listing; </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>Waka Online being unavailable (in whole or in part) or performing slowly;</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>the failure of any Waka Online user to comply with these Terms;  </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>any error in, or omission from, any information made available through Waka Online; </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>any exposure to viruses or other forms of interference which may damage your computer system or expose you to fraud when you access or use Waka Online.  To avoid doubt, you are responsible for ensuring the process by which you access and use Waka Online protects you from this; and</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>any site linked from Waka Online.  Any link on Waka Online to other sites does not imply any endorsement, approval or recommendation of, or responsibility for, those sites or their contents, operations, products or operators. </li>
            </ol>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>14.2	All advertisements and all sales and purchases of vehicles through Waka Online are carried out entirely at your own risk.  We exclude to the fullest extent permitted by applicable law all liability for any Loss arising out of or in any way connected with your use of Waka Online. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>14.3	Without limiting clause 14.2: </p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>you rely on information provided by other Waka Online users at your own risk;</li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>you acknowledge we do not, control, inspect, endorse, approve or check the availability, condition or nature of any advertised vehicles or the accuracy, currency, truth or completeness of the information provided by Waka Online users and it is your responsibility to do so; and </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>it is your responsibility to take any other necessary precautions before entering an agreement to buy or sell vehicles. </li>
            </ol>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}>LIABILITY </h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}> 15.1	To the maximum extent permitted by law:</p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>you access and use Waka Online at your own risk; and </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>we are not liable or responsible to you or any other person for any Loss under these Terms or in connection with Waka Online, or your access and use of (or inability to access or use) Waka Online.  This exclusion applies regardless of whether our liability or responsibility arises in contract, tort (including negligence), equity, breach of statutory duty, or otherwise.</li>
            </ol>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>15.2	Except to the extent permitted by law, nothing in these Terms has the effect of contracting out of the CGA or any other consumer protection law that cannot be excluded.  To the extent our liability cannot be excluded but can be limited, our liability will be limited to NZD9.00.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>15.3	To the maximum extent permitted by law and only to the extent clauses 15.1 and 15.2 of these Terms do not apply:  </p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>our total liability to you in connection with these Terms or Waka Online will not exceed NZD9.00; and </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>we will not be liable to you under or in connection with these Terms or Waka Online for any:
                <ol>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>loss of profit, revenue, savings, business, use, data (including Content), and/or goodwill; or</li>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>consequential, indirect, incidental or special damage or loss of any kind. </li>
                </ol>
              </li>
            </ol>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>15.4	Clauses 15.1 to 15.3 do not apply to limit:</p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>our liability under or in connection with these Terms:
                <ol>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>for personal injury or death; or</li>
                  <li type='i' style={{ fontFamily: 'Montserrat, sans-serif' }}>for fraud or wilful misconduct; or</li>
                </ol>
              </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>any liability that cannot be excluded by law.</li>
            </ol>
          </li>

          <li className="tc_list" style={{ fontFamily: 'Montserrat, sans-serif' }}>
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}> SUSPENSION AND TERMINATION</h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>16.1	Unless terminated under this clause 16, these Terms and your right to access and use Waka Online continues until a party gives 30 days’ notice that these Terms and your access to and use of Waka Online will terminate on the expiry of that notice.  If these Terms and your right to access and use Waka Online are terminated under this clause, we will close your Account on the effective date of termination. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>16.2	You may cease using Waka Online at any time by closing your Account with Waka Online.  If you do this, these Terms and your right to access and use Waka Online will terminate immediately.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>16.3	Termination of these Terms does not affect either party’s rights and obligations that accrued before that termination.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>16.4	No compensation is payable by us to you as a result of termination of these Terms for whatever reason, and you will not be entitled to a refund of any amount that you have already paid to us.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>16.5	Without limiting any other right or remedy available, we may restrict or suspend your access to Waka Online and remove Listings, and suspend or remove your Account, if we consider you have: </p>
            <ol className='tc_ol'>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>undermined, or attempted to undermine, the security or integrity of Waka Online or any Underlying Systems; </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>used, or attempted to use, Waka Online for improper purposes (including failure to comply with the MVSA or Unsolicited Electronic Messaging Act 2007) or in a manner, other than for normal operational purposes, that materially reduces the operational performance of Waka Online; </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>transmitted or stored any Content that breaches or may breach these Terms or any third party right (including Intellectual Property Rights and privacy rights), or that is, or may be, Objectionable, incorrect or misleading; or  </li>
              <li type='a' className='tc_li' style={{ fontFamily: 'Montserrat, sans-serif' }}>otherwise breached these Terms or our Privacy Policy. </li>
            </ol>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>16.6	In addition to clause 16.5, if a sale or purchase is not completed, we may suspend or remove your Account and any of your live Listings at our sole discretion.  You may request that your Account and Listings be reactivated, which we may do at our sole discretion.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>16.7	Clauses which, by their nature, are intended to survive termination of your right to access and use Waka Online, including clauses 10 to 15, 16.3, 16.4 and 17.2, continue in force.</p>
          </li>

          <li className="tc_list">
            <h4 className='tc_subheading' style={{ color: "#b91d1d", fontFamily: 'Montserrat, sans-serif' }}> GENERAL</h4>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>17.1	If we need to contact you, we may do so by email or by posting a notice on Waka Online.  You agree that this satisfies all legal requirements in relation to written communications.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>17.2	[All notices and communications by you to us under or in connection with these Terms must be in writing and be sent by email to the email address set out below (or at such other address as we may notify to you from time to time).  A notice sent by email will not be validly given if, within 24 hours after the email is sent, a return email is received by the sender stating that the addressee’s email address is wrong or that the message cannot be delivered.
              <br /> Email address: 			contact@wakaonline.nz  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>17.3	These Terms, and any dispute relating to these Terms or Waka Online, are governed by and must be interpreted in accordance with the laws of New Zealand.  Each party submits to the non-exclusive jurisdiction of the Courts of New Zealand in relation to any dispute connected with these Terms or Waka Online.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>17.4	We are not liable to you for any failure to perform our obligations under these Terms to the extent caused by an event that is beyond our reasonable control.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>17.5	You may not assign, novate, subcontract or transfer any right or obligation under these Terms without our prior written consent, that consent not to be unreasonably withheld.  You remain liable for your obligations under these Terms despite any approved assignment, subcontracting or transfer.  </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>17.6	No person other than us and you has any right to a benefit under, or to enforce, these Terms.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>17.7	Subject to clause 2.1, any variation to these Terms must be in writing and signed by both parties. </p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>17.8	For us to waive a right under these Terms, that waiver must be in writing and signed by us.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>17.9	If any part or provision of these Terms is or becomes illegal, unenforceable, or invalid, that part or provision is deemed to be modified to the extent required to remedy the illegality, unenforceability or invalidity.  If a modification is not possible, the part or provision must be treated for all purposes as severed from these Terms.  The remainder of these Terms will be binding on you.</p>
            <p className="pri_para" style={{ color: "black", fontFamily: 'Montserrat, sans-serif' }}>17.10	These Terms set out everything relating to your access and use of Waka Online and supersede and cancel anything discussed, exchanged or agreed prior to you agreeing to these Terms. The parties have not relied on any representation, warranty or agreement relating to Waka Online that is not expressly set out in the Terms, and no such representation, warranty or agreement has any effect from the date you agree to these Terms.  Without limiting the previous sentence, if you use Waka Online for the purposes of a business, the parties agree that sections 9, 12A and 13 of the Fair Trading Act 1986 do not apply, and that it is fair and reasonable that the parties are bound by this clause 17.10.</p>

          </li>
        </ol>


      </div>
    </div>
  );


};

export default TermsConditions;
