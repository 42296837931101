import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/CSS/Footer.css';

function Footer() {
  return (
    <>
      <div className='footer'>
        <div className="container-fluid">
          <div className="row">
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <p className='footer-paragraph my-auto'><span><Link className='footer-pages' to="/FAQs">FAQ</Link> |</span><span><Link className='footer-pages' to="/privacyPolicy"> Privacy Policy</Link> |</span> <span><Link className='footer-pages' to="/termsConditions">Terms and Conditions</Link></span></p>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <p className='footer-paragraph footer-copyright my-auto'>© 2023 Copyright: Waka Online Limited | 100% Owned by Kiwis</p>
            </div>
          </div>
        </div>
      </div>

    </>

  );
}

export default Footer;
