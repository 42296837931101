import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Legend } from 'chart.js';
import axios from 'axios';
import { TimestampToDate } from '../../utils/DateAndTimeUtils';
import '../CSS/Details.css';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import BarChartIcon from '@mui/icons-material/BarChart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Legend);

export default function CarPrice({ vin }) {
  const [vehicleValuationData, setVehicleValuationData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const valuationResponse = await axios.get(`${process.env.REACT_APP_API_COMMON}vehicle-value/${vin}`);
        setVehicleValuationData(valuationResponse.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (vin) {
      fetchData();
    }
  }, [vin]);

  const reportedDate = TimestampToDate(vehicleValuationData.UpdatedAt);
  const bstYear = vehicleValuationData.BestTimeToSell?.btsYear;
  const sample = vehicleValuationData.BestTimeToSell?.values;

  const transformData = (data) => {
    const transformedData = [];

    if (data) {
      for (let obj of data) {
        const key = Object.keys(obj)[0];
        const value = Object.values(obj)[0];

        const newObj = { year: key, value: value };
        transformedData.push(newObj);
      }
    }
    return transformedData;
  };

  const transformedData = transformData(sample);

  if (!vehicleValuationData.BestTimeToSell) {
    return null;
  }
  const valueForBstYear = transformedData.find(item => item.year === bstYear.toString())?.value;

  var data = {
    labels: transformedData.slice(0)
      .map((x) => x.year),
    datasets: [
      {
        label: 'Estimated Depreciation',
        fill: true,
        data: transformedData.slice(0)
          .map((x) => x.value),
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(255, 0, 0, 1)'],
        pointBackgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderWidth: 3,
      },
      {
        type: 'line',
        label: 'Best Time To Sell',
        data: transformedData.map(() => valueForBstYear),
        borderColor: 'rgba(0, 0, 255, 0.7)',
        borderWidth: 3,
        borderDash: [4, 4],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6 sm-6 xs-6" >
          <div className="avoid-breakInside">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="A visual representation (chart) illustrating the estimated valuation of the vehicle over time." arrow>
                <IconButton><BarChartIcon /></IconButton>
              </Tooltip>
              <h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>Estimated Valuation: {vehicleValuationData.ResidualValue.avg}.00</h4>
            </div>
            <hr />
            <p className="date" style={{ fontSize: "12px", color: "gray", marginBottom: "0.2px" }}>
              Reported Date - {reportedDate}
            </p>
            <p className="date" style={{ fontSize: "12px", color: "gray", marginBottom: "0.2px" }}>
              Source - CarPrice
            </p>
            <div className='odometergraph' style={{ height: '500px' }}>
              <Line data={data} height={500} options={options} style={{ height: '300px' }} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 sm-6 xs-6">
          <div className="row avoid-breakInside">
            <div className="col-12 col-md-12 sm-12 xs-12">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Comprehensive information about the vehicle, including make, model, year, and other key details" arrow>
                  <IconButton><InfoIcon /></IconButton>
                </Tooltip>
                <h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }}>Vehicle Information</h4>
              </div>
              <hr />
              <>
                <div className="text-cell">
                  <div className="part1">Make</div>
                  <div className="part2">{vehicleValuationData.Make || 'N/A'}</div>
                </div>
                <div className="text-cell">
                  <div className="part1">Model</div>
                  <div className="part2">{vehicleValuationData.model || 'N/A'}</div>
                </div>
                <div className="text-cell">
                  <div className="part1">Year</div>
                  <div className="part2">{vehicleValuationData.Year || 'N/A'}</div>
                </div>
                <div className="text-cell">
                  <div className="part1">Fuel</div>
                  <div className="part2">{vehicleValuationData.FuelType || 'N/A'}</div>
                </div>
                <div className="text-cell">
                  <div className="part1">Engine Size</div>
                  <div className="part2">{vehicleValuationData.EngineSize || 'N/A'}</div>
                </div>
                <div className="text-cell">
                  <div className="part1">Exterior Colour</div>
                  <div className="part2">{vehicleValuationData.ExteriorColour || 'N/A'}</div>
                </div>
                <div className="text-cell">
                  <div className="part1">Transmission</div>
                  <div className="part2">{vehicleValuationData.Transmission || 'N/A'}</div>
                </div>
                <div className="text-cell">
                  <div className="part1">Body Type</div>
                  <div className="part2">{vehicleValuationData.BodyType || 'N/A'}</div>
                </div>
                <div className="text-cell">
                  <div className="part1">Fuel Consumption</div>
                  <div className="part2">{vehicleValuationData.FuelConsumption || 'N/A'}</div>
                </div>
                <div className="text-cell">
                  <div className="part1">Safety Stars</div>
                  <div className="part2">{vehicleValuationData.SafetyStars || 'N/A'}</div>
                </div>
              </>
            </div>
          </div>
          <div className="row avoid-breakInside">
            <div className="col-12 col-md-12 sm-12 xs-12">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="The expected range of sales prices for the vehicle based on various factors, providing insights into potential pricing scenarios." arrow>
                  <IconButton><MonetizationOnIcon /></IconButton>
                </Tooltip>
                <h4 className='details_fc' style={{ margin: '0', marginLeft: '2px' }} >Estimated Sales Range</h4>
              </div>
              <hr />
              <>
                <table>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left' }}>Range</th>
                      <th style={{ textAlign: 'left' }}>Price</th>
                      <th style={{ textAlign: 'left' }}>Confidence Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left' }}>Low</td>
                      <td style={{ textAlign: 'left' }}>{vehicleValuationData.ResidualValue.low}.00</td>
                      <td style={{ textAlign: 'left' }}>{vehicleValuationData.ResidualValue.confidence}%</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left' }}>Average</td>
                      <td style={{ textAlign: 'left' }}>{vehicleValuationData.ResidualValue.avg}.00</td>
                      <td style={{ textAlign: 'left' }}>{vehicleValuationData.ResidualValue.confidence}%</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left' }}>High</td>
                      <td style={{ textAlign: 'left' }}>{vehicleValuationData.ResidualValue.hgh}.00</td>
                      <td style={{ textAlign: 'left' }}>{vehicleValuationData.ResidualValue.confidence}%</td>
                    </tr>
                  </tbody>
                </table>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
