import React from 'react';
import MultiSearch from '../components/MultiSearch';

function AdvancedSearch() {
  return (
    <>
      <MultiSearch />
    </>
  );
}

export default AdvancedSearch;
