const redirectUrls = process.env.REACT_APP_URL.split(',');

const redirectUrl = redirectUrls.find((url) => url.includes(window.location.hostname));

const awsConfig = {
  Auth: {
    identityPoolId: `${process.env.REACT_APP_IDP_ID}`,
    region: `${process.env.REACT_APP_REGION}`,
    userPoolId: `${process.env.REACT_APP_UP_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_UPWC_ID}`,
    oauth: {
      domain: `${process.env.REACT_APP_DOMAIN}`,
      scope: ['email', 'openid'],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

export default awsConfig;