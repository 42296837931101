import React, { useState, useEffect, useContext, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Spinner, ButtonSpinner } from '../components/Spinner';
import { jwtToken } from '../Context/DataContext';
import { UserContext } from '../Context/UserContext';
import { GlobalContext } from '../Context/GlobalContext';
import { Button } from 'react-bootstrap';
import { TimestampToDate, CurrentTimestampToDate, CurrentTimestampToDateWithSeconds, TimestampToDateWithSeconds, checkIsYesterday } from '../utils/DateAndTimeUtils';
import swal from '@sweetalert/with-react';
import { Auth } from 'aws-amplify';
import { clearDetailsLocalStorage } from './../utils/LocalStorageUtils';
import { Card, CardContent, CardActionArea, CardMedia, Typography, Grid } from '@mui/material';
import AOT_LOGO from '../assets/waka-logo/AOT_LOGO_GrayScale.png';
import infoLogo from '../assets/img/about.svg';
import { Popover } from '@mui/material';
import '../assets/CSS/Account.css';

const Account = () => {
  const { userID } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const history = useHistory();
  const [expiredPersonalPasses, setExpiredPersonalPasses] = useState([]);
  const [usedPersonalPasses, setUsedPersonalPasses] = useState([]);
  const [availablePersonalPasses, setAvailablePersonalPasses] = useState([]);
  const [expiredDealerPasses, setExpiredDealerPasses] = useState([]);
  const [usedDealerPasses, setUsedDealerPasses] = useState([]);
  const [availableDealerPasses, setAvailableDealerPasses] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [closeAccLoading, setCloseAccLoading] = useState(false);
  const [unclaimLoading, setUnclaimLoading] = useState('');
  const [vehicles, setVehicles] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [NotListedPop, setNotListedPop] = useState('');
  const searchResultsRef = useRef(null);
  const { activeClaims, inactiveClaims, expiredClaims, setClaims, activeDealerClaims, inactiveDealerClaims, expiredDealerClaims, setDealerClaims, setIndividualClaims } = useContext(GlobalContext);

  useEffect(() => {
    if (token) {
      const fetchPassess = async () => {
        setInitialLoading(true);
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_COMMON}paymentCheck`,
            { userID }, { headers: { Authorization: `Bearer ${token}` } });

          const { personalPasses, dealerPasses } = response.data;

          if (personalPasses.length > 0) {
            const expiredPasses = [];
            const usedPasses = [];
            const availablePasses = [];
            personalPasses.forEach((payment) => {
              if (payment.Status === 'Expired') {
                expiredPasses.push(payment);
              } else if (payment.Status === 'Active' && payment.AvailablePasses === 0) {
                usedPasses.push(payment);
              } else if (payment.Status === 'Active' && payment.AvailablePasses > 0) {
                availablePasses.push(payment);
              }
            });
            setExpiredPersonalPasses(expiredPasses);
            setUsedPersonalPasses(usedPasses);
            setAvailablePersonalPasses(availablePasses);
          }
          if (dealerPasses.length > 0) {
            const expiredPasses = [];
            const usedPasses = [];
            const availablePasses = [];
            dealerPasses.forEach((payment) => {
              if (payment.Status === 'Expired') {
                expiredPasses.push(payment);
              } else if (payment.Status === 'Active' && payment.AvailablePasses === 0) {
                usedPasses.push(payment);
              } else if (payment.Status === 'Active' && payment.AvailablePasses > 0) {
                availablePasses.push(payment);
              }
            });
            setExpiredDealerPasses(expiredPasses);
            setUsedDealerPasses(usedPasses);
            setAvailableDealerPasses(availablePasses);
          }
          const claimData = await axios.post(
            `${process.env.REACT_APP_API_COMMON}getClaims`, { userID },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setClaims(claimData.data);

          const watchListData = await axios.post(
            `${process.env.REACT_APP_API_COMMON}getWatchListings`, { userID },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const { vehicles, totalResults, totalPages } = watchListData.data;
          console.log(vehicles);
          setVehicles(vehicles);
          setTotalPages(totalPages);

          setInitialLoading(false);
        } catch (error) {
          setInitialLoading(false);
        }
      };
      fetchPassess();
    }
  }, [token, userID]);

  function unclaimVehicle(claim, type) {
    const claimID = claim.ClaimID;
    swal({
      title: 'Unclaim Vehicle!',
      content: (
        <div>
          <span>
            Are you sure you are going to unclaim this vehicle that you have previously claimed? Please refer the <a href="/termsConditions" target="_blank">terms and conditions</a>
            and make sure you understand the implications of unclaiming it, unless you are no longer the registered person/company of this vehicle with <a href="https://www.nzta.govt.nz/" target="_blank" rel="noopener noreferrer">NZTA</a>
          </span>
        </div>
      ),
      icon: 'info',
      buttons: {
        cancel: 'No',
        defeat: {
          text: 'Yes',
          className: 'request-button'
        }
      },
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setUnclaimLoading(claimID);
          try {
            const unclaimResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}unclaim`, { userID, claimID },
              { headers: { Authorization: `Bearer ${token}` } });
            if (unclaimResponse.status === 200) {
              if (type === "dealer") {
                setDealerClaims(prevItems => {
                  const index = prevItems.findIndex(item => item.ClaimID === claimID);
                  if (index !== -1) {
                    const updatedItems = [...prevItems];
                    updatedItems[index] = unclaimResponse.data;
                    return updatedItems;
                  }
                  return prevItems;
                });
              } else {
                setIndividualClaims(prevItems => {
                  const index = prevItems.findIndex(item => item.ClaimID === claimID);
                  if (index !== -1) {
                    const updatedItems = [...prevItems];
                    updatedItems[index] = unclaimResponse.data;
                    return updatedItems;
                  }
                  return prevItems;
                });
              }
              swal({
                text: `The vehicle ${claim.PlateNumber || claim.VIN} unclaimed successfully!`,
                icon: 'success',
                dangerMode: true,
              });
            }
          } catch (error) {
            if (error.response.status === 409) {
              swal({
                title: 'Failed to unclaim the vehicle!',
                text: 'You have already accepted an offer for your vehicle. In order to unclaim this vehicle, please wait for the buyer to complete the purchase or for the accepted offer to expire.',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                text: 'Failed to unclaim the vehicle!',
                icon: 'error',
                dangerMode: true,
              });
            }
          } finally {
            setUnclaimLoading('');
          }
        }
      });
  }

  function closeAccount() {
    swal({
      title: 'Close Account!',
      content: (
        <div>
          <span>
            By closing the account you accept the following sections of the <a href="/termsConditions" target="_blank">terms and conditions</a>.<br />
            If you do this, these Terms and your right to access and use Waka Online will terminate immediately.<br />
            No compensation is payable by us to you as a result of termination of these Terms for whatever reason, and you will not be entitled to a refund of any amount that you have already paid to us.
          </span>
        </div>
      ),
      icon: 'info',
      buttons: {
        cancel: 'No',
        defeat: {
          text: 'Yes',
          className: 'request-button'
        }
      },
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setCloseAccLoading(true);
          try {
            const closeAccResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}closeaccount`, { userID },
              { headers: { Authorization: `Bearer ${token}` } });
            if (closeAccResponse.status === 200) {
              swal({
                text: 'Account closed successfully!',
                icon: 'success',
                dangerMode: true,
              }).then(async () => {
                try {
                  setInitialLoading(true);
                  clearDetailsLocalStorage();
                  await Auth.signOut();
                  setInitialLoading(false);
                } catch (error) {
                  swal({
                    text: 'Failed to sign out',
                    icon: 'error',
                    dangerMode: true,
                  });
                }
              });
            }
          } catch (error) {
            if (error.response.status === 409) {
              swal({
                title: 'Failed to close the account!',
                text: 'You have already accepted one or more offers for your vehicles. In order to close your account, please wait for the buyer(s) to complete the purchase or for the accepted offer(s) to expire.',
                icon: 'error',
                dangerMode: true,
              });
            } else {
              swal({
                text: 'Failed to close the account!',
                icon: 'error',
                dangerMode: true,
              });
            }
          } finally {
            setCloseAccLoading(false);
          }
        }
      });
  }

  const arraysAreEmpty = [
    activeClaims,
    activeDealerClaims,
    expiredClaims,
    expiredDealerClaims,
    inactiveClaims,
    inactiveDealerClaims
  ].every(arr => arr.length === 0);

  const isPassesEmpty = [
    availablePersonalPasses,
    usedPersonalPasses,
    expiredPersonalPasses,
    availableDealerPasses,
    usedDealerPasses,
    expiredDealerPasses
  ].every(arr => arr.length === 0);


  return (
    <div className='p-spacing'>
      {initialLoading && !closeAccLoading && !unclaimLoading ? (<Spinner></Spinner>) : (
        <div className="App  m-spacing" style={{ minHeight: '100%', position: 'relative' }}>
          <div className="container">
            {!arraysAreEmpty && (
              <h3 className="claim-topic-color">Claims!</h3>
            )}
            <div>
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                {activeClaims && activeClaims.length > 0 && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Active personal  Claims</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-container">
                        <table className="table align-middle mb-0 mt-2 bg-white">
                          <thead className="bg-light">
                            <tr>
                              <th style={{ width: '16%' }}>Plate Number/VIN</th>
                              <th style={{ width: '16%' }}>Claimed Date</th>
                              <th style={{ width: '16%' }}>Expire Date</th>
                              <th style={{ width: '16%' }}>Location</th>
                              <th style={{ width: '16%' }}>Type</th>
                              <th style={{ width: '20%' }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {activeClaims
                              .map((claim, index) => (
                                <tr key={index}>
                                  <td>
                                    <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Location}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Type}</p>
                                  </td>
                                  <td className="d-flex justify-content-between align-items-center">
                                    <Button
                                      id="reject-btn"
                                      variant="danger"
                                      className='me-3'
                                      disabled={unclaimLoading}
                                      onClick={() => unclaimVehicle(claim, "individual")}
                                    >{unclaimLoading === claim.ClaimID ? (
                                      <ButtonSpinner />
                                    ) : (
                                      'Unclaim'
                                    )}
                                    </Button>
                                    <Button
                                      id="accept-btn"
                                      variant="success"
                                      className='me-3'
                                      onClick={() => history.push(`/details`, { plateNumberOrVIN: claim.PlateNumber || claim.VIN })}
                                      style={{ marginLeft: '10px' }}
                                    >
                                      View
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {expiredClaims && expiredClaims.length > 0 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Expired Personal Claims</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-container">
                        <table className="table align-middle mb-0 mt-2 bg-white">
                          <thead className="bg-light">
                            <tr>
                              <th style={{ width: '16%' }}>Plate Number/VIN</th>
                              <th style={{ width: '16%' }}>Claimed Date</th>
                              <th style={{ width: '16%' }}>Expire Date</th>
                              <th style={{ width: '16%' }}>Location</th>
                              <th style={{ width: '16%' }}>Type</th>
                              <th style={{ width: '20%' }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {expiredClaims
                              .map((claim, index) => (
                                <tr key={index}>
                                  <td>
                                    <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Location}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Type}</p>
                                  </td>
                                  <td className="">
                                    <div className="flex">
                                      <Button
                                        id="accept-btn"
                                        variant="success"
                                        className='me-3'
                                        onClick={() => history.push(`/details`, { plateNumberOrVIN: claim.PlateNumber || claim.VIN })}
                                      >
                                        View
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {inactiveClaims && inactiveClaims.length > 0 && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Inactive Personal Claims</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-container">
                        <table className="table align-middle mb-0 mt-2 bg-white">
                          <thead className="bg-light">
                            <tr>
                              <th>Plate Number/VIN</th>
                              <th>Claimed Date</th>
                              <th>Expire Date</th>
                              <th>Location</th>
                              <th>Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inactiveClaims
                              .map((claim, index) => (
                                <tr key={index}>
                                  <td>
                                    <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Location}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Type}</p>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </div>
            <br />
            <div>
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                {activeDealerClaims && activeDealerClaims.length > 0 && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Active Dealer Claims</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-container">
                        <table className="table align-middle mb-0 mt-2 bg-white">
                          <thead className="bg-light">
                            <tr>
                              <th style={{ width: '16%' }}>Plate Number/VIN</th>
                              <th style={{ width: '16%' }}>Claimed Date</th>
                              <th style={{ width: '16%' }}>Expire Date</th>
                              <th style={{ width: '16%' }}>Location</th>
                              <th style={{ width: '16%' }}>Type</th>
                              <th style={{ width: '20%' }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {activeDealerClaims
                              .map((claim, index) => (
                                <tr key={index}>
                                  <td>
                                    <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Location}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Type}</p>
                                  </td>
                                  <td className="d-flex justify-content-between align-items-center">
                                    <Button
                                      id="reject-btn"
                                      variant="danger"
                                      className='me-3'
                                      disabled={unclaimLoading}
                                      onClick={() => unclaimVehicle(claim, "dealer")}
                                    >{unclaimLoading === claim.ClaimID ? (
                                      <ButtonSpinner />
                                    ) : (
                                      'Unclaim'
                                    )}
                                    </Button>
                                    <Button
                                      id="accept-btn"
                                      variant="success"
                                      className='me-3'
                                      onClick={() => history.push(`/details`, { plateNumberOrVIN: claim.PlateNumber || claim.VIN })}
                                      style={{ marginLeft: '10px' }}
                                    >
                                      View
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {expiredDealerClaims && expiredDealerClaims.length > 0 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Expired Dealer Claims</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-container">
                        <table className="table align-middle mb-0 mt-2 bg-white">
                          <thead className="bg-light">
                            <tr>
                              <th style={{ width: '16%' }}>Plate Number/VIN</th>
                              <th style={{ width: '16%' }}>Claimed Date</th>
                              <th style={{ width: '16%' }}>Expire Date</th>
                              <th style={{ width: '16%' }}>Location</th>
                              <th style={{ width: '16%' }}>Type</th>
                              <th style={{ width: '20%' }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {expiredDealerClaims
                              .map((claim, index) => (
                                <tr key={index}>
                                  <td>
                                    <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Location}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Type}</p>
                                  </td>
                                  <td className="">
                                    <div className="flex">
                                      <Button
                                        id="accept-btn"
                                        variant="success"
                                        className='me-3'
                                        onClick={() => history.push(`/details`, { plateNumberOrVIN: claim.PlateNumber || claim.VIN })}
                                      >
                                        View
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {inactiveDealerClaims && inactiveDealerClaims.length > 0 && (
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Inactive Dealer Claims</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-container">
                        <table className="table align-middle mb-0 mt-2 bg-white">
                          <thead className="bg-light">
                            <tr>
                              <th>Plate Number/VIN</th>
                              <th>Claimed Date</th>
                              <th>Expire Date</th>
                              <th>Location</th>
                              <th>Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inactiveDealerClaims
                              .map((claim, index) => (
                                <tr key={index}>
                                  <td>
                                    <p className="text-muted mb-0">{claim.PlateNumber || claim.VIN}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.CreatedAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(claim.ExpiresAt)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Location}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{claim.Type}</p>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </div>
            <br />
            <br />
            {!isPassesEmpty && (
              <>
                <h3 className="claim-topic-color">Passes!</h3>
                <div>
                  <Accordion defaultActiveKey={['0']} alwaysOpen>
                    {availablePersonalPasses.length > 0 && (
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Available Personal Passes</Accordion.Header>
                        <Accordion.Body>
                          <div className="table-container">
                            <table className="table align-middle mb-0 mt-2 bg-white">
                              <thead className="bg-light">
                                <tr>
                                  <th>Name</th>
                                  <th>Available Passes</th>
                                  <th>Used Passes</th>
                                  <th>Expiry Date</th>
                                  <th>Claim Validity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {availablePersonalPasses.map((availablePass, index) => (
                                  <tr key={index}>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{availablePass.PricingName}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{availablePass.AvailablePasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{availablePass.UsedPasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{TimestampToDate(availablePass.ExpiresAt)}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{availablePass.ClaimValidity || 90} days</p>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {usedPersonalPasses.length > 0 && (
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Used Personal Passes</Accordion.Header>
                        <Accordion.Body>
                          <div className="table-container">
                            <table className="table align-middle mb-0 mt-2 bg-white">
                              <thead className="bg-light">
                                <tr>
                                  <th>Name</th>
                                  <th>Available Passes</th>
                                  <th>Used Passes</th>
                                  <th>Expiry Date</th>
                                  <th>Claim Validity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {usedPersonalPasses.map((usedPasses, index) => (
                                  <tr key={index}>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{usedPasses.PricingName}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{usedPasses.AvailablePasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{usedPasses.UsedPasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{TimestampToDate(usedPasses.ExpiresAt)}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{usedPasses.ClaimValidity || 90} days</p>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {expiredPersonalPasses.length > 0 && (
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Expired Personal Passes</Accordion.Header>
                        <Accordion.Body>
                          <div className="table-container">
                            <table className="table align-middle mb-0 mt-2 bg-white">
                              <thead className="bg-light">
                                <tr>
                                  <th>Name</th>
                                  <th>Available Passes</th>
                                  <th>Used Passes</th>
                                  <th>Expired Date</th>
                                  <th>Claim Validity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {expiredPersonalPasses.map((expiredPasses, index) => (
                                  <tr key={index}>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{expiredPasses.PricingName}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{expiredPasses.AvailablePasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{expiredPasses.UsedPasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{TimestampToDate(expiredPasses.ExpiresAt)}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{expiredPasses.ClaimValidity || 90} days</p>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </Accordion>
                  <br />
                  <Accordion defaultActiveKey={['0']} alwaysOpen>
                    {availableDealerPasses.length > 0 && (
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Available Dealer Passes</Accordion.Header>
                        <Accordion.Body>
                          <div className="table-container">
                            <table className="table align-middle mb-0 mt-2 bg-white">
                              <thead className="bg-light">
                                <tr>
                                  <th>Name</th>
                                  <th>Available Passes</th>
                                  <th>Used Passes</th>
                                  <th>Expiry Date</th>
                                  <th>Claim Validity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {availableDealerPasses.map((availablePass, index) => (
                                  <tr key={index}>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{availablePass.PricingName}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{availablePass.AvailablePasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{availablePass.UsedPasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{TimestampToDate(availablePass.ExpiresAt)}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{availablePass.ClaimValidity || 90} days</p>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {usedDealerPasses.length > 0 && (
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Used Dealer Passes</Accordion.Header>
                        <Accordion.Body>
                          <div className="table-container">
                            <table className="table align-middle mb-0 mt-2 bg-white">
                              <thead className="bg-light">
                                <tr>
                                  <th>Name</th>
                                  <th>Available Passes</th>
                                  <th>Used Passes</th>
                                  <th>Expiry Date</th>
                                  <th>Claim Validity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {usedDealerPasses.map((usedPasses, index) => (
                                  <tr key={index}>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{usedPasses.PricingName}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{usedPasses.AvailablePasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{usedPasses.UsedPasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{TimestampToDate(usedPasses.ExpiresAt)}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{usedPasses.ClaimValidity || 90} days</p>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {expiredDealerPasses.length > 0 && (
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Expired Dealer Passes</Accordion.Header>
                        <Accordion.Body>
                          <div className="table-container">
                            <table className="table align-middle mb-0 mt-2 bg-white">
                              <thead className="bg-light">
                                <tr>
                                  <th>Name</th>
                                  <th>Available Passes</th>
                                  <th>Used Passes</th>
                                  <th>Expired Date</th>
                                  <th>Claim Validity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {expiredDealerPasses.map((expiredPasses, index) => (
                                  <tr key={index}>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{expiredPasses.PricingName}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{expiredPasses.AvailablePasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{expiredPasses.UsedPasses}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{TimestampToDate(expiredPasses.ExpiresAt)}</p>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <p className="text-muted mb-0">{expiredPasses.ClaimValidity || 90} days</p>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </Accordion>
                </div>
              </>
            )}

            {vehicles.length > 0 && (
              <>
                <br></br>
                <h3 className="claim-topic-color">Watched listings!</h3>
                <div>
                  <Accordion defaultActiveKey={["0"]} alwaysOpen>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Watch listings</Accordion.Header>
                      <Accordion.Body>
                        <div className="table-container">
                          <table className="table align-middle mb-0 mt-2 bg-white">
                            <thead className="bg-light">
                              <tr>
                                <th>Plate/VIN</th>
                                <th>Claimed date</th>
                                <th>Listed date</th>
                                <th>Location</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {vehicles.map((data, index) => (
                                <tr key={index}>
                                  <td>
                                    <p className="text-muted mb-0">{data.plateNumber || data.VIN}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{TimestampToDate(data.claimed)}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{data.listed ? TimestampToDate(data.listed) : ""}</p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">{data.location}</p>
                                  </td>
                                  <td className="">
                                    <div className="flex">
                                      <Button
                                        id="accept-btn"
                                        variant="success"
                                        className='me-3'
                                        onClick={() => history.push(`/details`, { plateNumberOrVIN: data.VIN })}
                                      >
                                        View
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h6 className="right-btn my-5">You need to purchase a new pass?</h6>
              <Button className="right-btn my-5" style={{ minWidth: '200px', width: 'fit-content' }} onClick={() => history.push('/vehiclePasses')}>Purchase New Passes</Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h6 className="right-btn my-5">Close my account</h6>
              <Button className="right-btn my-5" style={{ minWidth: '200px', width: 'fit-content' }} onClick={closeAccount} disabled={closeAccLoading}>
                {closeAccLoading ? <ButtonSpinner /> : 'Close Account'}
              </Button>
            </div>
          </div >
        </div >
      )}
    </div>
  );
};

export default Account;
