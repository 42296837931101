import React, { useState, useEffect, useContext } from 'react';
import Button from '../claim/button';
import Accordion from 'react-bootstrap/Accordion';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from '../../components/Spinner';
import { jwtToken } from '../../Context/DataContext';
import { UserContext } from '../../Context/UserContext';
import { VehicleDataContext } from '../../Context/VehicleDataContext';
import { TimestampToDate } from '../../utils/DateAndTimeUtils';
import '../CSS/Steps.css';

export default function Stepthree({ isActive }) {
  const history = useHistory();
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const { claimObj } = useContext(VehicleDataContext);
  const [selectedPass, setSelectedPass] = useState({});
  const [expiredPasses, setExpiredPasses] = useState([]);
  const [usedPasses, setUsedPasses] = useState([]);
  const [availablePasses, setAvailablePasses] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (token && isActive) {
      const fetchPassess = async () => {
        setLoaded(true);
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_COMMON}paymentCheck`,
            { userID }, { headers: { Authorization: `Bearer ${token}` } });

          const { personalPasses, dealerPasses } = response.data;
          let passes;
          if (claimObj.DealerID) {
            passes = dealerPasses;
          } else {
            passes = personalPasses;
          }

          if (passes.length > 0) {
            const expiredPasses = [];
            const usedPasses = [];
            const availablePasses = [];
            passes.forEach((payment) => {
              if (payment.Status === 'Expired') {
                expiredPasses.push(payment);
              } else if (payment.Status === 'Active' && payment.AvailablePasses === 0) {
                usedPasses.push(payment);
              } else if (payment.Status === 'Active' && payment.AvailablePasses > 0) {
                availablePasses.push(payment);
              }
            });
            setExpiredPasses(expiredPasses);
            setUsedPasses(usedPasses);
            setAvailablePasses(availablePasses);
          }

          setLoaded(false);
        } catch (error) {
          setLoaded(false);
          console.log(error.message);
        }
      };
      fetchPassess();
    }
  }, [isActive, token, userID, claimObj]);

  const handleClickpath = () => {
    history.replace('/claim/Not%20Listed?activeStep=4', { selectedPass });
  };
  const previouspagehandler = () => {
    history.replace('/claim/Not%20Listed?activeStep=2');
  };

  return (
    <div className="container">
      <div className="three">
        {loaded ? (<Spinner></Spinner>) : (
          <>
            <Button className="right-btn" onClick={() => history.replace('/details')}>
              List Later
            </Button>
            <h3 className="claim-topic-color">Select a Pass!</h3>
            <div className='pt-4'>
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                {availablePasses.length > 0 && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Available Passes</Accordion.Header>
                    <Accordion.Body>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Available Passes</th>
                            <th>Used Passes</th>
                            <th>Expiry Date</th>
                            <th>Claim Validity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {availablePasses.map((availablePass, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="radio"
                                  value={availablePass}
                                  checked={selectedPass.CreatedAt === availablePass.CreatedAt}
                                  onChange={() => setSelectedPass(availablePass)}
                                />
                              </td>
                              <td>{availablePass.PricingName}</td>
                              <td>{availablePass.AvailablePasses}</td>
                              <td>{availablePass.UsedPasses}</td>
                              <td>{TimestampToDate(availablePass.ExpiresAt)}</td>
                              <td>{availablePass.ClaimValidity || 90} days</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {usedPasses.length > 0 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Used Passes</Accordion.Header>
                    <Accordion.Body>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Available Passes</th>
                            <th>Used Passes</th>
                            <th>Expiry Date</th>
                            <th>Claim Validity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {usedPasses.map((usedPass, index) => (
                            <tr key={index}>
                              <td><input type="radio" disabled={true} /></td>
                              <td>{usedPass.PricingName}</td>
                              <td>{usedPass.AvailablePasses}</td>
                              <td>{usedPass.UsedPasses}</td>
                              <td>{TimestampToDate(usedPass.ExpiresAt)}</td>
                              <td>{usedPass.ClaimValidity || 90} days</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {expiredPasses.length > 0 && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Expired Passes</Accordion.Header>
                    <Accordion.Body>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Available Passes</th>
                            <th>Used Passes</th>
                            <th>Expired Date</th>
                            <th>Claim Validity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {expiredPasses.map((expiredPass, index) => (
                            <tr key={index}>
                              <td><input type="radio" disabled={true} /></td>
                              <td>{expiredPass.PricingName}</td>
                              <td>{expiredPass.AvailablePasses}</td>
                              <td>{expiredPass.UsedPasses}</td>
                              <td>{TimestampToDate(expiredPass.ExpiresAt)}</td>
                              <td>{expiredPass.ClaimValidity || 90} days</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </div>
            <br />
            {availablePasses.length === 0 && <div>
              <h6>You need one or more vehicle passes to complete the listing, please purchase vehicle passes here.</h6>
              <Link to="/account">Account</Link>
            </div>}
            <div className="justify-content-center" style={{ display: 'flex' }}>
              <Button className="red btn my-1" onClick={previouspagehandler}>
                Previous
              </Button>
              {availablePasses.length > 0 && (
                <Button
                  className="red btn my-1"
                  onClick={handleClickpath}
                  disabled={Object.keys(selectedPass).length === 0 ? true : false}
                >
                  Next
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
